<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Új partner</h4>
    <button type="button" class="close" (click)="modal.close(undefined)" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus style="max-height: 82vh; overflow-y: auto">
    <div class="container">
        <form [formGroup]="formGroup" class="form form-vertical">
            <div class="divider">
                <div class="divider-text font-weight-bold">Alapadatok</div>
            </div>

            <ng-container formGroupName="alapAdatok">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="ugyfeltipus">Ügyfél típus</label>
                            <div class="form-group">
                                <ng-select id="ugyfeltipus" class="w-100" [items]="ugyfelTipusok" bindLabel="fullTitle" bindValue="id" [clearable]="false" formControlName="ugyfel_tipus" [required]="
                    'ugyfel_tipus' | requiredControl : formGroup : 'alapAdatok'
                  ">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="adoszam">Adószám</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="adoszam" mask="00000000-0-00" [dropSpecialCharacters]="false" aria-describedby="adoszam" formControlName="adoszam" (blur)="getCegInfo()" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="csoportos_adoszam">Csoportos adószám</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="csoportos_adoszam" mask="00000000-0-00" [dropSpecialCharacters]="false" aria-describedby="csoportos_adoszam" formControlName="csoportos_adoszam" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="eu_adoszam">EU adószám</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="eu_adoszam" aria-describedby="eu_adoszam" formControlName="eu_adoszam" [required]="
                    formGroup.get('alapAdatok.ugyfel_tipus')?.value === 'KJS'
                  " />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="nev">Név</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="nev" aria-describedby="nev" formControlName="nev" [required]="
                    'nev' | requiredControl : formGroup : 'alapAdatok'
                  " />
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="formGroup.get('alapAdatok').errors?.oneRequired" class="row mt-1">
                <div class="col">
                    <small class="text-muted text-danger">*Adószám vagy csoportos adószám mező egyike kötelező</small
          >
        </div>
      </div>

      <div *ngIf="formGroup.get('alapAdatok.adoszam')?.errors" class="row mt-1">
        <div class="col">
          <small class="text-muted text-danger"
            >*Az adószám formátuma nem megfelelő</small
          >
        </div>
      </div>

      <div
        *ngIf="formGroup.get('alapAdatok.csoportos_adoszam')?.errors"
        class="row mt-1"
      >
        <div class="col">
          <small class="text-muted text-danger"
            >*A csoportos adószám formátuma nem megfelelő</small
          >
        </div>
      </div>

      <div class="divider">
        <div class="divider-text font-weight-bold">Gazdasági adatok</div>
      </div>

      <ng-container formGroupName="gazdasagiAdatok">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="penznem">Pénznem</label>

              <ng-select
                id="penznem"
                class="w-100"
                [items]="penznemek$ | async"
                bindLabel="megnevezes"
                bindValue="id"
                [clearable]="false"
                formControlName="penznem_id"
                [required]="
                  'penznem_id' | requiredControl : formGroup : 'gazdasagiAdatok'
                "
              >
              </ng-select>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="fizetesiMod">Fizetési mód</label>

              <ng-select
                id="fizetesiMod"
                class="w-100"
                [items]="fizetesiModok$ | async"
                bindLabel="nev"
                bindValue="id"
                [clearable]="false"
                formControlName="fizetesimod_id"
                [required]="
                  'fizetesimod_id'
                    | requiredControl : formGroup : 'gazdasagiAdatok'
                "
              >
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="arkategoria">Árkategória</label>

              <ng-select
                id="arkategoria"
                class="w-100"
                [items]="arkategoriak$ | async"
                bindLabel="nev"
                bindValue="id"
                [multiple]="true"
                [closeOnSelect]="false"
                [clearable]="false"
                formControlName="arkategoria_id"
                [required]="
                  'arkategoria_id'
                    | requiredControl : formGroup : 'gazdasagiAdatok'
                "
              >
              </ng-select>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="divider">
        <div class="divider-text font-weight-bold">Címadatok</div>
      </div>

      <div class="row">
        <div class="col">
          <app-torzsek-cim-form></app-torzsek-cim-form>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-danger"
    (click)="modal.close(undefined)"
    rippleEffect
  >
    Mégse
  </button>
  <button
    type="button"
    class="btn btn-primary"
    [disabled]="formGroup?.invalid || formGroup?.pristine || loading"
    (click)="savePartner()"
    rippleEffect
  >
    <span
      *ngIf="loading"
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"
    ></span>
    Mentés
  </button>
</div>