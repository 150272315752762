import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  switchTheme(theme: 'light' | 'dark' | 'default') {
    let themeLink = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;

    const newTheme =
      theme === 'light' || theme === 'default'
        ? 'bootstrap4-light-blue.css'
        : 'bootstrap4-dark-blue.css';

    if (themeLink) {
      themeLink.href = newTheme;
    }
  }
}
