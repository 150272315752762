export class EventRef {
  id?: number = null;
  title: string = null;
  start: string | Date = null;
  end: string | Date = null;
  allDay: boolean = false;
  editable: boolean = true;
  naptar_kategoria_id: number;
  dolgozo_id: number;
  link: string;
  helyszin: string;
  leiras: string;
}

export interface CalendarExtendedProps {
  naptar_kategoria_id: number;
  dolgozo_id?: number;
  link?: string;
  helyszin?: string;
  leiras?: string;
}

export interface EventRefBackend {
  id?: number;
  cim: string;
  esemeny_kezdete: string | Date;
  esemeny_vege: string | Date;
  egesznap: boolean | 0 | 1;
  naptar_kategoria_id?: number;
  dolgozo_id?: number;
  link?: string;
  helyszin?: string;
  leiras?: string;
}
