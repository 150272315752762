import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { ToastrComponent } from '@core/components/toastr/toastr.component';
import { CoreCommonModule } from '@core/common.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ToastrComponent],
  imports: [CommonModule, CoreCommonModule, NgbModule, ToastrModule],
  entryComponents: [ToastrComponent],
  exports: [ToastrModule],
})
export class ToastrsModule {}
