import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { BehaviorSubject, of, Observable, throwError } from 'rxjs';
import { switchMap, take, tap, catchError, map } from 'rxjs/operators';
import { Column, Partner } from '../models/torzsek';
import { findByProp, parseJSON } from '../utils';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class PartnerekService {
  private readonly apiEndpoint = 'partnerek';
  private readonly columnsEndpoint = 'partnerek/columns';
  private readonly partnerekUpdateEndpoint = 'service/frissiteslib/partner_frissites';
  private readonly visszaallitasEndpoint = 'service/torzslib/vissza_allitas';

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);
  private partnerek = JSON.parse(localStorage.getItem('partnerek'));
  private lastFetch: Date = new Date();

  public data$ = this._data.pipe(
    switchMap((state: any[]) => {
      return state ? of(state) : this.partnerek?.db > 0 ? this.getDBData() : this.fetchData()
    })
  );

  // public data$ = this._data.pipe(
  //   switchMap((state: any[]) => (state ? of(state) : this.fetchData()))
  // );

  public szallitok$ = this.data$.pipe(
    map((partnerek: any[]) => partnerek.filter((p: any) => p.szallito && p.statusz != -1))
  );

  public vevok$ = this.data$.pipe(
    map((partnerek: any[]) => partnerek.filter((p: any) => p.vevo && p.statusz != -1))
  );

  constructor(private api: ApiService,
              private stateService: StateService, 
              private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<any[]> {
    console.log('init called');
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) => {
        return state ? of(state) : JSON.parse(localStorage.getItem('partnerek'))?.db > 0 ? this.getDBData() : this.fetchData()
      })
    );
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) => (state ? of(state) : this.fetchData()))
  //   );
  // }

  add(data: any): Observable<any> {
    const payload: any = {
      ...data,
      ...data.alapAdatok,
      ...data.gazdasagiAdatok,
    };

    return this.api.post(this.apiEndpoint, this.toJSON(payload)).pipe(
      map((added: any) => this.fromJSON(added)),
      tap((mapped: any) => {
        if (this._data.value === null) {
          this.fetchData();
        } else {
          this.stateService.setStateOnAdd(mapped, this._data);
          //this.dbService.add('partnerek', mapped).subscribe();
        }
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: any) => {
        this.stateService.setStateOnUpdate(deleted, this._data);
        //this.dbService.deleteByKey('partnerek', deleted.id).subscribe();
      }
      ),
      catchError((err: any) => throwError(err))
    );
  }

  visszaallitas(row: any): Observable<any> {
    return this.api.post(`${this.visszaallitasEndpoint}/partnerek/${row.id}`, '').pipe(
      map((partner: any) => { return this.fromJSON(partner) }),
      tap((updated: any) => {
        // Update state
        this.stateService.setStateOnUpdate(updated, this._data);
      }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    )
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state?.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(this.fromJSON(data));
      })
    );
  }

  update(data: any): Observable<any> {
    const payload: any = {
      ...data,
      ...data.alapAdatok,
      ...data.gazdasagiAdatok,
    };

    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(payload))
      .pipe(
        tap((updated: any) => {
          if (this._data.value === null) {
            this.fetchData();
          } else {
            this.stateService.setStateOnUpdate(
              this.fromJSON(updated),
              this._data
            );
            //this.dbService.update('partnerek', this.fromJSON(updated)).subscribe();
          }
        }),
        catchError((err: any) => throwError(err))
      );
  }

  getDBData(): Observable<any> {
    console.log('get partnerek from indexedDB')
    return this.dbService.getAll('partnerek').pipe(
      map((data: any[]) => this.fromJSON(data)),
      tap((data: any[]) => this._data.next(data)),
    );
  }

  fetchData(): Observable<any[]> {
    return this.api.get(this.apiEndpoint).pipe(
      map((data: any[]) => {
        this.dbService.clear('partnerek').subscribe();
            this.dbService
              .bulkAdd('partnerek', this.fromJSON(data))
              .subscribe((result) => {
                localStorage.setItem('partnerek', JSON.stringify({db: result.length, lastFetch: new Date()}));
              }, error => console.log(error));
        return this.fromJSON(data)
      }),
      tap((data: Partner[]) => {
        this._data.next(data);
      }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  // private fetchData(): Observable<any[]> {
  //   return this.api.get(this.apiEndpoint).pipe(
  //     map((data: any[]) => {
  //       // this.dbService.count('partnerek').subscribe(count => {
  //       //   if(count == 0) {
  //       //     this.dbService
  //       //       .bulkAdd('partnerek', this.fromJSON(data))
  //       //       .subscribe((result) => {
                
  //       //       }, error => console.log(error));
  //       //   }
  //       //   localStorage.setItem('partnerek', JSON.stringify({db: data.length, lastFetch: new Date()}));
  //       //   //this.count = count;
  //       // })
  //       localStorage.setItem('partnerek', JSON.stringify({db: data.length, lastFetch: new Date()}));

  //       return this.fromJSON(data)
  //     }),
  //     tap((data: Partner[]) => {
  //       this._data.next(data);
  //     }),
  //     catchError((err: any) => {
  //       console.log(err);
  //       return of([]);
  //     })
  //   );
  // }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      map((columns: Column[]) =>
        columns.map((c: Column) => {
          const shownColumns = [
            'id',
            'nev',
            'ugyfelkod',
            'telefonszam',
            'cim',
          ];

          if (!shownColumns.includes(c.name)) {
            return {
              ...c,
              hidden: true,
            };
          }

          return c;
        })
      ),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private fromJSON(data: any | any[]): any | any[] {
    // Array
    if (Array.isArray(data)) {
      return data.map((e: any) => ({
        ...e,
        bankszamlaszamok: parseJSON(e.bankszamlaszamok),
        cim: parseJSON(e.cim),
        ugyintezok: parseJSON(e.ugyintezok),
        arkategoria_id: parseJSON(e.arkategoria_id),
      }));
    }

    // Single object
    else {
      return {
        ...data,
        bankszamlaszamok: parseJSON(data.bankszamlaszamok),
        cim: parseJSON(data.cim),
        ugyintezok: parseJSON(data.ugyintezok),
        arkategoria_id: parseJSON(data.arkategoria_id),
      };
    }
  }

  private toJSON(data: any): any {
    return {
      ...data,
      bankszamlaszamok: JSON.stringify(data.bankszamlaszamok),
      cim: JSON.stringify(data.cim),
      ugyintezok: JSON.stringify(data.ugyintezok),
      arkategoria_id: JSON.stringify(data.arkategoria_id),
    };
  }

  getPartnerekChange(): any {
    this.lastFetch = new Date();
    const last = JSON.parse(localStorage.getItem('partnerek'))?.lastFetch;
    this.api.get(`${this.partnerekUpdateEndpoint}/${last}`).pipe(
        //take(1),
        map((data: any[]) => this.fromJSON(data)),
        //tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
    )
    .subscribe((resp: any) => {
      if(resp) {
        this.dbService.getAll('partnerek').subscribe(partnerek => {
          this._data.next(partnerek.sort((a: any,b: any) => b.id - a.id));
          if(resp.valtozas.length > 0) {
            resp.valtozas.forEach(partner => {
              const cikkIndex = this._data.value?.findIndex(item => item.id == partner.id);

              if(cikkIndex < 0) {
                //this._data.value.push(partner);
                this.stateService.setStateOnAdd(this.fromJSON(partner), this._data);
                this.dbService.add('partnerek', this.fromJSON(partner)).subscribe();
              } else {
                // this._data.value[cikkIndex] = {
                //   ...partner
                //   // ...cikkItem,
                //   // beszerzesi_ar: cikk?.beszerzesi,
                //   // aktualis_mennyiseg: cikk?.aktualis_mennyiseg,
                //   // foglalas: cikkItem?.foglalas + cikk?.foglalas,
                // }
                this.stateService.setStateOnUpdate(
                  this.fromJSON(partner),
                  this._data
                );
                this.dbService.update('partnerek', this.fromJSON(partner)).subscribe();
              }
            });
          }
          if(resp.torolt_lista.length > 0) {
            resp.torolt_lista.forEach(partner => {
              const part = findByProp(this._data.value, 'id', partner.modul_id);
                  if(part) {
                    this.stateService.setStateOnDelete(part ,this._data);
                    this.dbService.deleteByKey('partnerek', partner.modul_id).subscribe();
                  }
            })
          }
          this.dbService.count('partnerek').subscribe(count => {
            localStorage.setItem('partnerek', JSON.stringify({db: count, lastFetch: this.lastFetch}))
          })
        })
      }
    }
    );
  }
}
