<div class="user-settings">
  <form class="theme-customizer-styled">
    <!-- Router animation -->
    <div class="d-flex justify-content-between align-items-center">
      <p class="font-weight-bold mr-auto m-0" for="routeSelect">
        Önelszámoló egység
      </p>
      <ng-select
        id="oe"
        [items]="oek$ | async"
        bindLabel="megnevezes"
        bindValue="id"
        [clearable]="false"
        [ngModel]="(user$ | async)?.oe"
        [ngModelOptions]="{ standalone: true }"
        [required]="true"
        (change)="updateOnOeSelect($event)"
      >
      </ng-select>
    </div>

    <!-- Router animation -->
    <div class="d-flex justify-content-between align-items-center">
      <p class="font-weight-bold mr-auto m-0" for="routeSelect">Raktár</p>
      <ng-select
        #raktarSelect
        id="raktar"
        [items]="raktarak$ | async"
        bindLabel="megnevezes"
        bindValue="id"
        [clearable]="false"
        [ngModel]="(user$ | async)?.raktar"
        [ngModelOptions]="{ standalone: true }"
        [required]="true"
      >
      </ng-select>
    </div>
  </form>
</div>

<ng-template #formLayoutTemplate>
  <form class="form-layout-styled">
    <!-- Önelszámoló egység -->
    <div class="form-group">
      <label for="oe">Önelszámoló egység</label>
      <ng-select
        id="oe"
        class="w-100"
        [ngStyle]="{ maxWidth: '272px' }"
        [items]="oek$ | async"
        bindLabel="megnevezes"
        bindValue="id"
        [clearable]="false"
        [ngModel]="(user$ | async)?.oe"
        [ngModelOptions]="{ standalone: true }"
        [required]="true"
        (change)="updateOnOeSelect($event)"
      >
      </ng-select>
    </div>

    <!-- Raktár -->
    <div class="form-group">
      <label for="raktar">Raktár</label>
      <ng-select
        #raktarSelect
        id="raktar"
        class="w-100"
        [ngStyle]="{ maxWidth: '272px' }"
        [items]="raktarak$ | async"
        bindLabel="megnevezes"
        bindValue="id"
        [clearable]="false"
        [ngModel]="(user$ | async)?.raktar"
        [ngModelOptions]="{ standalone: true }"
        [required]="true"
      >
      </ng-select>
    </div>
  </form>
</ng-template>
