import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Pipe({
  name: 'requiredControl',
})
export class RequiredControlPipe implements PipeTransform {
  transform(
    controlName: string,
    formGroup: FormGroup,
    formGroupName?: string
  ): boolean {
    if (!controlName || !formGroup) {
      console.warn('Control or formGroup is missing');
      return false;
    }

    if (formGroupName && !formGroup.get(`${formGroupName}.${controlName}`)) {
      console.warn('Nem található formControl', formGroupName, controlName);
    }

    if (!formGroupName && !formGroup.get(controlName)) {
      console.warn('Nem található formControl', controlName);

      return false;
    }

    // Nested
    if (formGroupName) {
      return formGroup
        .get(`${formGroupName}.${controlName}`)
        .hasValidator(Validators.required);
    }

    // Normal
    else {
      return formGroup.get(controlName).hasValidator(Validators.required);
    }
  }
}
