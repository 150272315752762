import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { switchMap, take, tap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { findByProp } from '../utils';
import { OnelszamoloEgysegekService } from './onelszamolo-egysegek.service';
import { PartnerekService } from './partnerek.service';
import { RaktarakService } from './raktarak.service';
import { ArkategoriakService } from './arkategoriak.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AfakulcsokService } from './afakulcsok.service';

@Injectable({
  providedIn: 'root',
})
export class KulsoRaktariEladasService {
  private readonly apiEndpoint = 'kulsoraktarieladas';
  private readonly columnsEndpoint = 'kulsoraktarieladas/columns';
  private readonly listaEndpoint = 'service/helplib/oldal_lista/kr';
  private readonly updateEndpoint = 'service/helplib/bizonylat_frissites/kr';
  private readonly nyomtatasBeallitasEndpoint = 'service/helplib/nyomtatas_kapcsolo/kr';

  private lastFetch: Date = new Date();
  private kulsoraktari = JSON.parse(localStorage.getItem('kulsoraktari'));

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);
  public data: any[] = [];
  private pageNum: number;
  public readonly streamEnd = new BehaviorSubject<boolean>(false);

  // public data$ = combineLatest([
  //   this._data,
  //   this.oeService.data$,
  //   this.raktarakService.data$,
  //   this.partnerekService.data$,
  //   this.arkategoriakService.data$
  // ]).pipe(
  //   switchMap(([state, oek, raktarak, partnerek, arkategoriak]) => {
  //     return state
  //       ? of(this.mapKulsoRaktari(state, oek, raktarak, partnerek, arkategoriak))
  //       : this.kulsoraktari?.db > 0 ? this.getDBData() : this.fetchData()
  //   }),
  //   withLatestFrom(this.authService.currentUser$),
  //   map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  // );

  public data$ = combineLatest([
    this._data,
    this.oeService.data$,
    this.raktarakService.data$,
    this.partnerekService.data$,
    this.arkategoriakService.data$
  ]).pipe(
    switchMap(([state, oek, raktarak, partnerek, arkategoriak]) =>
      state
        ? of(this.mapKulsoRaktari(state, oek, raktarak, partnerek, arkategoriak))
        : this.fetchData()
    )
  );

  constructor(
    private oeService: OnelszamoloEgysegekService,
    private partnerekService: PartnerekService,
    private raktarakService: RaktarakService,
    private api: ApiService,
    private stateService: StateService,
    private authService: AuthenticationService,
    private arkategoriakService: ArkategoriakService,
    private afakulcsokService: AfakulcsokService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.oeService.initData$,
  //             this.raktarakService.initData$,
  //             this.partnerekService.initData$,
  //             this.arkategoriakService.initData$
  //           ]).pipe(
  //             map(([kulsoRaktari, oek, raktarak, partnerek, arkategoriak]) =>
  //               this.mapKulsoRaktari(kulsoRaktari, oek, raktarak, partnerek, arkategoriak)
  //             )
  //           )
  //         : JSON.parse(localStorage.getItem('kulsoraktari'))?.db > 0 ? this.getDBData() : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === JSON.parse(localStorage.getItem('currentUser'))?.oe) )
  //   );
  // }

  //user ? data.filter((e: any) => e.oe_id === user.oe) : 

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.oeService.initData$,
              this.raktarakService.initData$,
              this.partnerekService.initData$,
              this.arkategoriakService.initData$
            ]).pipe(
              map(([kulsoRaktari, oek, raktarak, partnerek, arkategoriak]) =>
                this.mapKulsoRaktari(kulsoRaktari, oek, raktarak, partnerek, arkategoriak)
              )
            )
          : this.fetchData()
      ),
      withLatestFrom(this.authService.currentUser$),
      map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('kulsoraktari', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  addWithoutMsg(data: any): Observable<any> {
    return this.api.postBizonylat(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('kulsoraktari', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('kulsoraktari', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.oeService.initData$,
      this.raktarakService.initData$,
      this.partnerekService.initData$,
      this.arkategoriakService.initData$,
      this.afakulcsokService.initData$
    ]).pipe(
      map(([kulsoRaktari, oek, raktarak, partnerek, arkategoriak, afakulcsok]) => {
        const retVal = this.mapKulsoRaktariById(kulsoRaktari, oek, raktarak, partnerek, arkategoriak, afakulcsok)
        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) => {
          this.stateService.setStateOnUpdate(updated, this._data);
          //this.dbService.update('kulsoraktari', updated).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('kulsoraktari', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem('currentUser')).oe;
    const last = JSON.parse(localStorage.getItem('kulsoraktari'))?.lastFetch;
    return this.api.postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
           .pipe(
            take(1),
            tap(valtozas => {
              if (this._data.value.length == 0) {
                //this.fetchData().subscribe();
              } else {
                if (valtozas.valtozasok.length > 0) {
                  valtozas.valtozasok.forEach((bizonylat) => {
                    const biz = findByProp(this._data.value, "id", bizonylat.id);
                    if (biz) {
                      this.stateService.setStateOnUpdate(bizonylat, this._data);
                    } else {
                      this.stateService.setStateOnAdd(bizonylat, this._data);
                    }
                  });
                }
                if (valtozas.torolt_lista.length > 0) {
                  valtozas.torolt_lista.forEach((bizonylat) => {
                    const biz = findByProp(
                      this._data.value,
                      "id",
                      bizonylat.modul_id
                    );
                    if (biz) {
                      this.stateService.setStateOnDelete(biz, this._data);
                    }
                  });
                }
              }
    
              localStorage.setItem(
                "kulsoraktari",
                JSON.stringify({
                  db: this._data.value.length,
                  lastFetch: this.lastFetch,
                })
              );
            }))
  }

  getDBData(): Observable<any> {
    console.log('get kulsoraktari from indexedDB')
    // return this.dbService.getAll('kulsoraktari').pipe(
    //   tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
    // );
    return forkJoin([
      this.dbService.getAll('kulsoraktari').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.oeService.initData$,
      this.raktarakService.initData$,
      this.partnerekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(
      map(([kulsoRaktari, oek, raktarak, partnerek, arkategoriak]) => {
        return this.mapKulsoRaktari(kulsoRaktari, oek, raktarak, partnerek, arkategoriak)
      })
    );
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(`${this.apiEndpoint}`).pipe(
        // bufferCount(25),
        // concatMap(objs => of(objs)),
        tap((data: any[]) => {
          this._data.next(data);
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.oeService.initData$,
      this.raktarakService.initData$,
      this.partnerekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(
      map(([kulsoRaktari, oek, raktarak, partnerek, arkategoriak]) => {
        localStorage.setItem('kulsoraktari', JSON.stringify({db: kulsoRaktari.length, lastFetch: this.lastFetch}));
        return this.mapKulsoRaktari(kulsoRaktari, oek, raktarak, partnerek, arkategoriak)
      })
    );
  }

  private getColumns(): Observable<Column[]> {
    const columns = JSON.parse(localStorage.getItem('kulsoraktari_columns'));

    if(columns) {
      return of(columns);
    } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((columns: Column[]) => {
          const mappedColumns = [
            ...columns,
            { name: 'cim', nullable: true},
            { name: 'tetelek', nullable: false, hidden: true },
            { name: 'oe_id', nullable: true },
            { name: 'megjegyzes_nyomtat', nullable: false, hidden: true, default: 1 },
          ];
          localStorage.setItem('kulsoraktari_columns', JSON.stringify(mappedColumns));
          return mappedColumns;
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      );
    }
  }

  private mapKulsoRaktari(
    kulsoRaktari: any[],
    oek: any[],
    raktarak: any[],
    partnerek: any[],
    arkategoriak: any[]
  ): any[] {
    return kulsoRaktari.map((e: any) => ({
      ...e,
      atado_oe: findByProp(oek, 'id', e.atado_oe_id)?.megnevezes,
      atado_raktar: findByProp(raktarak, 'id', e.atado_raktar_id)?.megnevezes,
      partner: findByProp(partnerek, 'id', e.partner_id)?.nev,
      //szamla_kelte: new Date(e.szamla_kelte),
      teljesites_datum: new Date(e.teljesites_datum),
      //fizetesi_hatarido: new Date(e.fizetesi_hatarido),
      //tetelek: e.tetelek.map((t: any) => ({ ...t, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, })),
      //sztornozhato: e.tetelek.filter(item => item.kiszamlazva != 0).length > 0 ? false : true,
      sztornozhato: (e.statusz != 3 && e.statusz != 4)
    }));
  }

  private mapKulsoRaktariById(
    kulsoRaktari: any,
    oek: any[],
    raktarak: any[],
    partnerek: any[],
    arkategoriak: any[],
    afakulcsok: any[]
  ): any[] {
    return {
      ...kulsoRaktari,
      atado_oe: findByProp(oek, 'id', kulsoRaktari.atado_oe_id)?.megnevezes,
      atado_raktar: findByProp(raktarak, 'id', kulsoRaktari.atado_raktar_id)?.megnevezes,
      partner: findByProp(partnerek, 'id', kulsoRaktari.partner_id)?.nev,
      //szamla_kelte: new Date(e.szamla_kelte),
      teljesites_datum: new Date(kulsoRaktari.teljesites_datum),
      //fizetesi_hatarido: new Date(e.fizetesi_hatarido),
      tetelek: kulsoRaktari.tetelek.map((t: any) => ({ ...t, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev,afakulcs_nev: findByProp(afakulcsok, 'id', t.afakulcs_id)?.nev  })),
      //sztornozhato: e.tetelek.filter(item => item.kiszamlazva != 0).length > 0 ? false : true,
      sztornozhato: (kulsoRaktari.statusz != 3 && kulsoRaktari.statusz != 4)
    };
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: JSON.stringify(data.cim),
    };
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .post(`${environment.pdfGeneralas}/${this.apiEndpoint}`, payload)
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  getMennyiseg(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.api.postBizonylat(`service/helplib/ossz_bizonylat/kr/${currentUser.id}`, "");
  }

  clearData(): any {
    this.data = [];
  }

  nyomtatasBeallitas(row: any, megjegyzes_nyomtat: number, hivatkozas_nyomtat: number = 0): Observable<any> {
    return this.api
    .post(`${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`, '')
    .pipe(
      tap((updated: any) => {
        const updateRow = {
          ...row,
          megjegyzes_nyomtat: megjegyzes_nyomtat,
        }
        this.stateService.setStateOnUpdate(updateRow, this._data);
      }),
      catchError(() => of(null))
    );
  }
}
