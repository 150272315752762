import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { AuthenticationService } from 'app/auth/service';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import {
  switchMap,
  take,
  tap,
  catchError,
  map,
  withLatestFrom,
  mergeMap,
} from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { findByProp } from '../utils';
import { PartnerekService } from './partnerek.service';
import { environment } from 'environments/environment';
import { ArkategoriakService } from './arkategoriak.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AfakulcsokService } from './afakulcsok.service';

@Injectable({
  providedIn: 'root',
})
export class NyugtaService {
  private readonly apiEndpoint = 'nyugta';
  private readonly columnsEndpoint = 'nyugta/columns';
  private readonly listaEndpoint = 'service/helplib/oldal_lista/ny';
  private readonly updateEndpoint = 'service/helplib/bizonylat_frissites/ny';
  private readonly nyomtatasBeallitasEndpoint = 'service/helplib/nyomtatas_kapcsolo/ny';

  private lastFetch: Date = new Date();
  private nyugta = JSON.parse(localStorage.getItem('nyugta'));

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);
  public data: any[] = [];
  private pageNum: number;
  public readonly streamEnd = new BehaviorSubject<boolean>(false);


  // private state$ = combineLatest([
  //   this._data,
  //   this.partnerekService.data$,
  //   this.arkategoriakService.data$
  // ]).pipe(
  //   switchMap(([state, partnerek, arkategoriak]) => {
  //     return state ? of(this.mapNyugtak(state, partnerek, arkategoriak)) : this.nyugta?.db > 0 ? this.getDBData() : this.fetchData()
  //   })
  // );

  private state$ = combineLatest([
    this._data,
    this.partnerekService.data$,
    this.arkategoriakService.data$
  ]).pipe(
    switchMap(([state, partnerek, arkategoriak]) =>
      state ? of(this.mapNyugtak(state, partnerek, arkategoriak)) : this.fetchData()
    )
  );

  public data$ = combineLatest([
    this.state$,
    this.authService.currentUser$,
  ]).pipe(map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe)));

  constructor(
    private authService: AuthenticationService,
    private partnerekService: PartnerekService,
    private api: ApiService,
    private stateService: StateService,
    private arkategoriakService: ArkategoriakService,
    private afakulcsokService: AfakulcsokService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([of(state), this.partnerekService.initData$, this.arkategoriakService.initData$]).pipe(
  //             map(([nyugtak, partnerek, arkategoriak]) => this.mapNyugtak(nyugtak, partnerek, arkategoriak))
  //           )
  //         : JSON.parse(localStorage.getItem('nyugta'))?.db > 0 ? this.getDBData() : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  //   );
  // }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([of(state), this.partnerekService.initData$, this.arkategoriakService.initData$]).pipe(
              map(([nyugtak, partnerek, arkategoriak]) => this.mapNyugtak(nyugtak, partnerek, arkategoriak))
            )
          : this.fetchData()
      ),
      withLatestFrom(this.authService.currentUser$),
      map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('nyugta', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) => {
          this.stateService.setStateOnUpdate(updated, this._data);
          //this.dbService.update('nyugta', updated).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: any) => {
        this.stateService.setStateOnDelete(deleted, this._data);
        //this.dbService.deleteByKey('nyugta', deleted.id).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .postBizonylat(`${environment.pdfGeneralas}/${this.apiEndpoint}`, payload)
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of({
          ...data,
          ervenyessegi_datum: new Date(data.ervenyessegi_datum),
          bizonylat_datuma: new Date(data.bizonylat_datuma),
        });
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.partnerekService.initData$,
      this.arkategoriakService.initData$,
      this.afakulcsokService.initData$
    ]).pipe(
      map(([nyugta, partnerek, arkategoriak, afakulcsok]) => {
        const retVal = this.mapNyugta(nyugta, partnerek, arkategoriak, afakulcsok)
        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem('currentUser')).oe;
    const last = JSON.parse(localStorage.getItem('nyugta'))?.lastFetch;
    return this.api.postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
           .pipe(
            take(1),
            tap(valtozas => {
              if (this._data.value.length == 0) {
                //this.fetchData().subscribe();
              } else {
                if (valtozas.valtozasok.length > 0) {
                  valtozas.valtozasok.forEach((bizonylat) => {
                    const biz = findByProp(this._data.value, "id", bizonylat.id);
                    if (biz) {
                      this.stateService.setStateOnUpdate(bizonylat, this._data);
                    } else {
                      this.stateService.setStateOnAdd(bizonylat, this._data);
                    }
                  });
                }
                if (valtozas.torolt_lista.length > 0) {
                  valtozas.torolt_lista.forEach((bizonylat) => {
                    const biz = findByProp(
                      this._data.value,
                      "id",
                      bizonylat.modul_id
                    );
                    if (biz) {
                      this.stateService.setStateOnDelete(biz, this._data);
                    }
                  });
                }
              }
    
              localStorage.setItem(
                "nyugta",
                JSON.stringify({
                  db: this._data.value.length,
                  lastFetch: this.lastFetch,
                })
              );
            }))
  }

  getDBData(): Observable<any> {
    console.log('get nyugta from indexedDB')
    // return this.dbService.getAll('nyugta').pipe(
    //   tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
    // );
    return forkJoin([
      this.dbService.getAll('nyugta').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.partnerekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(map(([nyugtak, partnerek, arkategoriak]) => {
      return this.mapNyugtak(nyugtak, partnerek, arkategoriak)
    }));
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(`${this.apiEndpoint}`).pipe(
        // bufferCount(25),
        // concatMap(objs => of(objs)),
        tap((data: any[]) => {
          this._data.next(data);
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.partnerekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(map(([nyugtak, partnerek, arkategoriak]) => {
      localStorage.setItem('nyugta', JSON.stringify({db: nyugtak.length, lastFetch: this.lastFetch}));
      return this.mapNyugtak(nyugtak, partnerek, arkategoriak)
    }));
  }

  private getColumns(): Observable<Column[]> {
    const columns = JSON.parse(localStorage.getItem('nyugta_columns'));

    if(columns) {
      return of(columns);
    } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((columns: Column[]) => {
          const col = columns.map(item => {
            if(item.name === 'partner_id') {
              return {...item, default: null};
            } else {
              return item;
            }
          });
  
          const mappedColumns = [
            ...col,
            { name: 'tetelek', nullable: true }
          ];
  
          localStorage.setItem('nyugta_columns', JSON.stringify(mappedColumns));
  
          return mappedColumns;
        }),
        catchError((err: any) => {
          console.log(err);
  
          return of([]);
        })
      );
    }
  }

  private mapNyugtak(nyugtak: any[], partnerek: any[], arkategoriak: any[]): any[] {
    return nyugtak.map((e: any) => ({
      ...e,
      partner: findByProp(partnerek, 'id', e.partner_id)?.nev,
      szamla_kelte: new Date(e.szamla_kelte),
      teljesites_datum: new Date(e.teljesites_datum),
      fizetesi_hatarido: new Date(e.fizetesi_hatarido),
      //tetelek: e.tetelek.map((t: any) => ({ ...t, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, })),
    }));
  }

  private mapNyugta(nyugta: any, partnerek: any[], arkategoriak: any[], afakulcsok: any[]): any[] {
    return {
      ...nyugta,
      partner: findByProp(partnerek, 'id', nyugta.partner_id)?.nev,
      szamla_kelte: new Date(nyugta.szamla_kelte),
      teljesites_datum: new Date(nyugta.teljesites_datum),
      fizetesi_hatarido: new Date(nyugta.fizetesi_hatarido),
      tetelek: nyugta.tetelek.map((t: any) => ({ ...t, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, afakulcs_nev: findByProp(afakulcsok, 'id', t.afakulcs_id)?.nev  })),
    };
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: JSON.stringify(data.cim),
    };
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('nyugta', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getMennyiseg(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.api.postBizonylat(`service/helplib/ossz_bizonylat/ny/${currentUser.id}`, "");
  }

  clearData(): any {
    this.data = [];
  }

  nyomtatasBeallitas(row: any, megjegyzes_nyomtat: number, hivatkozas_nyomtat: number = 0): Observable<any> {
    return this.api
    .post(`${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`, '')
    .pipe(
      tap((updated: any) => {
        const updateRow = {
          ...row,
          megjegyzes_nyomtat: megjegyzes_nyomtat,
        }
        this.stateService.setStateOnUpdate(updateRow, this._data);
      }),
      catchError(() => of(null))
    );
  }
}
