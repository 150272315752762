import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthLoginV2Component } from './main/pages/authentication/auth-login-v2/auth-login-v2.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/kezdolap',
    pathMatch: 'full',
  },
  {
    path: 'bejelentkezes',
    component: AuthLoginV2Component,
    data: { animation: 'auth', title: 'Bejelentkezés' },
  },
  {
    path: 'kezdolap',
    data: {
      title: 'Irányítópult'
    },
    loadChildren: () =>
      import('./main/pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'torzsek',
    loadChildren: () =>
      import('./main/pages/torzsek/torzsek.module').then(
        (m) => m.TorzsekModule
      ),
  },

  //Error 404 - Page not found
  {
    path: '**',
    loadChildren: () =>
      import('./main/pages/miscellaneous/miscellaneous.module').then(
        (m) => m.MiscellaneousModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
