import { Pipe, PipeTransform } from '@angular/core';
import { PenzugyiBizonylatTetel } from 'app/main/pages/torzsek/models/torzsek';

@Pipe({
  name: 'isHeaderIndeterminate',
})
export class IsHeaderIndeterminatePipe implements PipeTransform {
  transform(
    selectedTetelek: { [key: string]: number },
    bizonylatok: any[]
  ): boolean {
    if (!selectedTetelek || !bizonylatok) {
      return false;
    }

    let inBizonylatMennyisegSum: number = 0;
    let inListMennyisegSum: number = 0;

    // A listában mennyi az összes mennyiség
    Object.keys(selectedTetelek).forEach((key: string) => {
      inListMennyisegSum += selectedTetelek[key];
    });

    // Az összes bizonylatban mennyi az összes tétel mennyiség
    bizonylatok.forEach((b: any) => {
      // FIXME: Backend fix needed
      if (!b.tetelek) {
        return;
      }

      inBizonylatMennyisegSum += b.tetelek.reduce(
        (sum: number, t: PenzugyiBizonylatTetel) =>
          sum + (t.mennyiseg - (t?.kiszamlazva || 0)),
        0
      );
    });

    return (
      inListMennyisegSum &&
      inBizonylatMennyisegSum &&
      inListMennyisegSum !== inBizonylatMennyisegSum
    );
  }
}
