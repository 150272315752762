import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { kozteruletTipusok } from '../../constants/kozterulet-tipusok';
import { CimtipusokService } from '../../services/cimtipusok.service';
import { TelepulesekService } from '../../services/telepulesek.service';
import { findByProp } from '../../utils';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OrszagokService } from '../../services/orszagok.service';

@Component({
  selector: 'app-torzsek-cim-form',
  templateUrl: './torzsek-cim-form.component.html',
  styleUrls: ['./torzsek-cim-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class TorzsekCimFormComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @ViewChild('telepulesSelect',{static:false}) 
  private telepulesSelect: NgSelectComponent;

  @Input() customStyle: { [key: string]: any } = {};
  @Input() layout: 'horizontal' | 'vertical' = 'vertical';

  formGroup = new FormGroup({});
  formGroupName: 'cim' | 'cimek' = 'cim';
  // Ng select virtual scroll
  telepulesekInit: any[] = [];
  telepulesekSrc: any[] = [];
  telepulesekData: any[] = [];
  orszagokData: any[] = [];
  opened: boolean = false;
  telepules: any;

  cimtipusok$: Observable<any[]>;

  // A checkbox bugos, ha nem unique id-ja van....
  readonly uniqueId: string = new Date().getTime() + 'kulfoldi-checkbox-id';
  readonly kozteruletTipusok = kozteruletTipusok;

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    cimtipusokService: CimtipusokService,
    private parent: FormGroupDirective,
    private telepulesekService: TelepulesekService,
    private orszagokService: OrszagokService
  ) {
    this.cimtipusok$ = cimtipusokService.data$;
  }

  ngOnInit(): void {
    this.formGroup = this.parent.form.get('cim') as FormGroup;

    this.formGroup.patchValue({
      orszag: 'Magyarország'
    });

    this.formGroup
      .get('iranyitoszam')
      .valueChanges.pipe(debounceTime(300),
       filter(() => !this.formGroup.get('kulfoldi')?.value),
       takeUntil(this.destroy$))
      .subscribe((irsz: string) => {
        const telepulesek = this.telepulesekSrc.filter(
          (t: any) => t.iranyitoszam === +irsz
        );
        
        const telepules = findByProp(telepulesek, 'nev', this.telepules?.nev);

        if(telepules) {
          this.telepules == telepules;
          this.formGroup.patchValue({
            telepules: telepules?.nev || null,
          });
        } else {
          if(telepulesek.length > 1 ) {
            this.telepulesekSrc = telepulesek;
            this.telepulesSelect.open();
          } else {
            this.telepulesekSrc = this.telepulesekInit;
            this.telepules = telepules;
            this.formGroup.patchValue({
              telepules: this.telepulesekSrc.find(
                (t: any) => t.iranyitoszam === +irsz)?.nev || null,
            });
          }
        }
      });

    this.telepulesekService.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((telepulesek: any[]) => {
        this.telepulesekInit = telepulesek;
        this.telepulesekSrc = telepulesek || [];
        this.telepulesekData = (telepulesek || [])
          .slice()
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex((t: { nev: string }) => t.nev === value.nev)
          );
      });

    this.orszagokService.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((orszagok: any[]) => {
        this.orszagokData = orszagok;
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  fillIrszOnTelepulesChange(telepules: any): void {
    this.telepules = telepules;
    this.telepulesekSrc = this.telepulesekInit;

    this.formGroup.get('iranyitoszam').patchValue(telepules.iranyitoszam);
  }
}
