import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  combineLatest,
  forkJoin,
} from 'rxjs';
import { switchMap, take, tap, catchError, map } from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { findByProp } from '../utils';
import { OrszagokService } from './orszagok.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class MegyekService {
  private readonly apiEndpoint = 'megyek';
  private readonly columnsEndpoint = 'megyek/columns';
  private readonly megyekUpdateEndpoint = 'service/frissiteslib/megyek_frissites';

  private megyek = JSON.parse(localStorage.getItem('megyek'));
  private lastFetch: Date = new Date();

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  public data$ = combineLatest([this._data, this.orszagokService.data$]).pipe(
    switchMap(([state, orszagok]) => {
      return state ? of(this.mapMegyek(state, orszagok)) : this.megyek?.db > 0 ? this.getDBData() : this.fetchData()
    })
  );

  // public data$ = combineLatest([this._data, this.orszagokService.data$]).pipe(
  //   switchMap(([state, orszagok]) =>
  //     state ? of(this.mapMegyek(state, orszagok)) : this.fetchData()
  //   )
  // );

  constructor(
    private api: ApiService,
    private orszagokService: OrszagokService,
    private stateService: StateService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([of(state), this.orszagokService.initData$]).pipe(
              map(([megyek, orszagok]) => this.mapMegyek(megyek, orszagok))
            )
          : JSON.parse(localStorage.getItem('megyek'))?.db > 0 ? this.getDBData() : this.fetchData()
      )
    );
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([of(state), this.orszagokService.initData$]).pipe(
  //             map(([megyek, orszagok]) => this.mapMegyek(megyek, orszagok))
  //           )
  //         : this.fetchData()
  //     )
  //   );
  // }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, data).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('megyek', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: any) => {
        this.stateService.setStateOnDelete(deleted, this._data);
        //this.dbService.deleteByKey('megyek', deleted.id).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  update(data: any): Observable<any> {
    return this.api.put(`${this.apiEndpoint}/${data.id}`, data).pipe(
      tap((updated: any) => {
        this.stateService.setStateOnUpdate(updated, this._data);
        //this.dbService.update('megyek', updated).subscribe();
       }),
      catchError((err: any) => throwError(err))
    );
  }

  getDBData(): Observable<any> {
    console.log('get megyek from indexedDB')
    return forkJoin([
      this.dbService.getAll('megyek').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.orszagokService.initData$,
    ]).pipe(map(([megyek, orszagok]) => { 
      return this.mapMegyek(megyek, orszagok)
    }));
  }

  fetchData(): Observable<any[]> {
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.orszagokService.initData$,
    ]).pipe(map(([megyek, orszagok]) => { 
      this.dbService.clear('megyek').subscribe();
          this.dbService
            .bulkAdd('megyek', this.mapMegyek(megyek, orszagok))
            .subscribe((result) => {
              localStorage.setItem('megyek', JSON.stringify({db: result.length, lastFetch: this.lastFetch}));
            }, error => console.log(error));
      return this.mapMegyek(megyek, orszagok)
    }));
  }

  // private fetchData(): Observable<any[]> {
  //   return forkJoin([
  //     this.api.get(this.apiEndpoint).pipe(
  //       tap((data: any[]) => this._data.next(data)),
  //       catchError((err: any) => {
  //         console.log(err);
  //         return of([]);
  //       })
  //     ),
  //     this.orszagokService.initData$,
  //   ]).pipe(map(([megyek, orszagok]) => { 
  //     // this.dbService.count('megyek').subscribe(count => {
  //     //   if(count == 0) {
  //     //     this.dbService
  //     //       .bulkAdd('megyek', this.mapMegyek(megyek, orszagok))
  //     //       .subscribe((result) => {
  //     //       }, error => console.log(error));
  //     //   }
  //     //   localStorage.setItem('megyek', JSON.stringify({db: this.mapMegyek(megyek, orszagok).length, lastFetch: this.lastFetch}));
  //     //   //this.cikkek = count;
  //     // })
  //     localStorage.setItem('megyek', JSON.stringify({db: this.mapMegyek(megyek, orszagok).length, lastFetch: this.lastFetch}));

  //     return this.mapMegyek(megyek, orszagok)
  //   }));
  // }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  getMegyekChange(): any {
    this.lastFetch = new Date();
    const last = JSON.parse(localStorage.getItem('megyek'))?.lastFetch;
    this.api.get(`${this.megyekUpdateEndpoint}/${last}`).pipe(
        take(1),
        //tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
    )
    .subscribe((resp: any) => {
      if(resp) {
        if(this._data.value) {
          if(resp.valtozas.length > 0) {
            resp.valtozas.forEach(megye => {
                  const biz = findByProp(this._data.value, 'id', megye.id);
                  if(biz) {
                    this.stateService.setStateOnUpdate(megye, this._data);
                    this.dbService.update('megyek', megye).subscribe();
                  } else {
                    this.stateService.setStateOnAdd(megye, this._data);
                    this.dbService.add('megyek', megye).subscribe();
                  }
                })
          }
          if(resp.torolt_lista.length > 0) {
            resp.torolt_lista.forEach(megye => {
              const part = findByProp(this._data.value, 'id', megye.modul_id);
                  if(part) {
                    this.stateService.setStateOnDelete(part ,this._data);
                    this.dbService.deleteByKey('megyek', megye.modul_id).subscribe();
                  }
            })
          }
        }
          this.dbService.count('megyek').subscribe(count => {
            localStorage.setItem('megyek', JSON.stringify({db: count, lastFetch: this.lastFetch}))
          })
        }
      }
    );
  }

  private mapMegyek(megyek: any[], orszagok: any[]): any[] {
    return megyek.map((m: any) => ({
      ...m,
      orszag: findByProp(orszagok, 'id', m.orszag_id)?.megnevezes,
    }));
  }
}
