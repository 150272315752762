<div id="details-content" class="custom-modal d-flex flex-column">
    <div class="modal-header details-header">
        <div class="d-inline-flex flex-grow-1">
            <div class="d-flex flex-column gap-2 align-items-center flex-grow-1">
                <h5 class="modal-title details-title flex-grow-1 text-primary">
                    {{ torzs | translate }}
                </h5>
                <span class="text-hint" [ngStyle]="{ fontSize: '12px' }">
          {{ modalTitle }}
        </span>
            </div>

            <button (click)="closeSidebar()" type="button" class="font-large-1 font-weight-normal py-0 ml-auto btn btn-icon" data-dismiss="modal" aria-label="Close" [ngStyle]="{ position: 'absolute', right: '10px', top: '7px' }">
        ×
      </button>
        </div>
    </div>

    <!-- Form inputs -->
    <div class="modal-body details-body" [ngClass]="bodyClass">
        <ng-content></ng-content>
    </div>

    <!-- Actions -->
    <div *ngIf="!disableActions" class="modal-footer details-footer">
        <!-- Form actions -->
        <ng-container *ngTemplateOutlet="footerTemplate || basicTemplate"></ng-container>
    </div>
</div>

<ng-template #basicTemplate>
    <div class="start-content">
        <ng-content select="[footerCustom]"></ng-content>
    </div>

    <div class="end-content">
        <button class="btn btn-outline-secondary add-todo-item" type="button" data-dismiss="modal" rippleEffect (click)="closeSidebar()">
      Mégse
    </button>

        <button *ngIf="torzs == 'leltar'" type="submit" class="btn btn-primary add-todo-item" [disabled]="invalidForm" rippleEffect (click)="onSubmit()">
      Kész
    </button>

        <button *ngIf="!editMode && torzs != 'leltar'" type="submit" class="btn btn-primary add-todo-item" [disabled]="invalidForm" rippleEffect (click)="onSubmit()">
      {{ saveBtnTitle }}
    </button>

        <button *ngIf="editMode" type="button" class="btn btn-primary update-btn update-todo-item" [disabled]="invalidForm" rippleEffect (click)="onSubmit()">
      {{ updateBtnTitle }}
    </button>
    </div>
</ng-template>