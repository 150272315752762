import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMeByCikk',
})
export class FilterMeByCikkPipe implements PipeTransform {
  transform(
    mennyisegiEgysegek: any[],
    me_id: number,
    me_id_2: number,
    me_id_3: number
  ): any[] {
    if (!mennyisegiEgysegek || (!me_id && !me_id_2 && !me_id_3)) {
      return [];
    }

    return mennyisegiEgysegek.filter(
      (me: any) => me.id === me_id || me.id === me_id_2 || me.id === me_id_3
    );
  }
}
