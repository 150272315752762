<div class="content-area-wrapper" style="width: 100%">
    <ng-container *ngTemplateOutlet="tableTemplate"></ng-container>

    <!-- Todo Right Sidebar (Detail) -->
    <core-sidebar #coreSidebar class="modal modal-slide-in sidebar-custom-modal fade" name="ertekesites-sidebar" overlayClass="modal-backdrop" [hideOnEsc]="true" (backdropClickEvent)="closeSidebar()">
        <div class="modal-dialog sidebar w-auto" [ngStyle]="{ 'min-width': '300px' }">
            <div class="modal-content p-0">
                <div class="custom-modal h-100 left">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{ sideForm === 'partner' ? 'Partner' : 'Számla' }} adatai
                        </h5>
                    </div>

                    <div class="modal-body p-0">
                        <form [formGroup]="formGroup" autocomplete="off">
                            <app-ertekesites-szamla-adatai *ngIf="sideForm === 'szamla'" [arkategoriak]="
                  arkategoriak
                    | filterArkategoriakByPartner
                      : partnerek
                      : formGroup.get('partner_id')?.value
                " [fizetesiModok]="fizetesiModok" [penznemek]="penznemek" (close)="closeSidebar()"></app-ertekesites-szamla-adatai>

                            <app-ertekesites-partner-adatai *ngIf="sideForm === 'partner'" [partnerek]="partnerek" (kedvezmenyPartner)="onPartner($event)" (close)="closeSidebar()"></app-ertekesites-partner-adatai>
                        </form>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" (click)="closeSidebar()">
              Bezárás
            </button>
                    </div>
                </div>
            </div>
        </div>
    </core-sidebar>
</div>

<ng-template #tableTemplate>
    <div class="d-flex flex-column h-100" style="width: 100%">
        <div class="card-header">
            <h1 class="brand-text">Értékesítés</h1>
        </div>

        <div class="card-body">
            <app-ertekesites-tetelek [raktarId]="user.raktar" [selectedPenznem]="
            formGroup.get('penznem_id')?.value
            | findByKey : 'id' : penznemek
          " [ertekesitesForm]="formGroup" [arfolyamok]="arfolyamok" [kedvezmeny]="kedvezmeny"></app-ertekesites-tetelek>
        </div>

        <div class="footer-wrapper">
            <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #footerTemplate>
    <div class="w-100 d-flex flex-row justify-content-between">
        <div class="d-flex float-start">
            <div class="form-control mb-0">
                <span class="font-weight-bold">Tételek száma:</span> {{ formGroup.get('tetelek')?.value?.length }} darab |

                <span class="font-weight-bold">Nettó összesen:</span> {{ formGroup.get('netto_ar')?.value || 0 | number: '1.0-0' }} {{ ( formGroup.get('penznem_id')?.value | findByKey : 'id' : penznemek )?.outfix }} |

                <span class="font-weight-bold">Áfa összesen:</span> {{ formGroup.get('afa_ar')?.value || 0 | number: '1.0-0' }} {{ ( formGroup.get('penznem_id')?.value | findByKey : 'id' : penznemek )?.outfix }} |

                <span class="font-weight-bold">Bruttó összesen:</span> {{ formGroup.get('brutto_ar')?.value || 0 | number: '1.0-0' }} {{ ( formGroup.get('penznem_id')?.value | findByKey : 'id' : penznemek )?.outfix }}
            </div>
        </div>

        <div class="flex flex-row">
            <button type="button" class="btn" [ngClass]="
          formGroup.get('partner_id')?.value &&
          formGroup.get('cim')?.value &&
          formGroup.get('cim')?.valid
            ? 'btn-success'
            : 'btn-outline-secondary'
        " (click)="openPartnerAdatai()">
        Partner adatai
      </button>

            <!-- TODO: Dynamic btn class -->
            <button type="button" class="btn" [ngClass]="
          formGroup.get('szamla_kelte')?.value &&
          formGroup.get('fizetesi_mod_id')?.value &&
          formGroup.get('teljesites_datum')?.value &&
          formGroup.get('fizetesi_hatarido')?.value
            ? 'btn-success'
            : 'btn-danger'
        " (click)="openSzamlaAdatai()">
        Számla adatai
      </button>

            <div id="divier"></div>

            <button id="szallitolevel-btn" type="button" class="btn btn-primary" [disabled]="
          formGroup.invalid ||
          !formGroup.get('partner_id')?.value ||
          !formGroup.get('cim')?.value
        " (click)="onSubmit('szallitolevel')">
        Szállítólevél
      </button>

            <button id="nyugta-btn" type="button" class="btn btn-primary" [disabled]="formGroup.invalid ||
            formGroup.get('fizetesi_mod_id')?.value != 6 && 
            formGroup.get('fizetesi_mod_id')?.value != 8 " (click)="onSubmit('nyugta')">
        Nyugta
      </button>

            <button id="szamlabtn" type="button" class="btn btn-primary" [disabled]="
          formGroup.invalid ||
          !formGroup.get('partner_id')?.value ||
          !formGroup.get('cim')?.value
        " (click)="onSubmit('kimeno-szamla')">
        Számla
      </button>
        </div>
    </div>
</ng-template>

<!-- <ng-template #detailsWrapperTemplate>
  <app-torzsek-details
    class="modal-body-pt-0 ertekesites-details"
    [editMode]="false"
    [modalTitle]="'Értékesítés'"
    [footerTemplate]="footerTemplate"
    [invalidForm]="formGroup?.invalid || formGroup?.pristine"
    [openSidebar]="false"
    (cancel)="navigateToList()"
  >
    <div class="content-area-wrapper">
      <div class="ertekesites-container">
        <div class="top">
          <div class="left">
            <app-ertekesites-partner-adatai
              [formGroup]="formGroup"
              [partnerek]="partnerek"
            ></app-ertekesites-partner-adatai>
          </div>

          <div class="center">
            <app-ertekesites-szamla-adatai
              [formGroup]="formGroup"
              [fizetesiModok]="fizetesiModok"
              [penznemek]="penznemek"
              [arkategoriak]="arkategoriak"
              [torzs]="torzs"
            ></app-ertekesites-szamla-adatai>
          </div>

          <div class="right"></div>
        </div>

        <div class="bottom">
          <app-ertekesites-tetelek
            [torzs]="torzs"
            [raktarId]="user.raktar"
          ></app-ertekesites-tetelek>
        </div>
      </div>
    </div>

    <ng-template #footerTemplate>
      <div class="w-100 d-flex flex-row justify-content-between">
        <div class="d-flex float-start">
          <div class="form-control mb-0">
            <span class="font-weight-bold">Tételek száma:</span>
            {{ formGroup.get('tetelek')?.value?.length }} darab |

            <span class="font-weight-bold">Nettó összesen:</span>
            {{ formGroup.get('netto_ar')?.value || 0 | number: '1.0-0' }} Ft |

            <span class="font-weight-bold">Bruttó összesen:</span>
            {{ formGroup.get('brutto_ar')?.value || 0 | number: '1.0-0' }} Ft
          </div>
        </div>

        <div class="footer-wrapper">
          <button
            id="szallitolevel-btn"
            type="button"
            class="btn btn-primary"
            [disabled]="
              formGroup.invalid ||
              !formGroup.get('partner_id')?.value ||
              !formGroup.get('cim')?.value
            "
            (click)="onSubmit('szallitolevel')"
          >
            Szállítólevél
          </button>

          <button
            id="nyugta-btn"
            type="button"
            class="btn btn-primary"
            [disabled]="formGroup.invalid"
            (click)="onSubmit('nyugta')"
          >
            Nyugta
          </button>

          <button
            id="szamlabtn"
            type="button"
            class="btn btn-primary"
            [disabled]="
              formGroup.invalid ||
              !formGroup.get('partner_id')?.value ||
              !formGroup.get('cim')?.value
            "
            (click)="onSubmit('kimeno-szamla')"
          >
            Számla
          </button>
        </div>
      </div>
    </ng-template>
  </app-torzsek-details>
</ng-template> -->