import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { shortCimekColumns } from '../../constants/data';
import { FormService } from '../../services/_form.service';
import { oneRequiredValidator } from './adoszam-vagy-csoportos-adoszam.validator';

@Injectable({ providedIn: 'root' })
export class PartnerekFormService {
  constructor(private fb: FormBuilder, private formService: FormService) {}

  resetForm(): FormGroup {
    return this.createFormGroup();
  }

  createFormGroup(): FormGroup {
    const group = this.fb.group({
      id: [null],
      alapAdatok: this.fb.group({
        vevo: [false],
        szallito: [false],
        nev: [null, Validators.required],
        telefonszam: [null],
        //email: [null, Validators.email],
        email: [null],
        ugyfel_tipus: ['JS', Validators.required],
        adoszam: [null],
        csoportos_adoszam: [null],
        eu_adoszam: [null],
        adoszam_k: [null],
        cegjegyzekszam: [null],
      }),
      gazdasagiAdatok: this.fb.group({
        arkategoria_id: [[3], Validators.required],
        penznem_id: [1, Validators.required],
        fizetesimod_id: [null, Validators.required],
        utalasi_napok: [null, Validators.min(1)],
        kedvezmeny: [0, Validators.max(50)],
        hitelkeret: [null]
      }),
      cim: [[]],
      bankszamlaszamok: [[]],
      ugyintezok: [[]],
      fajlok: [[]]
    });

    group
      .get('alapAdatok')
      .setValidators(
        oneRequiredValidator(
          group.get('alapAdatok.adoszam'),
          group.get('alapAdatok.csoportos_adoszam')
        )
      );

    return group;
  }

  createModalForm(): FormGroup {
    return this.fb.group({
      id: [null],
      alapAdatok: this.fb.group({
        vevo: [true],
        nev: [null, Validators.required],
        ugyfel_tipus: ['JS', Validators.required],
        adoszam: [null],
        csoportos_adoszam: [null],
        eu_adoszam: [null],
      }),
      gazdasagiAdatok: this.fb.group({
        arkategoria_id: [[3], Validators.required],
        penznem_id: [1, Validators.required],
        fizetesimod_id: [null, Validators.required],
      }),
      cim: this.formService.createFormGroup(shortCimekColumns),
    });
  }
}
