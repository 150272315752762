import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { BehaviorSubject, of, Observable, throwError } from 'rxjs';
import {
  switchMap,
  take,
  tap,
  catchError,
  map,
  concatMap,
} from 'rxjs/operators';
import { Cikkcsoport, Column } from '../models/torzsek';

@Injectable({
  providedIn: 'root',
})
export class CikkcsoportokService {
  private readonly apiEndpoint = 'cikkcsoportok';
  private readonly columnsEndpoint = 'cikkcsoportok/columns';

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  public data$ = this._data.pipe(
    switchMap((state: any[]) => (state ? of(state) : this.fetchData()))
  );

  constructor(private api: ApiService, private stateService: StateService) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) => (state ? of(state) : this.fetchData()))
    );
  }

  get noTree$(): Observable<Cikkcsoport[]> {
    return this.api.get(this.apiEndpoint).pipe(
      catchError((err) => {
        return of([]);
      })
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, data).pipe(
      concatMap(() => this.fetchData()),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      concatMap(() => this.fetchData()),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<Cikkcsoport> {
    return this.api.getById(this.apiEndpoint, id).pipe(
      catchError((err) => {
        console.log('Not found or errored out');

        return of(null);
      })
    );
  }

  update(data: any): Observable<any> {
    return this.api.put(`${this.apiEndpoint}/${data.id}`, data).pipe(
      concatMap(() => this.fetchData()),
      catchError((err: any) => throwError(err))
    );
  }

  private fetchData(): Observable<any[]> {
    return this.api.get(`${this.apiEndpoint}`).pipe(
      tap((data: any[]) => this._data.next(data)),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      map((columns: Column[]) => {
        const displayedColumns = ['nev', 'leiras'];

        return columns.map((c: Column) =>
          displayedColumns.includes(c.name) ? c : { ...c, hidden: true }
        );
      }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }
}
