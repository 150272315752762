import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { CoreCommonModule } from '@core/common.module';
import { AuthInterceptor } from 'app/auth/helpers/auth.interceptor';

import { AuthLoginV2Component } from 'app/main/pages/authentication/auth-login-v2/auth-login-v2.component';

@NgModule({
  declarations: [AuthLoginV2Component],
  imports: [CoreCommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
