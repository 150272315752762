import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarEventModalComponent } from './calendar-event-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { FormsModule } from '@angular/forms';
import { SharedDirectivesModule } from 'app/main/shared/shared-directives.module';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [CalendarEventModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    QuillModule.forRoot(),
    SharedDirectivesModule,
  ],
  exports: [CalendarEventModalComponent],
})
export class CalendarEventModalModule {}
