<div class="toolbar-wrapper">
    <ng-container *ngTemplateOutlet="searchMode ? searchTemplate : toolbarTemplate"></ng-container>
</div>

<ng-template #toolbarTemplate>
    <form [formGroup]="formGroup" autocomplete="off">
        <div class="toolbar-container" [ngClass]="layout">
            <!-- Toggle searchbar -->
            <div id="toggle-search-btn" *ngIf="routeErtekesites != 'bejovo-koltseg'">
                <button class="btn btn-outline-primary btn-icon float-right" type="button" (click)="showSearch()">
                    <span [data-feather]="'search'"></span>
                </button>
            </div>

            <!-- Cikk -->
            <div id="cikk-select" *ngIf="routeErtekesites != 'bejovo-koltseg'">
                <ng-select #cikkSelect id="cikk" name="cikk" autocomplete="off" [items]="cikkek" [virtualScroll]="true" [searchFn]="customSearchFn" placeholder="Cikk hozzáadása" bindLabel="cikknev" bindValue="id" formControlName="cikk_id" [clearable]="false" (open)="onSelectFocus()"
                    (change)="onCikkSelect($event)">
                    <ng-template ng-option-tmp let-item="item">
                        <div class="d-flex flex-row justify-content-between" *ngIf="item.id != 'vev001'">
                            <div class="d-flex flex-column flex-grow-1 justify-content-between" style="width: calc(100% - 85px)">
                                <div class="overflow-hidden" style="text-overflow: ellipsis; white-space: nowrap">
                                    <span ngbTooltip="{{ item.cikknev }}" container="body" placement="right" [openDelay]="0">{{
                                        item.cikknev }}</span>
                                </div>

                                <div>{{ item?.cikkszam || '' }}</div>

                                <div *ngIf="item.cikkcsoport_id != 57">
                                    <span ngbTooltip="Elérhető mennyiség" container="body" placement="bottom" [openDelay]="300">{{
                                        item?.aktualis_mennyiseg || 0 }}
                                        {{
                                        item.me_id
                                        | findByKey : 'id' : mennyisegiEgysegek : 'rovidnev'
                                        }}
                                    </span> /

                                    <span ngbTooltip="Lefoglalt mennyiség" container="body" placement="bottom" [openDelay]="300">
                                        {{ item?.foglalas || 0 }}
                                        {{
                                        item.me_id
                                        | findByKey : 'id' : mennyisegiEgysegek : 'rovidnev'
                                        }}
                                    </span>
                                </div>
                            </div>

                            <div class="d-flex flex-column justify-content-between text-right" style="flex-basis: 85px" *ngIf="!item.fix">
                                <span ngbTooltip="Kiskerár" container="body" placement="right" [openDelay]="300">{{
                                    item?.fix_ar || 0
                                    | number : '1.0-0' }} Ft</span>

                                <span ngbTooltip="Kivitelezői ár" container="body" placement="right" [openDelay]="300">{{
                                    item?.kivitelezoiAr || 0 | number : '1.0-0' }} Ft</span>

                                <span ngbTooltip="Viszonteladói ár" container="body" placement="right" [openDelay]="300">{{
                                    item?.viszonteladoiAr || 0 | number : '1.0-0' }} Ft</span>
                            </div>

                            <div class="d-flex flex-column justify-content-between text-right" style="flex-basis: 85px" *ngIf="item.fix">
                                <span ngbTooltip="Fix ár" container="body" placement="right" [openDelay]="300">{{
                                    item?.fix_ar || 0
                                    | number : '1.0-0' }} Ft</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-select>
            </div>

            <!-- Bejovo koltseg cikk input -->
            <div *ngIf="routeErtekesites == 'bejovo-koltseg'" class="input-group input-group-merge cikk-input">
                <!-- Input -->
                <input #cikkKoltseg type="text" class="form-control" placeholder="Cikk megadása" formControlName="cikknev" />
            </div>

            <div id="mennyiseg-input-wrapper" class="input-group">
                <input #mennyisegInput id="mennyiseg-input" type="text" decimalMarker="," class="form-control" placeholder="Mennyiség" aria-label="Mennyiség" [formControl]="mennyisegControl" (keydown)="quickAddToList($event)" />
                <div class="input-group-append" *ngIf="torzs != 'bejovo-koltseg'">
                    <span *ngIf="!formGroup.get('cikk_id')?.value; else selectTemplate" class="input-group-text" [ngStyle]="{ color: '#cccccc' }">db</span>

                    <ng-template #selectTemplate>
                        <select class="form-control my-select" placeholder="db" formControlName="mennyisegi_egyseg" (change)="onMeChange($event.target.value)">
                            <option value="" selected disabled hidden>db</option>
                            <option *ngFor="
                  let me of mennyisegiEgysegek
                    | filterMeByCikk
                      : formGroup.get('me_id')?.value
                      : formGroup.get('me_id_2')?.value
                      : formGroup.get('me_id_3')?.value
                " [ngValue]="+me.id">
                                {{ me.rovidnev }}
                            </option>
                        </select>
                    </ng-template>
                </div>
                <div class="input-group-append" *ngIf="torzs == 'bejovo-koltseg'">
                    <span *ngIf="!formGroup.get('cikknev')?.value; else selectBejovoKoltsegTemplate" class="input-group-text" [ngStyle]="{ color: '#cccccc' }">db</span>

                    <ng-template #selectBejovoKoltsegTemplate>
                        <select class="form-control my-select" placeholder="db" formControlName="mennyisegi_egyseg">
                            <option value="1" selected disabled hidden>db</option>
                            <option *ngFor="
                  let me of mennyisegiEgysegek
                " [ngValue]="+me.id">
                                {{ me.rovidnev }}
                            </option>
                        </select>
                    </ng-template>
                </div>
            </div>

            <div *ngIf="formGroup.get('netto_ar')" id="afakulcs" class="input-group input-group-merge">
                <ng-select [items]="afakulcsok" [clearable]="false" bindLabel="nev" bindValue="id" placeholder="Áfakulcs" formControlName="afakulcs_id" [required]="'afakulcs_id' | requiredControl : formGroup">
                    <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.nev">{{ item.nev }} <br />
                                <small>{{ item.afakulcs }} % </small>
                            </span>
                    </ng-template>
                </ng-select>
            </div>

            <!-- Kedvezmény -->
            <div *ngIf="formGroup.get('kedvezmeny') && arkategoriaId == 3" class="input-group input-group-merge kedvezmeny-input">
                <!-- Input -->
                <input *ngIf="formGroup.get('kedvezmeny')" type="text" mask="separator.0" class="form-control" placeholder="Kedvezmény" formControlName="kedvezmeny" (keyup)="quickAddToList($event)" />

                <!-- Suffix -->
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>

            <!-- Nettó ár -->
            <div *ngIf="formGroup.get('netto_egysegar')" class="input-group input-group-merge ar-input">
                <!-- Input -->
                <input *ngIf="formGroup.get('netto_egysegar')" type="text" mask="separator.2" decimalMarker="," class="form-control" placeholder="(N) egységár" formControlName="netto_egysegar" (keyup)="quickAddToList($event)" />

                <!-- Suffix -->
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>

            <!-- Bruttó ár -->
            <div *ngIf="formGroup.get('brutto_egysegar')" class="input-group input-group-merge ar-input">
                <!-- Input -->
                <input *ngIf="formGroup.get('brutto_egysegar')" type="text" mask="separator.2" class="form-control" placeholder="(B) egységár" formControlName="brutto_egysegar" (keyup)="quickAddToList($event)" />

                <!-- Suffix -->
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>

            <!-- Nettó összesen -->
            <div *ngIf="formGroup.get('netto_ar')" class="input-group input-group-merge ar-input">
                <!-- Input -->
                <input *ngIf="formGroup.get('netto_ar')" type="text" [mask]="selectedPenznem?.id == 1 ? 'separator.0' : 'separator.2'" decimalMarker="," class="form-control" placeholder="Nettó ár" formControlName="netto_ar" />

                <!-- Suffix -->
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>

            <!-- Áfa összesen -->
            <div *ngIf="formGroup.get('afa_ar')" class="input-group input-group-merge ar-input">
                <!-- Input -->
                <input *ngIf="formGroup.get('afa_ar')" type="text" [mask]="selectedPenznem?.id == 1 ? 'separator.0' : 'separator.2'" decimalMarker="," class="form-control" placeholder="Áfa ár" formControlName="afa_ar" />

                <!-- Suffix -->
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>

            <!-- Br összesen -->
            <div *ngIf="formGroup.get('brutto_ar')" class="input-group input-group-merge ar-input">
                <!-- Input -->
                <input *ngIf="formGroup.get('brutto_ar')" type="text" [mask]="selectedPenznem?.id == 1 ? 'separator.0' : 'separator.2'" decimalMarker="," class="form-control" placeholder="Bruttó ár" formControlName="brutto_ar" />

                <!-- Suffix -->
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>

            <div *ngIf="torzs.includes('bevet')" class="ar-input">
                <!-- Eladási ár -->
                <div class="input-group input-group-merge">
                    <!-- Beszerzési egységár -->
                    <input *ngIf="formGroup.get('beszerzesi_ar')" mask="separator" decimalMarker="," type="text" class="form-control" placeholder="Beszerzési egységár" formControlName="beszerzesi_ar" (keyup)="quickAddToList($event)" />

                    <div class="input-group-append">
                        <span class="input-group-text">Ft</span>
                    </div>
                </div>
            </div>

            <div *ngIf="torzs.includes('bevet')" class="ar-input">
                <!-- Eladási ár -->
                <div class="input-group input-group-merge">
                    <!-- Beszerzési ár -->
                    <input *ngIf="formGroup.get('beszerzesi_ar_osszeg')" mask="separator" decimalMarker="," type="text" class="form-control" placeholder="Beszerzési ár" formControlName="beszerzesi_ar_osszeg" (keyup)="quickAddToList($event)" />

                    <div class="input-group-append">
                        <span class="input-group-text">Ft</span>
                    </div>
                </div>
            </div>

            <div *ngIf="torzs.includes('kivet')" class="ar-input">
                <!-- Eladási ár -->
                <div class="input-group input-group-merge">
                    <input *ngIf="formGroup.get('eladasi_ar')" type="text" mask="separator.2" class="form-control" placeholder="Eladási ár" formControlName="eladasi_ar" (keyup)="quickAddToList($event)" />

                    <div class="input-group-append">
                        <span class="input-group-text">Ft</span>
                    </div>
                </div>
            </div>

            <div id="submit-btn">
                <!-- <button *ngIf="torzs == 'bevetelezes'" class="btn btn-icon float-right" [ngClass]="beszerar_update ? 'btn-success' : 'btn-outline-secondary'" [ngStyle]="{ 'margin-right': '3px' }" type="button" [disabled]="formGroup?.invalid || formGroup?.pristine" (click)="beszerar_update = !beszerar_update"
                    data-toggle="tooltip" data-placement="top" title="Beszerzési ár frissítése">
            <span [data-feather]="'save'"></span>
            </button> -->

                <button *ngIf="!selectedItem?.dataItem && torzs != 'bejovo-szamla'" class="btn btn-primary btn-icon float-right" type="button" [disabled]="formGroup?.invalid || formGroup?.pristine || formGroup?.brutto_ar" (click)="onAdd()">
                        <span [data-feather]="'plus-square'"></span>
                </button>

                <button *ngIf="!selectedItem?.dataItem && torzs == 'bejovo-szamla'" class="btn btn-primary btn-icon float-right" type="button" [disabled]="formGroup?.invalid || formGroup?.pristine || formGroup?.brutto_ar || bejovoSzamlaForm?.invalid" (click)="onAdd()">
                    <span [data-feather]="'plus-square'"></span>
                </button>

                <button *ngIf="selectedItem?.dataItem" class="btn btn-outline-secondary btn-icon float-right" type="button" [ngStyle]="{ 'margin-right': '3px' }" (click)="onResetSelection()">
                        <span [data-feather]="'clipboard'"></span>
                    </button>

                <button *ngIf="selectedItem?.dataItem && torzs != 'bejovo-szamla'" class="btn btn-primary btn-icon float-right" type="button" [disabled]="(formGroup?.invalid || formGroup?.pristine)" (click)="onAdd()">
                        <span [data-feather]="'edit'"></span>
                    </button>

                <button *ngIf="selectedItem?.dataItem && torzs == 'bejovo-szamla'" class="btn btn-primary btn-icon float-right" type="button" [disabled]="(formGroup?.invalid || formGroup?.pristine || bejovoSzamlaForm?.invalid) && bejovoSzamlaForm?.pristine" (click)="onAdd()">
                        <span [data-feather]="'edit'"></span>
                    </button>

                <ng-container *ngIf="customToolbarTemplate" [ngTemplateOutlet]="customToolbarTemplate">
                </ng-container>
            </div>
        </div>
    </form>
    <form [formGroup]="bejovoSzamlaForm" style="width: 100% !important;" class="mt-1 mb-1" *ngIf="torzs == 'bejovo-szamla'">
        <div [ngClass]="layout" class="toolbar-container">
            <div class="text mr-1" style="padding-top: 10px !important;">Beszerzési ár: </div>
            <div class="input-group input-group-merge ar-input">
                <input type="text" mask="separator.2" decimalMarker="," class="form-control" formControlName="beszerzesi_ar" disabled/>
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>
            <div class="text mr-1" style="padding-top: 10px !important;">Utolsó eladási kiskerár: </div>
            <div class="input-group input-group-merge ar-input">
                <input type="text" mask="separator.2" decimalMarker="," class="form-control" formControlName="utolso_ar" disabled/>
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>
            <div class="input-group input-group-merge ar-input">
                <input type="text" mask="separator.2" decimalMarker="," class="form-control" placeholder="Nettó fix" formControlName="netto_fix" />
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>
            <div class="input-group input-group-merge ar-input">
                <input type="text" mask="separator.2" decimalMarker="," class="form-control" placeholder="Bruttó fix" formControlName="fix_ar" />
                <div class="input-group-append">
                    <span *ngIf="torzs != 'ertekesites'" class="input-group-text">{{selectedPenznem?.outfix}}</span>
                    <span *ngIf="torzs == 'ertekesites'" class="input-group-text">Ft</span>
                </div>
            </div>
            <div class="text mr-1" style="padding-top: 10px !important;">Raktárkészlet: </div>
            <div class="input-group input-group-merge ar-input">
                <input type="text" mask="separator.2" decimalMarker="," class="form-control" formControlName="aktualis_mennyiseg" disabled/>
                <div class="input-group-append">
                    <span class="input-group-text">{{selectedCikk?.me_text.split('/')[0]}}</span>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #searchTemplate>
    <div id="searchbar">
        <div class="input-group input-group-merge">
            <div class="input-group-prepend">
                <span class="input-group-text"><span [data-feather]="'search'"></span></span>
            </div>
            <input #searchInput type="text" placeholder="Keresés..." class="form-control float-right" (keyup)="onKeyup($event)" />
            <div class="input-group-append">
                <span class="input-group-text">
                    <button id="search-close" class="btn btn-icon" type="button" (click)="hideSearch()">
                        <span [data-feather]="'x'"></span>
                </button>
                </span>
            </div>
        </div>
    </div>
</ng-template>