import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import {
  switchMap,
  take,
  tap,
  catchError,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { findByProp } from '../utils';
import { OnelszamoloEgysegekService } from './onelszamolo-egysegek.service';
import { PartnerekService } from './partnerek.service';
import { RaktarakService } from './raktarak.service';
import { CikktorzsService } from './cikktorzs.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class BevetelezesService {
  private readonly apiEndpoint = 'bevetelezes';
  private readonly columnsEndpoint = 'bevetelezes/columns';
  private readonly updateEndpoint = 'service/helplib/bizonylat_frissites/b';
  private readonly nyomtatasBeallitasEndpoint = 'service/helplib/nyomtatas_kapcsolo/b';

  private lastFetch: Date = new Date();
  private bevetelezes = JSON.parse(localStorage.getItem('bevetelezes'));

  private raktarId = JSON.parse(localStorage.getItem('currentUser'))?.raktar;

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  // private state$ = combineLatest([
  //   this._data,
  //   this.oeService.data$,
  //   this.raktarakService.data$,
  //   this.partnerekService.data$,
  //   this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar)
  // ]).pipe(
  //   switchMap(([state, oek, raktarak, partnerek, cikkek]) => {
  //     return state
  //       ? of(this.mapBevetelezesek(state, oek, raktarak, partnerek, cikkek))
  //       : this.bevetelezes?.db > 0 ? this.getDBData() : this.fetchData()
  //   })
  // );

  private state$ = combineLatest([
    this._data,
    this.oeService.data$,
    this.raktarakService.data$,
    this.partnerekService.data$,
    //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar)
  ]).pipe(
    switchMap(([state, oek, raktarak, partnerek]) =>
      state
        ? of(this.mapBevetelezesek(state, oek, raktarak, partnerek))
        : this.fetchData()
    )
  );

  public data$ = combineLatest([
    this.state$,
    this.authService.currentUser$,
  ]).pipe(map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe)));

  constructor(
    private authService: AuthenticationService,
    private oeService: OnelszamoloEgysegekService,
    private partnerekService: PartnerekService,
    private raktarakService: RaktarakService,
    private api: ApiService,
    private stateService: StateService,
    private cikkService: CikktorzsService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.oeService.initData$,
  //             this.raktarakService.initData$,
  //             this.partnerekService.initData$,
  //             this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar)
  //           ]).pipe(
  //             map(([bevetelezesek, oek, raktarak, partnerek, cikkek]) =>
  //               this.mapBevetelezesek(bevetelezesek, oek, raktarak, partnerek, cikkek)
  //             )
  //           )
  //         : JSON.parse(localStorage.getItem('bevetelezes'))?.db > 0 ? this.getDBData() : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  //   );
  // }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.oeService.initData$,
              this.raktarakService.initData$,
              this.partnerekService.initData$,
              //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar)
            ]).pipe(
              map(([bevetelezesek, oek, raktarak, partnerek]) =>
                this.mapBevetelezesek(bevetelezesek, oek, raktarak, partnerek)
              )
            )
          : this.fetchData()
      ),
      withLatestFrom(this.authService.currentUser$),
      map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, data).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('bevetelezes', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('bevetelezes', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('bevetelezes', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.oeService.initData$,
      this.raktarakService.initData$,
      this.partnerekService.initData$,
      //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar)
    ]).pipe(
      map(([bevetelezes, oek, raktarak, partnerek]) => {
        const retVal = this.mapBevetelezes(bevetelezes, oek, raktarak, partnerek)
        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  checkSztornozhato(id: number): Observable<any> {
    return forkJoin([
      this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar),
      this.fetchAndMapDataById(id)
    ]).pipe(
      map(([raktarkeszlet, bevetelezes]) => {
        let sztornozhato = true;
        bevetelezes.tetelek.forEach(tetel => {
          if(raktarkeszlet?.find(item => tetel.cikk_id == item.id)?.aktualis_mennyiseg < tetel.mennyiseg) {
            sztornozhato = false;
          }
        });

        return sztornozhato;
      })
    )
  }

  update(data: any): Observable<any> {
    return this.api.put(`${this.apiEndpoint}/${data.id}`, data).pipe(
      tap((updated: any) => {
        this.stateService.setStateOnUpdate(updated, this._data);
        //this.dbService.update('bevetelezes', updated).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getDBData(): Observable<any> {
    console.log('get bevetelezes from indexedDB')
    // return this.dbService.getAll('nyugta').pipe(
    //   tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
    // );
    return forkJoin([
      this.dbService.getAll('bevetelezes').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.oeService.initData$,
      this.raktarakService.initData$,
      this.partnerekService.initData$,
      //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar)
    ]).pipe(
      map(([bevetelezesek, oek, raktarak, partnerek]) => {
        return this.mapBevetelezesek(bevetelezesek, oek, raktarak, partnerek)
      })
    );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem('currentUser')).oe;
    const last = JSON.parse(localStorage.getItem('bevetelezes'))?.lastFetch;
    return this.api.postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
           .pipe(
            take(1),
            tap(valtozas => {
              if (this._data.value.length == 0) {
                //this.fetchData().subscribe();
              } else {
                if (valtozas.valtozasok.length > 0) {
                  valtozas.valtozasok.forEach((bizonylat) => {
                    const biz = findByProp(this._data.value, "id", bizonylat.id);
                    if (biz) {
                      this.stateService.setStateOnUpdate(bizonylat, this._data);
                    } else {
                      this.stateService.setStateOnAdd(bizonylat, this._data);
                    }
                  });
                }
                if (valtozas.torolt_lista.length > 0) {
                  valtozas.torolt_lista.forEach((bizonylat) => {
                    const biz = findByProp(
                      this._data.value,
                      "id",
                      bizonylat.modul_id
                    );
                    if (biz) {
                      this.stateService.setStateOnDelete(biz, this._data);
                    }
                  });
                }
              }
    
              localStorage.setItem(
                "bevetelezes",
                JSON.stringify({
                  db: this._data.value.length,
                  lastFetch: this.lastFetch,
                })
              );
            }))
  }


  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.oeService.initData$,
      this.raktarakService.initData$,
      this.partnerekService.initData$,
      //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar)
    ]).pipe(
      map(([bevetelezesek, oek, raktarak, partnerek]) => {
        localStorage.setItem('bevetelezes', JSON.stringify({db: bevetelezesek.length, lastFetch: this.lastFetch}));
        return this.mapBevetelezesek(bevetelezesek, oek, raktarak, partnerek)
      })
    );
  }

  // private fetchData(): Observable<any[]> {
  //   this.lastFetch = new Date();
  //   return forkJoin([
  //     this.api.get(this.apiEndpoint).pipe(
  //       tap((data: any[]) => this._data.next(data)),
  //       catchError((err: any) => {
  //         console.log(err);
  //         return of([]);
  //       })
  //     ),
  //     this.oeService.initData$,
  //     this.raktarakService.initData$,
  //     this.partnerekService.initData$,
  //     this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar)
  //   ]).pipe(
  //     map(([bevetelezesek, oek, raktarak, partnerek, cikkek]) => {
  //       // this.dbService.count('bevetelezes').subscribe(count => {
  //       //   if(count == 0) {
  //       //     this.dbService
  //       //       .bulkAdd('bevetelezes', this.mapBevetelezesek(bevetelezesek, oek, raktarak, partnerek, cikkek))
  //       //       .subscribe((result) => {
                
  //       //       }, error => console.log(error));
  //       //   }
  //       //   localStorage.setItem('bevetelezes', JSON.stringify({db: bevetelezesek.length, lastFetch: this.lastFetch}));
  //       //   //this.cikkek = count;
  //       // })
  //       localStorage.setItem('bevetelezes', JSON.stringify({db: bevetelezesek.length, lastFetch: this.lastFetch}));

  //       return this.mapBevetelezesek(bevetelezesek, oek, raktarak, partnerek, cikkek)
  //     })
  //   );
  // }

  private getColumns(): Observable<Column[]> {
    const columns = JSON.parse(localStorage.getItem('bevetelezes_columns'));

    if(columns) {
      return of(columns);
    } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((columns: Column[]) => {
          const newColumns: Column[] = columns.concat({
            name: 'tetelek',
            nullable: false,
            hidden: true,
          });
  
          const newCol = newColumns.map((c: Column) => {
            const shown = [
              //'id',
              'bizonylat_szam',
              'szallito_id',
              'szall_okmany_szama',
              'teljesites_datum',
              'osszertek',
              'megjegyzes',
            ];
            if (c.name === 'osszertek') {
              return { ...c, suffix: 'Ft', type: 'currency' };
            } else if (!shown.includes(c.name)) {
              return {
                ...c,
                hidden: true,
              };
            }
  
            return c;
          });
  
          localStorage.setItem('bevetelezes_columns', JSON.stringify(newCol));
          return newCol;
        }),
        catchError((err: any) => {
          console.log(err);
  
          return of([]);
        })
      );
    }
  }

  private mapBevetelezesek(
    bevetelezesek: any[],
    oek: any[],
    raktarak: any[],
    partnerek: any[],
    //cikkek: any[]
  ): any[] {
    return bevetelezesek.map((e: any) => {
      let sztornozhato = true;
      // e.tetelek.forEach(tetel => {
      //   if(cikkek?.find(item => tetel.cikk_id == item.id)?.aktualis_mennyiseg < tetel.mennyiseg) {
      //     sztornozhato = false;
      //   }
      // })
      return {
        ...e,
        oe: findByProp(oek, 'id', e.oe_id)?.megnevezes,
        raktar: findByProp(raktarak, 'id', e.raktar_id)?.megnevezes,
        szallito: findByProp(partnerek, 'id', e.szallito_id)?.nev,
        teljesites_datum: new Date(e.teljesites_datum),
        sztornozhato: sztornozhato
      }
    });
  }

  private mapBevetelezes(
    bevetelezes: any,
    oek: any[],
    raktarak: any[],
    partnerek: any[],
    //cikkek: any[]
  ): any[] {
      let sztornozhato = true;
      // bevetelezes.tetelek.forEach(tetel => {
      //   if(cikkek?.find(item => tetel.cikk_id == item.id)?.aktualis_mennyiseg < tetel.mennyiseg) {
      //     sztornozhato = false;
      //   }
      // })
      return {
        ...bevetelezes,
        oe: findByProp(oek, 'id', bevetelezes.oe_id)?.megnevezes,
        raktar: findByProp(raktarak, 'id', bevetelezes.raktar_id)?.megnevezes,
        szallito: findByProp(partnerek, 'id', bevetelezes.szallito_id)?.nev,
        teljesites_datum: new Date(bevetelezes.teljesites_datum),
        sztornozhato: sztornozhato
      }
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .postBizonylat(`${environment.pdfGeneralas}/${this.apiEndpoint}`, payload)
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  nyomtatasBeallitas(row: any, megjegyzes_nyomtat: number, hivatkozas_nyomtat: number = 0): Observable<any> {
    return this.api
    .post(`${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`, '')
    .pipe(
      tap((updated: any) => {
        const updateRow = {
          ...row,
          megjegyzes_nyomtat: megjegyzes_nyomtat,
        }
        this.stateService.setStateOnUpdate(updateRow, this._data);
      }),
      catchError(() => of(null))
    );
  }
}
