import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import Hungarian from 'flatpickr/dist/l10n/hu';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Dolgozo } from '../../models/torzsek';
import { CalendarReworkService } from '../../pages/calendar/calendar-rework.service';
import { EventRef } from '../../pages/calendar/calendar.model';
import { DolgozokService } from '../../services/dolgozok.service';
import { NaptarKategoriakService } from '../../services/naptar-kategoriak.service';
import { CalendarEventModalService } from './calendar-event-modal.service';

@Component({
  selector: 'app-calendar-event-modal',
  templateUrl: './calendar-event-modal.component.html',
  styleUrls: ['./calendar-event-modal.component.scss'],
})
export class CalendarEventModalComponent implements OnInit, OnDestroy {
  //  Decorator
  @ViewChild('startDatePicker') startDatePicker: any;
  @ViewChild('endDatePicker') endDatePicker: any;
  @ViewChild('eventForm') eventForm: NgForm;

  dolgozok$: Observable<Dolgozo[]>;
  kategoriak$: Observable<any[]>;

  // Public
  public event: EventRef;
  public editMode: boolean = false;

  public dateOptions: FlatpickrOptions = {
    locale: Hungarian.hu,
    altInput: true,
    mode: 'single',
    dateFormat: 'Y-m-d H:i',
    altFormat: 'Y-m-d H:i',
    altInputClass:
      'form-control flat-picker flatpickr-input invoice-edit-input',
    enableTime: true,
    time_24hr: true,
  };

  private destroy$: Subject<boolean> = new Subject();

  /**
   *
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CalendarService} _calendarService
   */
  constructor(
    dolgozokService: DolgozokService,
    naptarKategoriakService: NaptarKategoriakService,
    private service: CalendarEventModalService,
    private _calendarService: CalendarReworkService
  ) {
    this.dolgozok$ = dolgozokService.data$;
    this.kategoriak$ = naptarKategoriakService.data$;
  }

  get startDate(): Date {
    return this.startDatePicker.flatpickrElement.nativeElement.children[0]
      .value;
  }

  get endDate(): Date {
    return this.endDatePicker.flatpickrElement.nativeElement.children[0].value;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._calendarService.selectedData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((editData: EventRef) => {
        console.log('Selected data in event sidebar', editData);
        this.event = editData;

        // If Event is available
        if (Object.keys(editData).length > 0) {
          this.event = editData;

          this.editMode = editData.id ? true : false;
        }
        // else Create New Event
        else {
          this.event = new EventRef();

          // Clear Flatpicker Values
          setTimeout(() => {
            this.startDatePicker.flatpickr.clear();
            this.endDatePicker.flatpickr.clear();
            this.eventForm.reset();
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle Event Sidebar
   */
  closeModal() {
    this.service.closeEventModal();
  }

  /**
   * Add Event
   */
  addEvent() {
    this.event.start = this.startDate;
    this.event.end = this.endDate;

    this._calendarService.add(this.event).subscribe(() => {
      this.closeModal();
      this.resetForm();
    });
  }

  /**
   * Update Event
   */
  updateEvent() {
    this.event.start = this.startDate;
    this.event.end = this.endDate;

    this._calendarService.update(this.event).subscribe(() => {
      this.closeModal();
      this.resetForm();
    });
  }

  /**
   * Delete Event
   */
  deleteEvent() {
    this._calendarService.delete(this.event.id).subscribe(() => {
      this.closeModal();
      this.resetForm();
    });
  }

  resetDatepickers(): void {
    this.startDatePicker.flatpickr.clear();
    this.endDatePicker.flatpickr.clear();
  }

  private resetForm(): void {
    this.eventForm.reset();
    this.resetDatepickers();
  }
}
