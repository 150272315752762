export type UgyfelTipusId = 'MS' | 'AM' | 'JS' | 'KJS' | 'OT';

export interface UgyfelTipus {
  readonly id: UgyfelTipusId;
  readonly title: string;
  readonly fullTitle: string;
}

export const ugyfelTipusok: UgyfelTipus[] = [
  { id: 'MS', title: 'magánszemély', fullTitle: 'MS - magánszemély' },
  {
    id: 'AM',
    title: 'adószámos magánszemély',
    fullTitle: 'AM - adószámos magánszemély',
  },
  { id: 'JS', title: 'jogi személy', fullTitle: 'JS - jogi személy' },
  {
    id: 'KJS',
    title: 'külföldi jogi személy',
    fullTitle: 'KJS - Külföldi jogi személy',
  },
  { id: 'OT', title: 'egyéb', fullTitle: 'OT - egyéb' },
];
