import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcActionsColWidth',
})
export class CalcActionsColWidthPipe implements PipeTransform {
  transform(actions: { [key: string]: boolean }): number {
    const minWidth = 120;
    const calcedWidth = Object.keys(actions).length * 45;

    return calcedWidth < minWidth ? minWidth : calcedWidth;
  }
}
