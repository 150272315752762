import { NgModule } from '@angular/core';

import { CoreCommonModule } from '@core/common.module';
import { TorzsekDetailsModule } from '../../components/torzsek-details/torzsek-details.module';
import { SharedModule } from 'app/main/shared/shared.module';
import { TorzsekCimFormModule } from '../../components/torzsek-cim-form/torzsek-cim-form.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CardBasicModule } from 'app/main/ui/card/card-basic/card-basic.module';
import { TorzsekBasicTableModule } from '../../components/torzsek-basic-table/torzsek-basic-table.module';
import { InvoiceModule } from '../invoice/invoice.module';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TetelekToolbarModule } from '../../components/torzsek-basic-table/toolbars/tetelek-toolbar/tetelek-toolbar.module';
import { PenzugyiBizonylatokPipesModule } from 'app/main/shared/pipes/penzugyi-bizonylatok-pipes/penzugyi-bizonylatok-pipes.module';
import { RouterModule } from '@angular/router';
import { ErtekesitesDetailsResolver } from './ertekesites-details.resolver';
import { ErtekesitesPartnerAdataiComponent } from './ertekesites-partner-adatai/ertekesites-partner-adatai.component';
import { ErtekesitesSzamlaAdataiComponent } from './ertekesites-szamla-adatai/ertekesites-szamla-adatai.component';
import { ErtekesitesTetelekComponent } from './ertekesites-tetelek/ertekesites-tetelek.component';
import { ErtekesitesTorzsekResolver } from './ertekesites-torzsek.resolver';
import { ErtekesitesComponent } from './ertekesites.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreSidebarModule } from '@core/components';
import { FindByKeyPipeModule } from 'app/main/shared/pipes/find-by-key-pipe/find-by-key.module';
import { CalendarModule } from 'primeng/calendar';
import { PartnerekBizonylatokTransformPipe } from '../dijbekero-eloleg-kimeno/dijbekero-eloleg-kimeno-partner-adatai/partnerek-bizonylatok-transform.pipe';
import { PartnerekErtekesitesTransformPipe } from './ertekesites-partner-adatai/partnerek-ertekesites-transform.pipe';

const routes = [
  {
    path: '',
    component: ErtekesitesComponent,
    resolve: {
      data: ErtekesitesDetailsResolver,
      torzsek: ErtekesitesTorzsekResolver,
    },
  },
];

@NgModule({
  declarations: [
    ErtekesitesComponent,
    ErtekesitesPartnerAdataiComponent,
    ErtekesitesSzamlaAdataiComponent,
    ErtekesitesTetelekComponent,
    PartnerekErtekesitesTransformPipe
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    CoreSidebarModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TetelekToolbarModule,
    NgxDatatableModule,
    TorzsekBasicTableModule,
    TorzsekDetailsModule,
    TorzsekCimFormModule,
    CardBasicModule,
    InvoiceModule,
    PenzugyiBizonylatokPipesModule,
    NgbModalModule,
    NgbModule,
    NgSelectModule,
    TorzsekDetailsModule,
    FindByKeyPipeModule,
    SharedModule,
    CalendarModule,
  ],
  exports: [ErtekesitesComponent],
})
export class ErtekesitesModule {}
