import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor() {}

  setStateOnAdd(data: any, container: BehaviorSubject<any[]>): void {
    // Update state
    if (data) {
      // Jelenlegi tömb
      const current: any[] = container.value || [];
      // Módosított tömb létrehozása
      const newData = [data, ...current];
      // Update BehaviorSubject
      container.next(newData);
    }
    // Missing params
    else {
      console.log('Nincs adat in: "SetStateOnAdd()"');
    }
  }

  setStateOnUpdate(
    data: any,
    container: BehaviorSubject<any[]>,
    matchProp: string = 'id'
  ): void {
    // Update state
    if (data) {
      // Jelenlegi tömb
      const current: any[] = container.value || [];
      const index = current.findIndex(
        (element: any) => element[matchProp] === data[matchProp]
      );
      // Módosított tömb létrehozása
      const newData = [...current];
      // Elem módosítása
      newData[index] = data;
      // Update BehaviorSubject
      container.next(newData);
    }
    // Missing params
    else {
      console.log('Nincs adat in: "SetStateOnUpdate()"');
    }
  }

  setStateOnDelete(
    data: any,
    container: BehaviorSubject<any[]>,
    matchProp: string = 'id'
  ): void {
    // Update state
    if (data) {
      // Jelenlegi tömb
      const current = container.value || [];
      // Módosított tömb létrehozása
      const newData = current.filter(
        (element: any) => element[matchProp] !== data[matchProp]
      );
      // Update BehaviorSubject
      container.next(newData);
    }

    // Missing params
    else {
      console.log('Nincs adat in: "SetStateOnDelete()"');
    }
  }
}
