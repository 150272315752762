import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Observable, Subject, concat, forkJoin, of } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { menu } from 'app/menu/menu';
import { locale as hu } from 'app/menu/i18n/hu';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { CalendarEventModalService } from './main/pages/torzsek/components/calendar-event-modal/calendar-event-modal.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { CikktorzsService } from './main/pages/torzsek/services/cikktorzs.service';
import { PartnerekService } from './main/pages/torzsek/services/partnerek.service';
import { KimenoSzamlaService } from './main/pages/torzsek/services/kimeno-szamla.service';
import { AjanlatService } from './main/pages/torzsek/services/ajanlat.service';
import { BevetelezesService } from './main/pages/torzsek/services/bevetelezes.service';
import { BejovoSzamlaService } from './main/pages/torzsek/services/bejovo-szamla.service';
import { BejovoKoltsegService } from './main/pages/torzsek/services/bejovo-koltseg.service';
import { DijbekeroService } from './main/pages/torzsek/services/dijbekero.service';
import { ElolegszamlaService } from './main/pages/torzsek/services/elolegszamla.service';
import { FoglalasService } from './main/pages/torzsek/services/foglalas.service';
import { KulsoRaktariEladasService } from './main/pages/torzsek/services/kulso-raktari-eladas.service';
import { NyugtaService } from './main/pages/torzsek/services/nyugta.service';
import { SzallitolevelService } from './main/pages/torzsek/services/szallitolevel.service';
import { OrszagokService } from './main/pages/torzsek/services/orszagok.service';
import { PenzintezetekService } from './main/pages/torzsek/services/penzintezetek.service';
import { MegyekService } from './main/pages/torzsek/services/megyek.service';
import { DolgozokService } from './main/pages/torzsek/services/dolgozok.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  defaultLanguage: 'hu'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'hu'; // Set application default language i.e fr
  loading = false;

  shortcuts: ShortcutInput[] = [
    {
      key: ['duplaklikk'],
      label: 'Lista funkciók',
      description: 'Szerkesztés / helyesbítés',
      command: () => {},
    },
    {
      key: ['Shift b'],
      label: 'Gyors elérés (lista nézet / kezdőlap)',
      description: 'Bejövő számla',
      command: () => {
        if (localStorage.getItem('currentUser')) {
          this._router.navigate(['torzsek', 'bejovo-szamla']);
        }
      },
      preventDefault: true,
    },
    {
      key: "shift + ctrl + á",
      label: 'IndexedDB törlés',
      description: 'IndexedDB törlés',
      command: () => {
        this.dbService.clear('ajanlat').subscribe((deleted) => {
          localStorage.removeItem('ajanlat');
        });

        this.dbService.clear('dijbekero').subscribe((deleted) => {
          localStorage.removeItem('dijbekero');
        });

        this.dbService.clear('elolegszamla').subscribe((deleted) => {
          localStorage.removeItem('elolegszamla');
        });

        this.dbService.clear('kimenoszamla').subscribe((deleted) => {
          localStorage.removeItem('kimenoszamla');
        });

        this.dbService.clear('foglalas').subscribe((deleted) => {
          localStorage.removeItem('foglalas');
        });

        this.dbService.clear('bejovoszamla').subscribe((deleted) => {
          localStorage.removeItem('bejovoszamla');
        });

        this.dbService.clear('bejovokoltseg').subscribe((deleted) => {
          localStorage.removeItem('bejovokoltseg');
        });

        this.dbService.clear('bevetelezes').subscribe((deleted) => {
          localStorage.removeItem('bevetelezes');
        });

        this.dbService.clear('szallitolevel').subscribe((deleted) => {
          localStorage.removeItem('szallitolevel');
        });

        this.dbService.clear('megyek').subscribe((deleted) => {
          localStorage.removeItem('megyek');
        });

        this.dbService.clear('cikkek').subscribe((deleted) => {
          localStorage.removeItem('cikkek');
        });

        this.dbService.clear('dolgozok').subscribe((deleted) => {
          localStorage.removeItem('dolgozok');
        });

        this.dbService.clear('orszagok').subscribe((deleted) => {
          localStorage.removeItem('orszagok');
        });

        this.dbService.clear('kulsoraktari').subscribe((deleted) => {
          localStorage.removeItem('kulsoraktari');
        });

        this.dbService.clear('penzintezetek').subscribe((deleted) => {
          localStorage.removeItem('penzintezetek');
        });

        this.dbService.clear('partnerek').subscribe((deleted) => {
          localStorage.removeItem('partnerek');
        });

        this.dbService.clear('nyugta').subscribe((deleted) => {
          localStorage.removeItem('nyugta');
        });

        this.loading = true;

        const allApiCalls = [
          this.dolgozokService.fetchData().pipe(take(1)),
          this.partnerekService.fetchData().pipe(take(1)),
          this.cikktorzsService.fetchData().pipe(take(1)),
          this.penzintezetekService.fetchData().pipe(take(1)),
          this.orszagokService.fetchData().pipe(take(1)),
          this.megyekService.fetchData().pipe(take(1)),
          this.kimenoService.fetchData().pipe(take(1)),
          this.ajanlatService.fetchData().pipe(take(1)),
          this.bejovoKoltsegService.fetchData().pipe(take(1)),
          this.bejovoSzamlaService.fetchData().pipe(take(1)),
          this.bevetelezesService.fetchData().pipe(take(1)),
          this.dijbekeroService.fetchData().pipe(take(1)),
          this.elolegszamlaService.fetchData().pipe(take(1)),
          this.foglalasService.fetchData().pipe(take(1)),
          this.kulsoraktariService.fetchData().pipe(take(1)),
          this.nyugtaService.fetchData().pipe(take(1)),
          this.szallitoService.fetchData().pipe(take(1)),
          of(true)
        ];
    
        const numberOfSimultaneousRequests = 1;
    
        setTimeout(() => {
          concat(...allApiCalls).pipe().subscribe(resp => {
            if(resp == true) {
              this.loading = false;
            }
          });
        }, 3000);
      },
      preventDefault: true,
    },
    {
      key: "shift + cmd + á",
      label: 'IndexedDB törlés',
      description: 'IndexedDB törlés',
      command: () => {
        this.loading = true;

        this.dbService.clear('ajanlat').subscribe((deleted) => {
          localStorage.removeItem('ajanlat');
          //this.ajanlatService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('dijbekero').subscribe((deleted) => {
          localStorage.removeItem('dijbekero');
          //this.dijbekeroService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('elolegszamla').subscribe((deleted) => {
          localStorage.removeItem('elolegszamla');
          //this.elolegszamlaService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('kimenoszamla').subscribe((deleted) => {
          localStorage.removeItem('kimenoszamla');
          //this.kimenoService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('foglalas').subscribe((deleted) => {
          localStorage.removeItem('foglalas');
          //this.foglalasService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('bejovoszamla').subscribe((deleted) => {
          localStorage.removeItem('bejovoszamla');
          //this.bejovoSzamlaService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('bejovokoltseg').subscribe((deleted) => {
          localStorage.removeItem('bejovokoltseg');
          //this.bejovoKoltsegService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('bevetelezes').subscribe((deleted) => {
          localStorage.removeItem('bevetelezes');
          //this.bevetelezesService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('szallitolevel').subscribe((deleted) => {
          localStorage.removeItem('szallitolevel');
          //this.szallitoService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('megyek').subscribe((deleted) => {
          localStorage.removeItem('megyek');
          //this.megyekService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('cikkek').subscribe((deleted) => {
          localStorage.removeItem('cikkek');
          //this.cikktorzsService.fetchData().pipe(take(1)).subscribe()
        });

        this.dbService.clear('dolgozok').subscribe((deleted) => {
          localStorage.removeItem('dolgozok');
          //this.dolgozokService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('orszagok').subscribe((deleted) => {
          localStorage.removeItem('orszagok');
          //this.orszagokService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('kulsoraktari').subscribe((deleted) => {
          localStorage.removeItem('kulsoraktari');
          //this.kulsoraktariService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('penzintezetek').subscribe((deleted) => {
          localStorage.removeItem('penzintezetek');
          //this.penzintezetekService.fetchData().pipe(take(1)).subscribe();
        });

        this.dbService.clear('partnerek').subscribe((deleted) => {
          localStorage.removeItem('partnerek');
          // this.partnerekService.fetchData().pipe(take(1)).subscribe(resp => {
          //   if(resp) {
          //     console.log('final');
          //     this.loading = false;
          //   }
          // });
        });

        this.dbService.clear('nyugta').subscribe((deleted) => {
          localStorage.removeItem('nyugta');
          //this.nyugtaService.fetchData().pipe(take(1)).subscribe();
        });

        const allApiCalls = [
          this.partnerekService.fetchData().pipe(take(1)),
          this.cikktorzsService.fetchData().pipe(take(1)),
          this.kimenoService.fetchData().pipe(take(1)),
          this.ajanlatService.fetchData().pipe(take(1)),
          this.bejovoKoltsegService.fetchData().pipe(take(1)),
          this.bejovoSzamlaService.fetchData().pipe(take(1)),
          this.bevetelezesService.fetchData().pipe(take(1)),
          this.dijbekeroService.fetchData().pipe(take(1)),
          this.elolegszamlaService.fetchData().pipe(take(1)),
          this.foglalasService.fetchData().pipe(take(1)),
          this.kulsoraktariService.fetchData().pipe(take(1)),
          this.nyugtaService.fetchData().pipe(take(1)),
          this.szallitoService.fetchData().pipe(take(1)),
          this.dolgozokService.fetchData().pipe(take(1)),
          this.orszagokService.fetchData().pipe(take(1)),
          this.megyekService.fetchData().pipe(take(1)),
          this.penzintezetekService.fetchData().pipe(take(1)),
          of(true)
        ];
    
        const numberOfSimultaneousRequests = 1;

        setTimeout(() => {
          concat(...allApiCalls).pipe().subscribe(resp => {
            if(resp == true) {
              this.loading = false;
            }
          });
        }, 3000);
      },
      preventDefault: true,
    },
    {
      key: ['Shift b s'],
      label: 'Gyors elérés (lista nézet / kezdőlap)',
      description: 'Bejövő szállítólevél',
      command: () => {
        if (localStorage.getItem('currentUser')) {
          this._router.navigate(['torzsek', 'bevetelezes']);
        }
      },
      preventDefault: true,
    },

    {
      key: ['Shift k'],
      label: 'Gyors elérés (lista nézet / kezdőlap)',
      description: 'Kimenő számla',
      command: () => {
        if (localStorage.getItem('currentUser')) {
          this._router.navigate(['torzsek', 'kimeno-szamla']);
        }
      },
      preventDefault: true,
    },
    {
      key: ['Shift e'],
      label: 'Gyors elérés (lista nézet / kezdőlap)',
      description: 'Naptár',
      command: () => {
        if (localStorage.getItem('currentUser')) {
          this._router.navigate(['torzsek', 'naptar']);
        }
      },
      preventDefault: true,
    },
    {
      key: ['Shift s'],
      label: 'Gyors elérés (lista nézet / kezdőlap)',
      description: 'Szállítólevél',
      command: () => {
        if (localStorage.getItem('currentUser')) {
          this._router.navigate(['torzsek', 'szallitolevel']);
        }
      },
      preventDefault: true,
    },
  ];

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    public _coreConfigService: CoreConfigService,
    private calendarEventModalService: CalendarEventModalService,
    private config: NgSelectConfig,
    private primeConfig: PrimeNGConfig,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dbService: NgxIndexedDBService,
    private cikktorzsService: CikktorzsService,
    private partnerekService: PartnerekService,
    private kimenoService: KimenoSzamlaService,
    private ajanlatService: AjanlatService,
    private bevetelezesService: BevetelezesService,
    private bejovoSzamlaService: BejovoSzamlaService,
    private bejovoKoltsegService: BejovoKoltsegService,
    private dijbekeroService: DijbekeroService,
    private elolegszamlaService: ElolegszamlaService,
    private foglalasService: FoglalasService,
    private kulsoraktariService: KulsoRaktariEladasService,
    private nyugtaService: NyugtaService,
    private szallitoService: SzallitolevelService,
    private orszagokService: OrszagokService,
    private penzintezetekService: PenzintezetekService,
    private megyekService: MegyekService,
    private dolgozokService: DolgozokService
    )
   {
    // Get the application main menu
    this.menu = menu;

    // Register the menu to the menu service
    this._coreMenuService.register('main', this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu('main');

    // Add languages to the translation service
    this._translateService.addLangs(['hu']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('hu');

    // Set the translations for the menu
    this._coreTranslationService.translate(hu);

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Ng select no data text
    this.config.notFoundText = 'Nincs találat.';

    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
              if (child.firstChild) {
                  child = child.firstChild;
              } else if (child.snapshot.data &&    child.snapshot.data['title']) {
                  return child.snapshot.data['title'];
              } else {
                  return null;
              }
          }
          return null;
      })
  ).subscribe( (data: any) => {
      if (data) {
        this.titleService.setTitle(data + ' - Aranyi ERP');
      } else {
        this.titleService.setTitle('Aranyi ERP')
      }
  });
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Init wave effect (Ripple effect)
    Waves.init();

    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;

        // Set application default language.

        // Change application language? Read the ngxTranslate Fix

        // ? Use app-config.ts file to set default language
        const appLanguage = this.coreConfig.app.appLanguage || 'hu';
        this._translateService.use(appLanguage);

        // ? OR
        // ? User the current browser lang if available, if undefined use 'en'
        // const browserLang = this._translateService.getBrowserLang();
        // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

        /**
         * ! Fix : ngxTranslate
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         *
         * Using different language than the default ('en') one i.e French?
         * In this case, you may find the issue where application is not properly translated when your app is initialized.
         *
         * It's due to ngxTranslate module and below is a fix for that.
         * Eventually we will move to the multi language implementation over to the Angular's core language service.
         *
         **/

        // Set the default language to 'en' and then back to 'fr'.

        setTimeout(() => {
          this._translateService.setDefaultLang('hu');
          this._translateService.setDefaultLang(appLanguage);
          this._translateService
            .get('primeng')
            .subscribe((res) => this.primeConfig.setTranslation(res));
        });

        /**
         * !Fix: ngxTranslate
         * ----------------------------------------------------------------------------------------------------
         */

        // Layout
        //--------

        // Remove default classes first
        this._elementRef.nativeElement.classList.remove(
          'vertical-layout',
          'vertical-menu-modern',
          'horizontal-layout',
          'horizontal-menu'
        );
        // Add class based on config options
        if (this.coreConfig.layout.type === 'vertical') {
          this._elementRef.nativeElement.classList.add(
            'vertical-layout',
            'vertical-menu-modern'
          );
        } else if (this.coreConfig.layout.type === 'horizontal') {
          this._elementRef.nativeElement.classList.add(
            'horizontal-layout',
            'horizontal-menu'
          );
        }

        // Navbar
        //--------

        // Remove default classes first
        this._elementRef.nativeElement.classList.remove(
          'navbar-floating',
          'navbar-static',
          'navbar-sticky',
          'navbar-hidden'
        );

        // Add class based on config options
        if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
          this._elementRef.nativeElement.classList.add('navbar-static');
        } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
          this._elementRef.nativeElement.classList.add('navbar-sticky');
        } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
          this._elementRef.nativeElement.classList.add('navbar-floating');
        } else {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }

        // Footer
        //--------

        // Remove default classes first
        this._elementRef.nativeElement.classList.remove(
          'footer-fixed',
          'footer-static',
          'footer-hidden'
        );

        // Add class based on config options
        if (this.coreConfig.layout.footer.type === 'footer-sticky') {
          this._elementRef.nativeElement.classList.add('footer-fixed');
        } else if (this.coreConfig.layout.footer.type === 'footer-static') {
          this._elementRef.nativeElement.classList.add('footer-static');
        } else {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }

        // Blank layout
        if (
          this.coreConfig.layout.menu.hidden &&
          this.coreConfig.layout.navbar.hidden &&
          this.coreConfig.layout.footer.hidden
        ) {
          this._elementRef.nativeElement.classList.add('blank-page');
          // ! Fix: Transition issue while coming from blank page
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName(
              'app-content'
            )[0],
            'style',
            'transition:none'
          );
        } else {
          this._elementRef.nativeElement.classList.remove('blank-page');
          // ! Fix: Transition issue while coming from blank page
          setTimeout(() => {
            this._renderer.setAttribute(
              this._elementRef.nativeElement.getElementsByClassName(
                'app-content'
              )[0],
              'style',
              'transition:300ms ease all'
            );
          }, 0);
          // If navbar hidden
          if (this.coreConfig.layout.navbar.hidden) {
            this._elementRef.nativeElement.classList.add('navbar-hidden');
          }
          // Menu (Vertical menu hidden)
          if (this.coreConfig.layout.menu.hidden) {
            this._renderer.setAttribute(
              this._elementRef.nativeElement,
              'data-col',
              '1-column'
            );
          } else {
            this._renderer.removeAttribute(
              this._elementRef.nativeElement,
              'data-col'
            );
          }
          // Footer
          if (this.coreConfig.layout.footer.hidden) {
            this._elementRef.nativeElement.classList.add('footer-hidden');
          }
        }

        // Skin Class (Adding to body as it requires highest priority)
        if (
          this.coreConfig.layout.skin !== '' &&
          this.coreConfig.layout.skin !== undefined
        ) {
          this.document.body.classList.remove(
            'default-layout',
            'bordered-layout',
            'dark-layout',
            'semi-dark-layout'
          );
          this.document.body.classList.add(
            this.coreConfig.layout.skin + '-layout'
          );
        }
      });

    // Set the application page title
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  fetchData(): any {
    const allApiCalls = [
      this.partnerekService.fetchData().pipe(take(1)),
      this.cikktorzsService.fetchData().pipe(take(1)),
      this.kimenoService.fetchData().pipe(take(1)),
      this.ajanlatService.fetchData().pipe(take(1)),
      this.bejovoKoltsegService.fetchData().pipe(take(1)),
      this.bejovoSzamlaService.fetchData().pipe(take(1)),
      this.bevetelezesService.fetchData().pipe(take(1)),
      this.dijbekeroService.fetchData().pipe(take(1)),
      this.elolegszamlaService.fetchData().pipe(take(1)),
      this.foglalasService.fetchData().pipe(take(1)),
      this.kulsoraktariService.fetchData().pipe(take(1)),
      this.nyugtaService.fetchData().pipe(take(1)),
      this.szallitoService.fetchData().pipe(take(1)),
      this.dolgozokService.fetchData().pipe(take(1)),
      this.orszagokService.fetchData().pipe(take(1)),
      this.megyekService.fetchData().pipe(take(1)),
      this.penzintezetekService.fetchData().pipe(take(1))
    ];

    const numberOfSimultaneousRequests = 1;

    forkJoin(
      of(...allApiCalls)
     .pipe(
      mergeMap((apiCall) => apiCall, numberOfSimultaneousRequests))
    ).subscribe();
  }

  
}
