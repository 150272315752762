import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { environment } from 'environments/environment';
import {
  of,
  Observable
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnalitikaService {
  private readonly keszletkartonEndpoint = 'keszlet_karton';
  private readonly foglalasListaEndpoint = 'foglalas_lista';
  private readonly vevoAnalitikaEndpoint = 'vevo_analitika';
  private readonly szallitoAnalitikaEndpoint = 'szallito_analitika';
  private readonly minKeszletEndpoint = 'min_keszlet';
  private readonly bizonylatAnalitikaEndpoint = 'bizonylat_analitika';
  private readonly napiPenztarZarasEndpoint = 'napi_penztar_zaras';
  private readonly bizonylatokTeljesitettsegeEnpoint = 'bizonylatok_teljesitettsege';
  private readonly koltsegnemenkentAnalitikaEndpoint = 'koltsegnemenkenti_analitika';
  private readonly forgalomAnalitikaEnpoint = 'forgalom';
  private readonly jutalekEndpoint = 'jutalek';
  private readonly afaAnalitikaEndpoint = 'afa_analitika';
  private readonly arajanlatAnalitikaEndpoint = 'arajanlat_analitika';
  private readonly kimenoKifizetesekEndpoint = 'kimeno_szamla_kifizetesek';

  constructor(
    private api: ApiService,
  ) {}

  generateKeszletkarton(): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.keszletkartonEndpoint}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateFoglalasLista(raktarId: string): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.foglalasListaEndpoint}/${raktarId}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateVevoAnalitika(): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.vevoAnalitikaEndpoint}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateSzallitoAnalitika(startDate: string, endDate: string): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.szallitoAnalitikaEndpoint}/${startDate}/${endDate}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateMinKeszlet(raktarId: string): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.minKeszletEndpoint}/${raktarId}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateBizonylatAnalitika(ev: number): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.bizonylatAnalitikaEndpoint}/${ev}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateNapiPenztarZaras(datum: any, oe: any): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.napiPenztarZarasEndpoint}/${datum}/${oe}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateBizonylatokTeljesitettsege(ev: number): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.bizonylatokTeljesitettsegeEnpoint}/${ev}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateKoltsegnemAnalitika(ev: number): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.koltsegnemenkentAnalitikaEndpoint}/${ev}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateForgalomAnalitika(startDate: string, endDate: string): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.forgalomAnalitikaEnpoint}/${startDate}/${endDate}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateJutalekAnalitika(ev: number): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.jutalekEndpoint}/${ev}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateAfaAnalitika(ev: number, honap: number): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.afaAnalitikaEndpoint}/${ev}/${honap}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateAfaAnalitikaIntervall(startDate: string, endDate: string): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.afaAnalitikaEndpoint}/${startDate}/${endDate}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateArajanlatAnalitika(ev: number): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.arajanlatAnalitikaEndpoint}/${ev}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  generateKimenoSzamlaKifizetesekAnalitika(ev: number, honap: number): Observable<any> {
    return this.api
      .post(`${environment.xlsGeneralas}/${this.kimenoKifizetesekEndpoint}/${ev}/${honap}`, '')
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }
}
