import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  Afakulcs,
  Arkategoria,
  Cikktorzs,
  Column,
  MennyisegiEgyseg,
  PenzugyiBizonylatTetel,
} from '../../../models/torzsek';
import { AfakulcsokService } from '../../../services/afakulcsok.service';
import { ArkategoriakService } from '../../../services/arkategoriak.service';
import { CikktorzsService } from '../../../services/cikktorzs.service';
import { MennyisegiEgysegekService } from '../../../services/mennyisegi-egysegek.service';
import { TetelekColumns } from '../data';
import { DijbekeroElolegKimenoHelperService } from '../../dijbekero-eloleg-kimeno/dijbekero-eloleg-kimeno-helper.service';

@Component({
  selector: 'app-ertekesites-tetelek',
  templateUrl: './ertekesites-tetelek.component.html',
  styleUrls: ['./ertekesites-tetelek.component.scss'],
  providers: [FormGroupDirective],
})
export class ErtekesitesTetelekComponent implements OnInit {
  @Input() arkategoriaSzazalek: number;
  @Input() raktarId: number;
  @Input() selectedPenznem: any;
  @Input() ertekesitesForm: any;
  @Input() arfolyamok: any;
  @Input() kedvezmeny: number;
  formGroup: FormGroup;

  afakulcsok$: Observable<Afakulcs[]>;
  arkategoriak$: Observable<Arkategoria[]>;
  cikkek$: Observable<Cikktorzs[]>;
  mennyisegiEgysegek$: Observable<MennyisegiEgyseg[]>;

  selectedItem: { dataItem: PenzugyiBizonylatTetel; rowIndex: number };
  filter: string = '';

  readonly torzs = 'kimeno-szamla';
  readonly tableHeight = 'calc(100vh - 24.4rem)';
  readonly tetelekColumns: Column[] = TetelekColumns;

  private _updateTrigger = new Subject<void>();

  get formValue(): any {
    return this.formGroup.getRawValue();
  }

  get tetelek(): AbstractControl {
    return this.formGroup.get('tetelek');
  }

  constructor(
    afakulcsokService: AfakulcsokService,
    arkategoriakService: ArkategoriakService,
    meService: MennyisegiEgysegekService,
    private cikktorzsService: CikktorzsService,
    private helperService: DijbekeroElolegKimenoHelperService,
  ) {
    this.afakulcsok$ = afakulcsokService.data$;
    this.arkategoriak$ = arkategoriakService.data$;
    this.mennyisegiEgysegek$ = meService.data$;
  }

  ngOnInit(): void {
    this.formGroup = this.helperService.formGroup;

    this.cikkek$ = this._updateTrigger
      .asObservable()
      .pipe(
        switchMap(() => this.cikktorzsService.getElerhetoKeszlet(this.raktarId))
      );
  }

  onEditRow(e: { dataItem: PenzugyiBizonylatTetel; rowIndex: number }): void {
    this.selectedItem = e;
  }

  onRemove(index: number): void {
    this.tetelek.patchValue(
      this.tetelek.value.filter((e: any, i: number) => index !== i)
    );

    if (index === this.selectedItem?.rowIndex) {
      this.resetForm();
    }
  }

  resetForm(): void {
    this.selectedItem = undefined;
  }

  applyFilter(filter: string): void {
    this.filter = filter;
  }

  updateCikkek(): void {
    this._updateTrigger.next();
  }
}
