import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  TranslateLoader,
  TranslateModule,
  TranslateStore,
} from '@ngx-translate/core';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './main/pages/authentication/auth.module';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { ToastrModule } from 'ngx-toastr';
import { ToastrsModule } from '@core/components/toastr/toastr.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CalendarEventModalModule } from './main/pages/torzsek/components/calendar-event-modal/calendar-event-modal.module';
import { CoreUserSettingsModule } from '@core/components/user-settings/user-settings.module';
import { AnalitikaModule } from './main/pages/torzsek/pages/analitika/analitika.module';
import { TorzsekDetailsModule } from './main/pages/torzsek/components/torzsek-details/torzsek-details.module';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

registerLocaleData(localeHu);

const dbConfig: DBConfig  = {
  name: 'MyDb',
  version: 1,
  objectStoresMeta: [{
    store: 'cikkek',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'partnerek',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'ajanlat',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'dijbekero',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'elolegszamla',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'foglalas',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'kimenoszamla',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'kulsoraktari',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'nyugta',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'szallitolevel',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'bevetelezes',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'bejovoszamla',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'bejovokoltseg',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'dolgozok',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'orszagok',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'megyek',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  },
  {
    store: 'penzintezetek',
    storeConfig: { keyPath: 'id', autoIncrement: false},
    storeSchema: [
    ]
  }]
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    //IndexedDB
    NgxIndexedDBModule.forRoot(dbConfig),

    //NgBootstrap
    NgbModule,

    // Toastr
    ToastrModule.forRoot(),
    ToastrsModule,

    // NgKeyboardShortcut
    KeyboardShortcutsModule.forRoot(),

    // Core modules
    AuthModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    // User settings | Oe / Raktár select
    CoreUserSettingsModule,

    //Analitika
    AnalitikaModule,

    // App modules
    LayoutModule,

    // Global add event
    CalendarEventModalModule,
  ],

  bootstrap: [AppComponent],
  providers: [TranslateStore, { provide: LOCALE_ID, useValue: 'hu-HU' }],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
