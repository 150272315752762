import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Arkategoria, FizetesiMod, Penznem } from '../../../models/torzsek';
import { findByProp } from '../../../utils';

@Component({
  selector: 'app-ertekesites-szamla-adatai',
  templateUrl: './ertekesites-szamla-adatai.component.html',
  styleUrls: ['./ertekesites-szamla-adatai.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ErtekesitesSzamlaAdataiComponent implements OnInit, OnDestroy {
  @Input() arkategoriak: Arkategoria[];
  @Input() fizetesiModok: FizetesiMod[];
  @Input() penznemek: Penznem[];
  @Output() close = new EventEmitter<void>();

  // Ref from parent
  formGroup: FormGroup;
  _penznemek: Penznem[];

  public skontoShow: boolean = false;
  public skonto_nap = 0;
  public skonto = 0;

  minFizetesiHatarido: Date;
  readonly now: Date = new Date();
  readonly minTeljesitesDatuma: Date = this.now;

  get bizonylatDatuma(): AbstractControl {
    return this.formGroup.get('bizonylat_datuma');
  }

  get fizetesiHatarido(): AbstractControl {
    return this.formGroup.get('fizetesi_hatarido');
  }

  get teljesitesDatuma(): AbstractControl {
    return this.formGroup.get('teljesites_datum');
  }

  get fizetesiModControl(): AbstractControl {
    return this.formGroup.get('fizetesi_mod_id');
  }

  get formValue(): AbstractControl {
    return this.formGroup.getRawValue();
  }

  private destroy$: Subject<boolean> = new Subject();

  constructor(private parent: FormGroupDirective) {}

  ngOnInit(): void {
    this.formGroup = this.parent.form;

    this.filterPenznemek(this.formGroup.get('fizetesi_mod_id')?.value);

    this.minFizetesiHatarido = this.minTeljesitesDatuma;

    if (this.bizonylatDatuma) {
      this.bizonylatDatuma.disable();
    }

    this.onFizetesiModChange();
    this.updateMinFizetesiHataridoOnTeljesitesDatumaChange();
    this.updateFizetesiHataridoOnTeljesitesDatumaChange();

    this.formGroup.patchValue({
      skonto: 0,
      skonto_nap: 0
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  closeSidebar(): void {
    this.close.emit();
  }

  private updateMinFizetesiHataridoOnTeljesitesDatumaChange(): void {
    if (this.teljesitesDatuma) {
      this.teljesitesDatuma.valueChanges
        .pipe(
          filter(() => this.teljesitesDatuma.valid),
          takeUntil(this.destroy$)
        )
        .subscribe((teljesitesD: Date) => {
          this.minFizetesiHatarido = teljesitesD;
        });
    }
  }

  private filterPenznemek(id: number): void {
    // KP
    if (id === 8) {
      this._penznemek = this.penznemek.filter((e: Penznem) => e.id === 1);
      this.formGroup.get('penznem_id').patchValue(1);
    }

    // Egyéb
    else {
      this._penznemek = this.penznemek.slice();
    }
  }

  private onFizetesiModChange() {
    if (this.fizetesiModControl) {
      this.fizetesiModControl.valueChanges
        .pipe(
          filter((fId: number) => !!fId),
          map((fId: number) => findByProp(this.fizetesiModok, 'id', fId)),
          takeUntil(this.destroy$)
        )
        .subscribe((fizetesiMod: FizetesiMod) => {
          // Cald Fizetesi hatarido
          this.calcFizetesiHatarido(fizetesiMod.napokszama);

          // Filter penznemek
          this.filterPenznemek(fizetesiMod.id);
        });
    }
  }

  private calcFizetesiHatarido(napokszama: number): void {
    const teljesitesDatuma: Date =
      this.formGroup.get('teljesites_datum')?.value;

    if (teljesitesDatuma) {
      const calced: Date = moment(teljesitesDatuma)
        .add(napokszama, 'days')
        .toDate();

      this.formGroup.patchValue({
        fizetesi_hatarido: calced,
      });
    }
  }

  private updateFizetesiHataridoOnTeljesitesDatumaChange(): void {
    if (this.formGroup.get('teljesites_datum')) {
      this.formGroup
        .get('teljesites_datum')
        .valueChanges.pipe(
          filter(() => this.formGroup.get('teljesites_datum')?.valid),
          takeUntil(this.destroy$)
        )
        .subscribe((date: Date) => {
          const napokszama = findByProp(
            this.fizetesiModok,
            'id',
            this.fizetesiModControl?.value
          );

          this.formGroup.patchValue({
            fizetesi_hatarido: moment(date).add(napokszama, 'days').toDate(),
          });
        });
    }
  }

  onCheckBoxChange() {
    this.skontoShow = !this.skontoShow;
    if(!this.skontoShow) {
      this.formGroup.patchValue({
        skonto: 0,
        skonto_nap: 0
      })
    } else {
      this.formGroup.patchValue({
        skonto: 1,
        skonto_nap: 1
      })
    }
  }
}
