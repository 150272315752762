import {
  Directive,
  OnInit,
  ElementRef,
  Renderer2,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
@Directive({
  selector: '[required], [requiredMask]',
})
export class MarkAsteriskDirective implements OnInit, OnChanges {
  @Input() required: boolean;
  @Input() requiredMask: boolean;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    if (this.required !== undefined || this.requiredMask !== undefined) {
      this.updateAsterix();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('required' in changes && !changes.required.isFirstChange()) {
      this.updateAsterix();
    }

    if ('requiredMask' in changes && !changes.requiredMask.isFirstChange()) {
      this.updateAsterix();
    }
  }

  private updateAsterix() {
    const labelEl = this.el.nativeElement
      .closest('.form-group')
      ?.querySelector('label');

    if (!labelEl) {
      return;
    }

    // Add mask
    if (this.required || this.requiredMask) {
      this.addAsterix(labelEl);
    }
    // Remove mask
    else {
      this.removeAsterix(labelEl);
    }
  }

  private addAsterix(el: HTMLElement) {
    this.renderer.addClass(el, 'required-asterix');
  }

  private removeAsterix(el: HTMLElement): void {
    this.renderer.removeClass(el, 'required-asterix');
  }
}
