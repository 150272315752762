import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
  from,
} from 'rxjs';
import { switchMap, take, tap, catchError, map, bufferCount, concatMap, delay, bufferTime, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Column, FizetesiMod, Partner, Penznem } from '../models/torzsek';
import { addDays, findByProp } from '../utils';
import { FizetesiModokService } from './fizetesi-modok.service';
import { PartnerekService } from './partnerek.service';
import { PenznemekService } from './penznemek.service';
import { ArkategoriakService } from './arkategoriak.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthenticationService } from 'app/auth/service';
import { AfakulcsokService } from './afakulcsok.service';

@Injectable({
  providedIn: 'root',
})
export class KimenoSzamlaService {
  private readonly apiEndpoint = 'kimenoszamla';
  private readonly columnsEndpoint = 'kimenoszamla/columns';
  private readonly listaEndpoint = 'service/helplib/oldal_lista/k';
  private readonly updateEndpoint = 'service/helplib/bizonylat_frissites/k';
  private readonly nyomtatasBeallitasEndpoint = 'service/helplib/nyomtatas_kapcsolo/k';
  private readonly elonezetEndpoint = 'service/nyomtatas/kimenoszamlaelokep';

  private lastFetch: Date = new Date();
  private kimenoszamla = JSON.parse(localStorage.getItem('kimenoszamla'));

  public data: any[] = [];
  private pageNum: number;
 
  public readonly streamEnd = new BehaviorSubject<boolean>(false);

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  // public data$ = combineLatest([
  //   this._data,
  //   this.fizetesiModokService.data$,
  //   this.partnerekService.data$,
  //   this.penznemekService.data$,
  //   this.arkategoriakService.data$
  // ]).pipe(
  //   switchMap(([state, fizetesiModok, partnerek, penznemek, arkategoriak]) => {
  //     return state
  //       ? of(this.mapKimenoSzamlak(state, fizetesiModok, partnerek, penznemek, arkategoriak))
  //       : this.kimenoszamla?.db > 0 ? this.getDBData() : this.fetchData()
  //     }),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  // );

  public data$ = combineLatest([
    this._data,
    this.fizetesiModokService.data$,
    this.partnerekService.data$,
    this.penznemekService.data$,
    this.arkategoriakService.data$
  ]).pipe(
    switchMap(([state, fizetesiModok, partnerek, penznemek, arkategoriak]) =>
      state
        ? of(this.mapKimenoSzamlak(state, fizetesiModok, partnerek, penznemek, arkategoriak))
        : this.fetchData()
    )
  );

  constructor(
    private fizetesiModokService: FizetesiModokService,
    private partnerekService: PartnerekService,
    private penznemekService: PenznemekService,
    private api: ApiService,
    private stateService: StateService,
    private arkategoriakService: ArkategoriakService,
    private dbService: NgxIndexedDBService,
    private afakulcsokService: AfakulcsokService,
    private authService: AuthenticationService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.fizetesiModokService.initData$,
  //             this.partnerekService.initData$,
  //             this.penznemekService.initData$,
  //             this.arkategoriakService.initData$
  //           ]).pipe(
  //             map(([kimenoSzamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) =>
  //               this.mapKimenoSzamlak(
  //                 kimenoSzamlak,
  //                 fizetesiModok,
  //                 partnerek,
  //                 penznemek,
  //                 arkategoriak
  //               )
  //             )
  //           )
  //         : JSON.parse(localStorage.getItem('kimenoszamla'))?.db > 0 ? this.getDBData() : this.fetchData()
  //     ),
  //   withLatestFrom(this.authService.currentUser$),
  //   map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  //   );
  // }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.fizetesiModokService.initData$,
              this.partnerekService.initData$,
              this.penznemekService.initData$,
              this.arkategoriakService.initData$
            ]).pipe(
              map(([kimenoSzamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) =>
                this.mapKimenoSzamlak(
                  kimenoSzamlak,
                  fizetesiModok,
                  partnerek,
                  penznemek,
                  arkategoriak
                )
              )
            )
          : this.fetchData()
      )
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('kimenoszamla', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((storno: any) => {
        this.stateService.setStateOnDelete(storno, this._data);
        //this.dbService.deleteByKey('kimenoszamla', storno.id).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('kimenoszamla', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getDBData(): Observable<any> {
    console.log('get kimenoszamla from indexedDB')
    // return this.dbService.getAll('kimenoszamla').pipe(
    //   tap((data: any[]) => {this._data.next(data.sort((a,b) => b.id - a.id)); console.log('data', data)}),
    // );
    return forkJoin([
      this.dbService.getAll('kimenoszamla').pipe(
           tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.fizetesiModokService.initData$,
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
    ]).pipe(
      map(
        ([kimenoSzamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) => {
          return this.mapKimenoSzamlak(
            kimenoSzamlak,
            fizetesiModok,
            partnerek,
            penznemek,
            arkategoriak
          )
      })
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek && data.tetelek.length > 0 ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.fizetesiModokService.initData$,
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
      this.afakulcsokService.initData$,
    ]).pipe(
      map(([kimenoSzamla, fizetesiModok, partnerek, penznemek, arkategoriak, afakulcsok]) => {

        const retVal = this.mapKimenoSzamlaById(
          kimenoSzamla,
          fizetesiModok,
          partnerek,
          penznemek,
          arkategoriak,
          afakulcsok
        )

        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) => {
          this.stateService.setStateOnUpdate(updated, this._data);
          //this.dbService.update('kimenoszamla', updated).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .postBizonylat(`${environment.pdfGeneralas}/${this.apiEndpoint}`, payload)
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  nyomtatasBeallitas(row: any, megjegyzes_nyomtat: boolean, hivatkozas_nyomtat: boolean = false): Observable<any> {
    return this.api
    .post(`${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`, '')
    .pipe(
      tap((updated: any) => {
        //this.stateService.setStateOnUpdate(updateRow, this._data);
        this.getBizonylatValtozas();
      }),
      catchError(() => of(null))
    );
  }
  
  retryNAV(szamla_id: number): Observable<any> {
    return this.api
      .post(environment.retryNAVApi, { szamla_id, vegszamla: true })
      .pipe(
        tap((navUzenetJSON: any) => {
          const parsed = this.parseNAVUzenet(navUzenetJSON);

          // Ha volt változás akkor state update
          if (parsed) {
            const elById = findByProp(this._data.value, 'id', szamla_id);

            this.stateService.setStateOnUpdate(
              { ...elById, nav_uzenet: parsed },
              this._data
            );
          }
        }),
        catchError((err) => {
          console.log('NAV újraküldés hiba');

          return throwError(err);
        })
      );
  }

  getNAVStatus(szamla_id: number): Observable<any> {
    return this.api
      .post(environment.szamlaStatusApi, { szamla_id, vegszamla: true })
      .pipe(
        catchError((err) => {
          console.log('NAV újraküldés hiba', err);

          return throwError(err);
        })
      );
  }

  fetchAndMapDataByIdKifizetes(id: number | string): Observable<any> {
    return forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.initData$,
      this.fizetesiModokService.initData$,
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
    ]).pipe(
      map(
        ([kimenoSzamla, kimenoSzamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) =>
          this.mapKimenoSzamla(
            kimenoSzamla,
            kimenoSzamlak,
            fizetesiModok,
            partnerek,
            penznemek,
            arkategoriak
          )
      )
    );
  }

  updateKimenoSzamlaRow(id: number, helyesbitoId?: number): Observable<any> {
    if (helyesbitoId) {
      return forkJoin([
        this.fetchDataById(id),
        this.fetchDataById(helyesbitoId),
      ]).pipe(
        tap(([kimenoSzamla, helyesbitoSzamla]) => {
          this.stateService.setStateOnUpdate(kimenoSzamla, this._data);
          this.stateService.setStateOnUpdate(helyesbitoSzamla, this._data);
          this.dbService.update('kimenoszamla', kimenoSzamla).subscribe();
          this.dbService.update('kimenoszamla', helyesbitoSzamla).subscribe();
        })
      );
    }

    return this.fetchDataById(id).pipe(
      tap((kimenoSzamla) => {
        this.stateService.setStateOnUpdate(kimenoSzamla, this._data);
        this.dbService.update('kimenoszamla', kimenoSzamla).subscribe();
      })
    );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem('currentUser')).oe;
    const last = JSON.parse(localStorage.getItem('kimenoszamla'))?.lastFetch;
    return this.api.postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
           .pipe(
            take(1),
            tap(valtozas => {
              if (this._data.value.length == 0) {
                //this.fetchData().subscribe();
              } else {
                if (valtozas.valtozasok.length > 0) {
                  valtozas.valtozasok.forEach((bizonylat) => {
                    const biz = findByProp(this._data.value, "id", bizonylat.id);
                    if (biz) {
                      this.stateService.setStateOnUpdate(bizonylat, this._data);
                    } else {
                      this.stateService.setStateOnAdd(bizonylat, this._data);
                    }
                  });
                }
                if (valtozas.torolt_lista.length > 0) {
                  valtozas.torolt_lista.forEach((bizonylat) => {
                    const biz = findByProp(
                      this._data.value,
                      "id",
                      bizonylat.modul_id
                    );
                    if (biz) {
                      this.stateService.setStateOnDelete(biz, this._data);
                    }
                  });
                }
              }
    
              localStorage.setItem(
                "kimenoszamla",
                JSON.stringify({
                  db: this._data.value.length,
                  lastFetch: this.lastFetch,
                })
              );
            }))
  }

  getCsatoltFajlok(id: any): any {
    return this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err) => {
          console.log('NAV újraküldés hiba');

          return throwError(err);
        })
    )
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(`${this.apiEndpoint}`).pipe(
        // bufferCount(25),
        // concatMap(objs => of(objs)),
        tap((data: any[]) => {
          this._data.next(data);
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.fizetesiModokService.initData$,
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(
      map(([kimenoSzamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) => {
        localStorage.setItem('kimenoszamla', JSON.stringify({db: kimenoSzamlak.length, lastFetch: this.lastFetch}));
        return this.mapKimenoSzamlak(
          kimenoSzamlak,
          fizetesiModok,
          partnerek,
          penznemek,
          arkategoriak
        )
      })
    );
  }

  private fetchDataById(id: number): Observable<any> {
    return this.api.get(`${this.apiEndpoint}/${id}`).pipe(
      catchError((err: any) => {
        console.log(err);
        return of(null);
      })
    );
  }

  private getColumns(): Observable<Column[]> {
    const columns = JSON.parse(localStorage.getItem('kimeno_columns'));

    if(columns) {
      return of(columns);
    } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((data: Column[]) => {
          data = data.map((c: Column) => 
          c.name === 'skonto' ? {...c, default: 0} : c);
  
          data = data.map((c: Column) => 
          c.name === 'skonto_nap' ? {...c, default: 0} : c);
  
          const columns = [
            ...data,
            { name: 'tetelek', nullable: true },
            { name: 'eredeti_szamla_id', nullable: true, hidden: true }
          ];
  
          localStorage.setItem('kimeno_columns', JSON.stringify(columns.map((c: Column) =>
          c.name === 'szamla_kelte' ? { ...c, disabled: true } : c
          )))
          return columns.map((c: Column) =>
            c.name === 'szamla_kelte' ? { ...c, disabled: true } : c
          );
        }),
        catchError((err: any) => {
          console.log(err);
  
          return of([]);
        })
      );
    }

  }

  private mapKimenoSzamlak(
    kimenoSzamlak: any[],
    fizetesiModok: FizetesiMod[],
    partnerek: Partner[],
    penznemek: Penznem[],
    arkategoriak: any[]
  ): any[] {
    return kimenoSzamlak.map((kimenoSzamla: any) => {
      return this.mapKimenoSzamla(
        kimenoSzamla,
        kimenoSzamlak,
        fizetesiModok,
        partnerek,
        penznemek,
        arkategoriak
      );
    });
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: JSON.stringify(data.cim),
      teljesites_datum: new Date(
        new Date(data.teljesites_datum).setHours(12, 0)
      ),
      szamla_kelte: new Date(new Date(data.szamla_kelte).setHours(12, 0)),
      fizetesi_hatarido: new Date(
        new Date(data.fizetesi_hatarido).setHours(12, 0)
      ),
    };
  }

  private parseNAVUzenet(
    navUzenetJSON: any
  ): { tranzakciosId: string; statusz: 'DONE' | 'ABORTED' } | null {
    // Nav üzenet
    if(typeof navUzenetJSON === 'object' && navUzenetJSON !== null) {
      return navUzenetJSON ? { ...navUzenetJSON, statusz: navUzenetJSON?.statusz } : null;
    } else {
      const parsed = navUzenetJSON ? JSON.parse(navUzenetJSON) : null;

      return parsed ? { ...parsed, statusz: parsed?.statusz?.['0'] } : null;
    }
  }

  private calcFizetendo(kimenoSzamlak: any[], id: number): number {
    const kimenoSzamla = findByProp(kimenoSzamlak, 'id', id);
    const isHelyesbitett = kimenoSzamla.helyesbito_id;
    const isHelyesbito = kimenoSzamla.eredeti_szamla_id;

    // if (isHelyesbitett) {
    //   const helyesbitoBrutto = findByProp(
    //     kimenoSzamlak,
    //     'id',
    //     kimenoSzamla.helyesbito_id
    //   )?.brutto_ar;

    //   return kimenoSzamla.brutto_ar + helyesbitoBrutto;
    // }

    // if (isHelyesbito) {
    //   const eredetiBrutto = findByProp(
    //     kimenoSzamlak,
    //     'id',
    //     kimenoSzamla.eredeti_szamla_id
    //   )?.brutto_ar;

    //   return kimenoSzamla.brutto_ar + eredetiBrutto;
    // }

    if(kimenoSzamla.skonto_ok) {
      const skontoValue = kimenoSzamla.brutto_ar * (1 - (kimenoSzamla.skonto / 100))
      return kimenoSzamla?.penznem_id == 1 ? Math.round(skontoValue) : skontoValue;
    }

    return kimenoSzamla.brutto_ar;
  }

  private calcFizetendoById(kimenoSzamla: any): number {
    //const kimenoSzamla = findByProp(kimenoSzamlak, 'id', id);
    const isHelyesbitett = kimenoSzamla.helyesbito_id;
    const isHelyesbito = kimenoSzamla.eredeti_szamla_id;

    // if (isHelyesbitett) {
    //   const helyesbitoBrutto = findByProp(
    //     kimenoSzamlak,
    //     'id',
    //     kimenoSzamla.helyesbito_id
    //   )?.brutto_ar;

    //   return kimenoSzamla.brutto_ar + helyesbitoBrutto;
    // }

    // if (isHelyesbito) {
    //   const eredetiBrutto = findByProp(
    //     kimenoSzamlak,
    //     'id',
    //     kimenoSzamla.eredeti_szamla_id
    //   )?.brutto_ar;

    //   return kimenoSzamla.brutto_ar + eredetiBrutto;
    // }

    if(kimenoSzamla.skonto_ok) {
      const skontoValue = kimenoSzamla.brutto_ar * (1 - (kimenoSzamla.skonto / 100))
      return kimenoSzamla?.penznem_id == 1 ? Math.round(skontoValue) : skontoValue;
    }

    return kimenoSzamla.brutto_ar;
  }

  private calcKifizetettOsszeg(kimenoSzamla: any, id: number): number {
    kimenoSzamla.kiegyenlitesek.forEach(kiegy => {
      
    });
    return 1;
  }

  private mapKimenoSzamla(
    kimenoSzamla: any,
    kimenoSzamlak: any[],
    fizetesiModok: FizetesiMod[],
    partnerek: Partner[],
    penznemek: Penznem[],
    arkategoriak: any[]
  ) {
    const penznemObj: Penznem = findByProp(
      penznemek,
      'id',
      kimenoSzamla.penznem_id
    );

    const penznem = penznemObj?.outfix || penznemObj?.prefix;
    const fizetendo = this.calcFizetendo(kimenoSzamlak, kimenoSzamla.id);
    //const kifizetett = 
    const isFizetve: boolean = Math.abs(fizetendo) <= Math.abs(kimenoSzamla.fizetett);

    return {
      ...kimenoSzamla,
      nav_uzenet: this.parseNAVUzenet(kimenoSzamla.nav_uzenet),
      partner: findByProp(partnerek, 'id', kimenoSzamla.partner_id)?.nev,
      szamla_kelte: new Date(kimenoSzamla.szamla_kelte),
      teljesites_datum: new Date(kimenoSzamla.teljesites_datum),
      fizetesi_hatarido: new Date(kimenoSzamla.fizetesi_hatarido),
      penznem,
      //afakulcs: kimenoSzamla.tetelek[0]?.afakulcs,
      fizetesiMod: findByProp(fizetesiModok, 'id', kimenoSzamla.fizetesi_mod_id)?.nev,
      //tetelek: kimenoSzamla.tetelek.map((t: any) => ({ ...t, penznem, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev })),
      fizetve: isFizetve,
      fizetendo,
    };
  }

  private mapKimenoSzamlaById(
    kimenoSzamla: any,
    fizetesiModok: FizetesiMod[],
    partnerek: Partner[],
    penznemek: Penznem[],
    arkategoriak: any[],
    afakulcsok: any[]
  ) {
    const penznemObj: Penznem = findByProp(
      penznemek,
      'id',
      kimenoSzamla.penznem_id
    );

    const penznem = penznemObj?.outfix || penznemObj?.prefix;
    const fizetendo = this.calcFizetendoById(kimenoSzamla);
    //const kifizetett = 
    const isFizetve: boolean = Math.abs(fizetendo) <= Math.abs(kimenoSzamla.fizetett);

    return {
      ...kimenoSzamla,
      nav_uzenet: this.parseNAVUzenet(kimenoSzamla.nav_uzenet),
      partner: findByProp(partnerek, 'id', kimenoSzamla.partner_id)?.nev,
      szamla_kelte: new Date(kimenoSzamla.szamla_kelte),
      teljesites_datum: new Date(kimenoSzamla.teljesites_datum),
      fizetesi_hatarido: new Date(kimenoSzamla.fizetesi_hatarido),
      penznem,
      afakulcs: kimenoSzamla.tetelek[0]?.afakulcs,
      fizetesiMod: findByProp(fizetesiModok, 'id', kimenoSzamla.fizetesi_mod_id)?.nev,
      tetelek: kimenoSzamla.tetelek.map((t: any) => ({ ...t, penznem, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, afakulcs_nev: findByProp(afakulcsok, 'id', t.afakulcs_id)?.nev })),
      fizetve: isFizetve,
      fizetendo,
    };
  }

  private checkSkontonBeluli(kimenoSzamla: any): boolean {
    return new Date(new Date().setHours(23, 59, 59, 999)) <= addDays(kimenoSzamla.szamla_kelte, kimenoSzamla.skonto_nap);
  }

  getMennyiseg(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.api.postBizonylat(`service/helplib/ossz_bizonylat/k/${currentUser.id}`, "");
  }

  clearData(): any {
    this.data = [];
  }

  generateElonezetPdf(payload): Observable<any> {
    return this.api
      .postBizonylat(`${this.elonezetEndpoint}`, this.toJSON(payload))
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }
}
