import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map, tap } from 'rxjs/operators';
import { KIRCeg } from '../models/torzsek';

@Injectable({ providedIn: 'root' })
export class KIRService {
  constructor(private httpClient: HttpClient) {}

  auth(): Observable<{ status: string; message: string; token: string }> {
    const payload = {
      felhasznalonev: 'TECH',
      jelszo: 'Fortnite2023',
    };

    return this.httpClient
      .post<{
        status: string;
        message: string;
        token: string;
      }>(environment.KIRAuth, payload)
      .pipe(tap(({ token }) => localStorage.setItem('kirToken', token)));
  }

  cegkereso(adoszam: string): Observable<KIRCeg> {
    return this.httpClient
      .get<KIRCeg>(`${environment.KIRCegkereso}?keresett=${adoszam}`)
      .pipe(map((res: any) => res?.cegadatok?.[0]));
  }
}
