export const TetelekColumns = [
  {
    name: 'kimenoszamla_id',
    nullable: true,
    hidden: true,
  },
  {
    name: 'cikk_id',
    type: 'select',
    nullable: false,
    hidden: true,
  },
  {
    name: 'cikknev',
    type: 'text',
    nullable: false,
    width: '236px',
  },
  {
    name: 'arkategoria',
    title: 'Árkategoria',
    type: 'text',
    nullable: false,
    width: '236px',
  },
  {
    name: 'mennyiseg',
    type: 'number',
    nullable: false,
    suffixProp: 'mennyisegi_egyseg_text',
    width: '211px',
  },
  {
    name: 'mennyisegi_egyseg',
    type: 'select',
    nullable: false,
    hidden: true,
  },
  {
    name: 'afakulcs',
    type: 'number',
    nullable: false,
    suffix: '%',
    width: '122px',
  },
  {
    name: 'kedvezmeny',
    type: 'number',
    nullable: false,
    suffix: '%',
    width: '122px',
  },
  {
    name: 'netto_egysegar',
    type: 'currency',
    nullable: false,
    suffix: 'Ft',
    width: '164px',
  },
  {
    name: 'brutto_egysegar',
    type: 'currency',
    nullable: false,
    suffix: 'Ft',
    width: '164px',
  },

  {
    name: 'netto_ar',
    type: 'currency',
    nullable: false,
    suffix: 'Ft',
    width: '164px',
  },
  {
    name: 'afa_ar',
    type: 'currency',
    nullable: false,
    suffix: 'Ft',
    width: '164px',
  },
  {
    name: 'brutto_ar',
    type: 'currency',
    nullable: false,
    suffix: 'Ft',
    width: '164px',
  },
];
