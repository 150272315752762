<div class="form-group">
    <label for="partner">Partner</label>
    <div id="partner-input" class="input-group" [class.hidden-button]="formGroup.get('partner_id')?.disabled">
        <button *ngIf="formGroup.get('partner_id').enabled" type="button" [disabled]="formGroup.get('partner_id').disabled" class="btn btn-icon btn-primary" (click)="openNewPartnerModal()">
      <span data-feather="plus" class="cursor-pointer"></span>
    </button>
        <ng-select id="partner" class="w-100" [items]="partnerek" [virtualScroll]="true" [searchFn]="customSearchFn" bindLabel="nev" bindValue="id" formControlName="partner_id" [clearable]="true" [required]="'partner_id' | requiredControl: formGroup">
            <ng-template ng-option-tmp let-item="item">
                <span [title]="item.nev">{{ item.nev }} <br />
          <small>{{ item?.adoszam || (item?.ugyfel_tipus == 'MS' ? (item?.cim | partnerekErtekesitesTransform) : '-')}}</small><br>
          <small>{{ item?.ugyfel_tipus != 'MS' ? (item?.cim | partnerekErtekesitesTransform) : ''}}</small>
          </span
        >
      </ng-template>
    </ng-select>
  </div>
</div>

<app-torzsek-cim-form></app-torzsek-cim-form>