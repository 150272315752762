import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
  ReplaySubject,
} from 'rxjs';
import {
  switchMap,
  take,
  tap,
  catchError,
  map,
  shareReplay,
  withLatestFrom,
  mergeMap,
} from 'rxjs/operators';
import { Column, FoglalasTetel } from '../models/torzsek';
import { findByProp } from '../utils';
import { PartnerekService } from './partnerek.service';
import { ArkategoriakService } from './arkategoriak.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AfakulcsokService } from './afakulcsok.service';

@Injectable({
  providedIn: 'root',
})
export class FoglalasService {
  private readonly apiEndpoint = 'foglalas';
  private readonly columnsEndpoint = 'foglalas/columns';
  private readonly foglalasokApiEndpoint = 'service/rendeles/foglalasok';
  private readonly listaEndpoint = 'service/helplib/oldal_lista/f';
  private readonly foglalasTeljesitesEndpoint = 'service/bizonylatlib/foglalas_teljesitve';
  private readonly updateEndpoint = 'service/helplib/bizonylat_frissites/f';
  private readonly nyomtatasBeallitasEndpoint = 'service/helplib/nyomtatas_kapcsolo/f';

  private lastFetch: Date = new Date();
  private foglalas = JSON.parse(localStorage.getItem('foglalas'));

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);
  private readonly _selectedCikkId = new ReplaySubject<number>(1);
  public data: any[] = [];
  private pageNum: number;
  public readonly streamEnd = new BehaviorSubject<boolean>(false);

  // private state$ = combineLatest([
  //   this._data,
  //   this.partnerekService.data$,
  //   this.arkategoriakService.data$
  // ]).pipe(
  //   switchMap(([state, partnerek, arkategoriak]) => {
  //     return state ? of(this.mapFoglalas(state, partnerek, arkategoriak)) : this.foglalas?.db > 0 ? this.getDBData() : this.fetchData()
  //   })
  // );

  private state$ = combineLatest([
    this._data,
    this.partnerekService.data$,
    this.arkategoriakService.data$
  ]).pipe(
    switchMap(([state, partnerek, arkategoriak]) =>
      state ? of(this.mapFoglalas(state, partnerek, arkategoriak)) : this.fetchData()
    )
  );

  public data$ = combineLatest([
    this.state$,
    this.authService.currentUser$,
  ]).pipe(map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe)));

  public cikkFoglalasok$: Observable<FoglalasTetel[]> =
    this._selectedCikkId.pipe(
      switchMap((id: number) => (id ? this.fetchFoglalasByCikkId(id) : of([]))),
      shareReplay(1)
    );

  constructor(
    private authService: AuthenticationService,
    private partnerekService: PartnerekService,
    private api: ApiService,
    private stateService: StateService,
    private arkategoriakService: ArkategoriakService,
    private dbService: NgxIndexedDBService,
    private afakulcsokService: AfakulcsokService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([of(state), this.partnerekService.initData$, this.arkategoriakService.initData$]).pipe(
  //             map(([foglalasok, partnerek, arkategoriak]) =>
  //               this.mapFoglalas(foglalasok, partnerek, arkategoriak)
  //             )
  //           )
  //         : JSON.parse(localStorage.getItem('foglalas'))?.db > 0 ? this.getDBData() : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  //   );
  // }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([of(state), this.partnerekService.initData$, this.arkategoriakService.initData$]).pipe(
              map(([foglalasok, partnerek, arkategoriak]) =>
                this.mapFoglalas(foglalasok, partnerek, arkategoriak)
              )
            )
          : this.fetchData()
      ),
      withLatestFrom(this.authService.currentUser$),
      map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('foglalas', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    const el = findByProp(this._data.value, 'id', id);

    // Visszavonás
    if (el.statusz === 1) {
      return this.api.delete(this.apiEndpoint, id, 'visszavonás').pipe(
        tap((storno: any) => {
          this.stateService.setStateOnUpdate(storno, this._data);
          //this.dbService.update('foglalas', storno).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
    }

    // Végleges törlés
    else {
      return this.api.delete(this.apiEndpoint, id, 'törlés').pipe(
        tap((storno: any) => {
          this.stateService.setStateOnDelete(storno, this._data);
          //this.dbService.deleteByKey('foglalas', storno.id).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
    }
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('foglalas', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) => {
          this.stateService.setStateOnUpdate(updated, this._data);
          //this.dbService.update('foglalas', updated).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
  }

  getDBData(): Observable<any> {
    console.log('get foglalas from indexedDB')
    // return this.dbService.getAll('foglalas').pipe(
    //   tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
    // );
    return forkJoin([
      this.dbService.getAll('foglalas').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.partnerekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(
      map(([foglalasok, partnerek, arkategoriak]) => {
        return this.mapFoglalas(foglalasok, partnerek, arkategoriak)
      })
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of({
          ...data,
          ervenyessegi_datum: new Date(data.ervenyessegi_datum),
          bizonylat_datuma: new Date(data.bizonylat_datuma),
        });
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.partnerekService.initData$,
      this.arkategoriakService.initData$,
      this.afakulcsokService.initData$
    ]).pipe(
      map(([foglalas, partnerek, arkategoriak, afakulcsok]) => {
        const retVal = this.mapFoglalasByID(foglalas, partnerek, arkategoriak, afakulcsok)
        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  selectFoglalasCikk(id: number): void {
    this._selectedCikkId.next(id);
  }

  clearFoglalasCikk(): void {
    this._selectedCikkId.next(null);
  }

  private fetchFoglalasByCikkId(id: number): Observable<FoglalasTetel[]> {
    return this.api.get(`${this.foglalasokApiEndpoint}/${id}`).pipe(
      catchError((err: any) => {
        console.log('Cikk foglalás nem található', err);
        return of([]);
      })
    );
  }

  foglalasTeljesites(foglalas_id: any, statusz: any): Observable<any> {
    return this.api.get(`${this.foglalasTeljesitesEndpoint}/${foglalas_id}/${statusz}`).pipe(
      catchError((err: any) => {
        return of([]);
      })
    );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem('currentUser')).oe;
    const last = JSON.parse(localStorage.getItem('foglalas'))?.lastFetch;
    return this.api.postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
           .pipe(
            take(1),
            tap(valtozas => {
              if (this._data.value.length == 0) {
                //this.fetchData().subscribe();
              } else {
                if (valtozas.valtozasok.length > 0) {
                  valtozas.valtozasok.forEach((bizonylat) => {
                    const biz = findByProp(this._data.value, "id", bizonylat.id);
                    if (biz) {
                      this.stateService.setStateOnUpdate(bizonylat, this._data);
                    } else {
                      this.stateService.setStateOnAdd(bizonylat, this._data);
                    }
                  });
                }
                if (valtozas.torolt_lista.length > 0) {
                  valtozas.torolt_lista.forEach((bizonylat) => {
                    const biz = findByProp(
                      this._data.value,
                      "id",
                      bizonylat.modul_id
                    );
                    if (biz) {
                      this.stateService.setStateOnDelete(biz, this._data);
                    }
                  });
                }
              }
    
              localStorage.setItem(
                "foglalas",
                JSON.stringify({
                  db: this._data.value.length,
                  lastFetch: this.lastFetch,
                })
              );
            }))
  }

  // private fetchData(pageNum: number): Observable<any[]> {
  //   this.lastFetch = new Date();
  //   return forkJoin([
  //     this.api.get(`${this.apiEndpoint}`).pipe(
  //       //       // bufferCount(25),
  //       //       // concatMap(objs => of(objs)),
  //       //       tap((data: any[]) => {
  //       //         this._data.next(data);
  //       //       }),
  //       //       catchError((err: any) => {
  //       //         console.log(err);
  //       //         return of([]);
  //       //       })
  //       //     ),
  //       catchError((err: any) => {
  //         console.log(err);
  //         return of([]);
  //       })
  //     ),
  //     this.partnerekService.initData$,
  //     this.arkategoriakService.initData$
  //   ]).pipe(
  //     map(([foglalasok, partnerek, arkategoriak]) => { 
  //       localStorage.setItem('foglalas', JSON.stringify({db: foglalasok.length, lastFetch: this.lastFetch}));
  //       return this.mapFoglalas(foglalasok, partnerek, arkategoriak)
  //     })
  //   );
  // }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(`${this.apiEndpoint}`).pipe(
        tap((data: any[]) => {
          this._data.next(data);
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.partnerekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(
      map(([foglalasok, partnerek, arkategoriak]) => {
        localStorage.setItem('foglalas', JSON.stringify({db: foglalasok.length, lastFetch: this.lastFetch}));
        return this.mapFoglalas(foglalasok, partnerek, arkategoriak)
      })
    );
  }

  private getColumns(): Observable<Column[]> {
    const columns = JSON.parse(localStorage.getItem('foglalas_columns'));

    if(columns) {
      return of(columns);
    } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((columns: Column[]) => {
          const mappedColumns = [
            ...columns,
            { name: 'tetelek', nullable: false, hidden: true },
            { name: 'megjegyzes_nyomtat', nullable: false, hidden: true, default: 1 },
          ]
  
          localStorage.setItem('foglalas_columns', JSON.stringify(mappedColumns));
          return mappedColumns;
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      );
    }
  }

  private mapFoglalas(foglalasok: any[], partnerek: any[], arkategoriak: any[]): any[] {
    return foglalasok.map((e: any) => ({
      ...e,
      partner: findByProp(partnerek, 'id', e.partner_id)?.nev,
      bizonylat_datuma: new Date(e.bizonylat_datuma),
      ervenyessegi_datum: new Date(e.ervenyessegi_datum),
      //tetelek: e.tetelek.map((t: any) => ({ ...t, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, })),
      helyesbitheto: this.isHelyesbitheto(e)
    }));
  }

  private mapFoglalasByID(foglalas: any, partnerek: any[], arkategoriak: any[], afakulcsok: any[]): any[] {
    return {
      ...foglalas,
      partner: findByProp(partnerek, 'id', foglalas.partner_id)?.nev,
      bizonylat_datuma: new Date(foglalas.bizonylat_datuma),
      ervenyessegi_datum: new Date(foglalas.ervenyessegi_datum),
      tetelek: foglalas.tetelek.map((t: any) => ({ ...t, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, afakulcs_nev: findByProp(afakulcsok, 'id', t.afakulcs_id)?.nev })),
      helyesbitheto: this.isHelyesbitheto(foglalas)
    };
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: JSON.stringify(data.cim),
    };
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .postBizonylat(`${environment.pdfGeneralas}/${this.apiEndpoint}`, payload)
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  getMennyiseg(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.api.postBizonylat(`service/helplib/ossz_bizonylat/f/${currentUser.id}`, "");
  }

  clearData(): any {
    this.data = [];
  }

  nyomtatasBeallitas(row: any, megjegyzes_nyomtat: number, hivatkozas_nyomtat: number = 0): Observable<any> {
    return this.api
    .post(`${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`, '')
    .pipe(
      tap((updated: any) => {
        const updateRow = {
          ...row,
          megjegyzes_nyomtat: megjegyzes_nyomtat,
        }
        this.stateService.setStateOnUpdate(updateRow, this._data);
      }),
      catchError(() => of(null))
    );
  }

  isHelyesbitheto(foglalas: any): any {
    return foglalas.statusz == 1;
  }
}
