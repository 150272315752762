import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Arkategoria,
  Bankszamlaszam,
  FizetesiMod,
  KIRCeg,
  Penznem,
} from 'app/main/pages/torzsek/models/torzsek';
import { oneRequiredValidator } from 'app/main/pages/torzsek/pages/partnerek/adoszam-vagy-csoportos-adoszam.validator';
import {
  UgyfelTipusId,
  ugyfelTipusok,
} from 'app/main/pages/torzsek/pages/partnerek/partnerek-alapadatok/ugyfel-tipusok';
import { ArkategoriakService } from 'app/main/pages/torzsek/services/arkategoriak.service';
import { BankszamlaszamokService } from 'app/main/pages/torzsek/services/bankszamlaszamok.service';
import { FizetesiModokService } from 'app/main/pages/torzsek/services/fizetesi-modok.service';
import { PartnerekService } from 'app/main/pages/torzsek/services/partnerek.service';
import { PenznemekService } from 'app/main/pages/torzsek/services/penznemek.service';
import { KIRService } from 'app/main/pages/torzsek/services/_kir.service';
import { Observable, Subject } from 'rxjs';
import { concatMap, finalize, startWith, takeUntil } from 'rxjs/operators';
import { PartnerekFormService } from '../../../pages/torzsek/pages/partnerek/partnerek-form.service';

@Component({
  selector: 'app-partnerek-modal',
  templateUrl: './partnerek-modal.component.html',
  styles: [],
})
export class PartnerekModalComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  loading: boolean = false;

  arkategoriak$: Observable<Arkategoria[]>;
  bankszamlaszamok$: Observable<Bankszamlaszam[]>;
  fizetesiModok$: Observable<FizetesiMod[]>;
  penznemek$: Observable<Penznem[]>;

  readonly ugyfelTipusok = ugyfelTipusok;
  private destroy$ = new Subject();

  constructor(
    arkategoriakService: ArkategoriakService,
    bankszamlaszamokService: BankszamlaszamokService,
    fizetesiModokService: FizetesiModokService,
    penznemekService: PenznemekService,
    public modal: NgbActiveModal,
    private KIRService: KIRService,
    private partnerekFormService: PartnerekFormService,
    private service: PartnerekService
  ) {
    this.arkategoriak$ = arkategoriakService.data$;
    this.bankszamlaszamok$ = bankszamlaszamokService.data$;
    this.fizetesiModok$ = fizetesiModokService.data$;
    this.penznemek$ = penznemekService.data$;
  }

  get formValue(): any {
    const formVal = this.formGroup.getRawValue();

    return {
      ...formVal,
      ...formVal.alapAdatok,
      ...formVal.gazdasagiAdatok,
    };
  }

  get alapAdatok(): FormGroup {
    return this.formGroup.get('alapAdatok') as FormGroup;
  }

  get adoszamControl(): AbstractControl {
    return this.alapAdatok.get('adoszam');
  }

  get csoportosAdoszamControl(): AbstractControl {
    return this.alapAdatok.get('csoportos_adoszam');
  }

  get euAdoszamControl(): AbstractControl {
    return this.alapAdatok.get('eu_adoszam');
  }

  get cimGroup(): FormGroup {
    return this.formGroup.get('cim') as FormGroup;
  }

  ngOnInit(): void {
    this.formGroup = this.partnerekFormService.createModalForm();

    this.alapAdatok
      .get('ugyfel_tipus')
      .valueChanges.pipe(
        startWith(this.alapAdatok.get('ugyfel_tipus')?.value),
        takeUntil(this.destroy$)
      )
      .subscribe((id: UgyfelTipusId) => {
        // Not required
        if (id === 'MS') {
          this.clearOneRequiredValidator();

          // Init cimtipus
          this.cimGroup.patchValue({
            cimtipus: 'Számlázási cím',
          });
        }

        // Eu adószám | Külföldi partner
        else if (id === 'KJS') {
          this.clearAdoszamValidators();
          this.clearOneRequiredValidator();
          this.addEuAdoszamValidator();

          // Init cimtipus
          this.cimGroup.patchValue({
            cimtipus: 'Székhely',
          });
        }

        // Required
        else {
          this.addOneRequiredValidator();

          // Init cimtipus
          this.cimGroup.patchValue({
            cimtipus: 'Székhely',
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  savePartner(): void {
    this.loading = true;

    this.service
      .add({ ...this.formValue, cim: [this.formValue.cim] })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (partner: any) => {
          this.modal.close(partner);
        },
        (err) => {
          console.log('Hiba a mentés közben (in modal)', err);
        }
      );
  }

  getCegInfo(): void {
    if (
      !this.adoszamControl?.value ||
      this.adoszamControl?.value?.length < 11
    ) {
      return;
    }

    this.KIRService.auth()
      .pipe(
        concatMap(() => this.KIRService.cegkereso(this.adoszamControl?.value))
      )
      .subscribe((cegadatok: KIRCeg) => {
        if (cegadatok) {
          this.formGroup.patchValue({
            alapAdatok: {
              nev: cegadatok.cegnev,
              email: cegadatok.email,
            },
            cim: {
              kulfoldi: false,
              cimtipus: 'Székhely',
              iranyitoszam: cegadatok.iranyitoszam,
              telepules: cegadatok.varos,
              kozterulet_neve: cegadatok.utca,
              kozterulet_tipusa: cegadatok.utca_tipus,
              hazszam: cegadatok.hazszam,
              epulet: null,
              emelet: cegadatok.emelet || null,
              ajto: cegadatok.ajto || null,
            },
          });
        }
      });
  }

  private addOneRequiredValidator(): void {
    this.alapAdatok.addValidators(
      oneRequiredValidator(this.adoszamControl, this.csoportosAdoszamControl)
    );
    this.alapAdatok.updateValueAndValidity();
  }

  private clearOneRequiredValidator(): void {
    this.alapAdatok.clearValidators();
    this.alapAdatok.updateValueAndValidity();

    this.clearEuAdoszamValidator();
  }

  private clearAdoszamValidators(): void {
    // Remove validator from adószám
    this.adoszamControl.clearValidators();
    this.adoszamControl.updateValueAndValidity();

    // Remove validator from csoportos adószám
    this.csoportosAdoszamControl.clearValidators();
    this.csoportosAdoszamControl.updateValueAndValidity();
  }

  private addEuAdoszamValidator(): void {
    // Add eu adószám validator
    this.euAdoszamControl.addValidators(Validators.required);
    this.euAdoszamControl.updateValueAndValidity();
  }

  private clearEuAdoszamValidator(): void {
    // Add eu adószám validator
    this.euAdoszamControl.clearValidators();
    this.euAdoszamControl.updateValueAndValidity();
  }

}
