import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const oneRequiredValidator =
  (control1: AbstractControl, control2: AbstractControl): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const control1Value: any = control1.value;
    const control2Value: any = control2.value;

    return control1Value || control2Value ? null : { oneRequired: true };
  };

  export const oneRequiredSzallitoVevoValidator =
  (control1: AbstractControl, control2: AbstractControl): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const control1Value: any = control1.value;
    const control2Value: any = control2.value;

    return control1Value || control2Value ? null : { oneSzallitoVevoRequired: true };
  };
