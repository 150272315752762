export const locale = {
  lang: 'hu',
  data: {
    MENU: {
      kezdolap: 'Kezdőlap',
      afakulcsok: 'Áfakulcsok',
      arkategoriak: 'Árkategóriák',
      arfolyamok: 'Árfolyamok',
      bizonylattipusok: 'Bizonylat típusok',
      cegadatok: 'Cégadatok',
      cegcsoportok: 'Cégcsoportok',
      cikkcsoportok: 'Cikkcsoportok',
      dolgozok: 'Dolgozók',
      felhasznaloicsoportok: 'Felhasználói csoportok',
      feor: 'FEOR',
      fizetesimodok: 'Fizetési módok',
      fokonyviszamok: 'Főkönyvi számok',
      feladatok: 'Feladatok',
      megyek: 'Megyék',
      mennyisegiegysegek: 'Mennyiségi egységek',
      munkanapok: 'Munkanapok',
      nyomtatvanyok: 'Nyomtatványok',
      nyelvek: 'Nyelvek',
      onelszamoloegysegek: 'Önelszámoló egységek',
      orszagok: 'Országok',
      penzintezetek: 'Pénzintézetek',
      partnerek: 'Partnerek',
      penznemek: 'Pénznemek',
      raktarak: 'Raktárak',
      szervezetiegysegek: 'Szervezeti egységek',
      telepulesek: 'Települések',
      szabadsagtipusok: 'Szabadság típusok',
      szallitokereso: 'Szállító kereső'
    },
  },
};
