import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';
import { ApiService } from '@core/services/api.service';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreConfigService } from '@core/services/config.service';
import { PartnerekService } from 'app/main/pages/torzsek/services/partnerek.service';
import { CikktorzsService } from 'app/main/pages/torzsek/services/cikktorzs.service';
import { KimenoSzamlaService } from 'app/main/pages/torzsek/services/kimeno-szamla.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser$: Observable<User>;

  //private
  private _currentUser: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   */
  constructor(
      private api: ApiService,
      private configServce: CoreConfigService,
      ) {
    this._currentUser = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser$ = this._currentUser.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this._currentUser.value;
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') || null;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      // this.currentUser && this.currentUserSubject.value.role === Role.Admin
      true
    );
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return (
      // this.currentUser && this.currentUserSubject.value.role === Role.Client
      false
    );
  }

  /**
   *  Updates current user state
   *  Updates current user in localStorage
   */
  set currentUser(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user));

    this._currentUser.next(user);
  }

  /**
   * User login
   *
   * @param felhasznalonev
   * @param jelszo
   * @returns user
   */
  login(felhasznalonev: string, jelszo: string) {
    return this.api
      .post(environment.auth, {
        felhasznalonev,
        jelszo,
      })
      .pipe(
        // tap((response) => console.log('Login response:', response)),
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            const parsed = {
              ...user,
              oek: JSON.parse(user.oek),
              raktarak: JSON.parse(user.raktarak),
              jogosultsagok: JSON.parse(user?.jogosultsagok),
              config: JSON.parse(user?.beallitasok)
            };

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(parsed));
            localStorage.setItem('accessToken', user.token);
            localStorage.setItem('rows', JSON.stringify(15));

            // notify
            this.currentUser = parsed;

            //set config 
            // if(parsed.config) {
            //   this.configServce.setConfig(parsed.config);
            // }
          }

          return user;
        }),
        catchError((err) => {
          console.log('Login error:', err);

          return throwError(err);
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('accessToken');
    // notify
    this._currentUser.next(null);
  }
}
