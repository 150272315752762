import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import {
  OnelszamoloEgyseg,
  Raktar,
} from 'app/main/pages/torzsek/models/torzsek';
import { OnelszamoloEgysegekService } from 'app/main/pages/torzsek/services/onelszamolo-egysegek.service';
import { RaktarakService } from 'app/main/pages/torzsek/services/raktarak.service';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CoreSidebarService } from '../core-sidebar/core-sidebar.service';

@Component({
  selector: 'core-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  oek$: Observable<OnelszamoloEgyseg[]>;
  raktarak$: Observable<Raktar[]>;
  user$: Observable<User>;

  private destroy$ = new Subject();

  /**
   * Constructor

   * @param {CoreSidebarService} _coreSidebarService
   * */
  constructor(
    private authService: AuthenticationService,
    private oekService: OnelszamoloEgysegekService,
    private raktarakService: RaktarakService,
    private _coreSidebarService: CoreSidebarService
  ) {}

  ngOnInit(): void {
    this.user$ = this.authService.currentUser$;

    if(this.authService.currentUserValue) {
      this.oek$ = this.oekService.data$;
      this.raktarak$ = this.user$.pipe(
        switchMap((user: User) =>
          this.raktarakService.data$.pipe(
            map((raktarak: Raktar[]) =>
              raktarak.filter((r: Raktar) => r.oe_id === user?.oe)
            )
          )
        )
      );
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  updateOnOeSelect(oe: OnelszamoloEgyseg): void {
    this.authService.currentUser = {
      ...this.authService.currentUserValue,
      oe: oe.id,
      raktar: oe.raktarak[0].id,
      oeNev: oe.megnevezes,
    };
    window.location.reload();
  }
}
