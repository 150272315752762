import { Component } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

import { toastrSlideY } from '@core/components/toastr/toastr.animation';

@Component({
  selector: '[app-toastr-component]',
  templateUrl: './toastr.component.html',
  animations: [toastrSlideY],
  preserveWhitespaces: false,
})
export class ToastrComponent extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }
}
