import { Pipe, PipeTransform } from '@angular/core';
import { PenzugyiBizonylatTetel } from 'app/main/pages/torzsek/models/torzsek';

@Pipe({
  name: 'isKiszolgalt',
})
export class IsKiszolgaltPipe implements PipeTransform {
  transform(bizonylat: any, inList: number = 0, allSelected: boolean = false): boolean {
    // FIXME: Backend fix needed
    if(allSelected) {
      return true;
    }

    if (!bizonylat?.tetelek) {
      return false;
    }

    const inBizonylat = bizonylat.tetelek.reduce(
      (sum: number, t: PenzugyiBizonylatTetel) =>
        sum + (t?.mennyiseg - (t?.kiszamlazva || 0)),
      0
    );

    return inList === inBizonylat;
  }
}
