import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { switchMap, take, tap, catchError, map } from 'rxjs/operators';
import {
  Afakulcs,
  Arkategoria,
  Cikkcsoport,
  Cikktorzs,
  Column,
  RaktarkeszletByRaktar,
} from '../models/torzsek';
import { calcBrutto, findByProp } from '../utils';
import { AfakulcsokService } from './afakulcsok.service';
import { ArkategoriakService } from './arkategoriak.service';
import { CikkcsoportokService } from './cikkcsoportok.service';
import { RaktarkeszletService } from './raktarkeszlet.service';
import { environment } from 'environments/environment';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class CikktorzsService {
  private readonly apiEndpoint = 'cikktorzs';
  private readonly columnsEndpoint = 'cikktorzs/columns';
  private readonly cikkCsoportEndpoint = 'service/helplib/cikk_csoport_tomeges';
  private readonly cikkUpdateEndpoint = 'service/helplib/cikk_frissites';
  private readonly visszaallitasEndpoint = 'service/torzslib/vissza_allitas';
  private readonly listaEndpoint = 'service/helplib/oldal_lista/ci';
  private lastFetch: Date = new Date();
  private cikkek = JSON.parse(localStorage.getItem('cikkek'));

  private readonly _data = new BehaviorSubject<Cikktorzs[]>(null);
  private readonly _selectedData = new BehaviorSubject<Cikktorzs>(null);

  public data$ = combineLatest([
    this._data,
    this.afakulcsokService.data$,
    this.arkategoriakService.data$,
    this.cikkcsoportokService.data$,
  ]).pipe(
    switchMap(([state, afakulcsok, arkategoriak, cikkcsoportok]) => {
      return state
        ? of(this.mapCikkek(state, afakulcsok, arkategoriak, cikkcsoportok))
        : this.cikkek?.db > 0 ? this.getDBData() : this.fetchData()
    })
  );

  // public data$ = combineLatest([
  //   this._data,
  //   this.afakulcsokService.data$,
  //   this.arkategoriakService.data$,
  //   this.cikkcsoportokService.data$,
  // ]).pipe(
  //   switchMap(([state, afakulcsok, arkategoriak, cikkcsoportok]) =>
  //     state
  //       ? of(this.mapCikkek(state, afakulcsok, arkategoriak, cikkcsoportok))
  //       : this.fetchData()
  //   )
  // );

  constructor(
    private api: ApiService,
    private stateService: StateService,
    private afakulcsokService: AfakulcsokService,
    private arkategoriakService: ArkategoriakService,
    private cikkcsoportokService: CikkcsoportokService,
    private raktarkeszletService: RaktarkeszletService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.afakulcsokService.initData$,
              this.arkategoriakService.initData$,
              this.cikkcsoportokService.initData$,
            ]).pipe(
              map(([cikkek, afakulcsok, arkategoriak, cikkcsoportok]) => {
                return this.mapCikkek(cikkek, afakulcsok, arkategoriak, cikkcsoportok).sort((a: any,b: any) => a.cikknev - b.cikknev)
              }
              )
            )
          : JSON.parse(localStorage.getItem('cikkek'))?.db > 0 ? this.getDBData() :this.fetchData()
      )
    );
  }

  get initDataAktiv$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.afakulcsokService.initData$,
              this.arkategoriakService.initData$,
              this.cikkcsoportokService.initData$,
            ]).pipe(
              map(([cikkek, afakulcsok, arkategoriak, cikkcsoportok]) => {
                return this.mapCikkek(cikkek, afakulcsok, arkategoriak, cikkcsoportok).sort((a: any,b: any) => a.cikknev - b.cikknev)
              }
              )
            )
          : JSON.parse(localStorage.getItem('cikkek'))?.db > 0 ? this.getDBData() :this.fetchData()
      ),
      map(data => data.filter(cikk => cikk.statusz != -1))
    );
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.afakulcsokService.initData$,
  //             this.arkategoriakService.initData$,
  //             this.cikkcsoportokService.initData$,
  //           ]).pipe(
  //             map(([cikkek, afakulcsok, arkategoriak, cikkcsoportok]) => {
  //               return this.mapCikkek(cikkek, afakulcsok, arkategoriak, cikkcsoportok)
  //             }
  //             )
  //           )
  //         : this.fetchData()//this.fetchData()
  //     )
  //   );
  // }

  add(data: Cikktorzs): Observable<Cikktorzs> {
    return this.api.post(this.apiEndpoint, data).pipe(
      tap((added: Cikktorzs) => {
        this.stateService.setStateOnAdd(this.fromJSON(added), this._data);
        //this.dbService.add('cikkek', this.fromJSON(added)).subscribe();
      }
      ),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<Cikktorzs> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: Cikktorzs) => {
        this.stateService.setStateOnUpdate(deleted, this._data);
        //this.dbService.deleteByKey('cikkek', deleted.id).subscribe();
      }
      ),
      catchError((err: any) => throwError(err))
    );
  }

  visszaallitas(row: any): Observable<any> {
    return this.api.post(`${this.visszaallitasEndpoint}/cikktorzs/${row.id}`, '').pipe(
      map((dolgozo: Cikktorzs) => { return this.fromJSON(dolgozo) }),
      tap((updated: Cikktorzs) => {
        // Update state
        this.stateService.setStateOnUpdate(updated, this._data);
      }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    )
  }

  update(data: Cikktorzs): Observable<Cikktorzs> {
    return this.api.put(`${this.apiEndpoint}/${data.id}`, data).pipe(
      tap((updated: Cikktorzs) => {
        this.stateService.setStateOnUpdate(this.fromJSON(updated[0]), this._data);
        //this.dbService.update('cikkek', this.fromJSON(updated[0])).subscribe();
      }
      ),
      catchError((err: any) => throwError(err))
    );
  }

  updateCikkBeszerar(data: any): Observable<Cikktorzs> {
    return this.api.put(`${environment.beszerarModositasApi}/${data.id}/${data.osszeg}`, "").pipe(
      tap((updated: Cikktorzs) =>
        this.stateService.setStateOnUpdate(this.fromJSON(updated), this._data)
      ),
      catchError((err: any) => throwError(err))
    );
  }

  getByIdFetch(id: string): Observable<any> {
    return this.api.get(`${this.apiEndpoint}/${id}`);
   }

  getById(id: string): Observable<Cikktorzs> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: Cikktorzs) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  getElerhetoKeszlet(raktarId: number, torzs?: string): Observable<Cikktorzs[]> {
    return forkJoin([
      this.initDataAktiv$,
      this.raktarkeszletService.fetchRaktarkeszletById(raktarId),
      this.raktarkeszletService.fetchFoglalasById()
    ]).pipe(
      map(([cikkek, raktarkeszlet, foglalasok]) =>
      torzs == 'elolegszamla' || torzs == 'kimeno-szamla' ? 
        this.getCikkekWithMennyiseg(
          this.updateCikkekAktualisMennyisegEloleg(cikkek, raktarkeszlet, foglalasok),
          torzs
        ) :
        this.getCikkekWithMennyiseg(
          this.updateCikkekAktualisMennyiseg(cikkek, raktarkeszlet, foglalasok),
          torzs
        )
      )
    );
  }

  getRaktarkeszlet(raktarId: number, torzs?: string): Observable<Cikktorzs[]> {
    return forkJoin([
      this.initDataAktiv$,
      this.raktarkeszletService.fetchRaktarkeszletById(raktarId),
      this.raktarkeszletService.fetchFoglalasById()
    ]).pipe(
      map(([cikkek, raktarkeszlet, foglalasok]) => {
        if(torzs == 'elolegszamla') {
          return this.updateCikkekAktualisMennyisegEloleg(cikkek, raktarkeszlet, foglalasok)
        } else {
          return this.updateCikkekAktualisMennyiseg(cikkek, raktarkeszlet, foglalasok)
        }
      }
      )
    );
  }

  setCikktorzsState(tetelek: any[]): any {
    tetelek.forEach(item => {
      const cikkItem = findByProp(this._data.value, 'id', item.id);

      this.stateService.setStateOnUpdate({
        ...cikkItem,
        foglalas: cikkItem.foglalas + item.mennyiseg
      }, this._data);
    })
  }

  private updateCikkekAktualisMennyisegEloleg(
    cikkek: Cikktorzs[],
    raktarkeszlet: RaktarkeszletByRaktar[],
    foglalasok: any[]
  ): Cikktorzs[] {
    // No cikk
    if (!cikkek) {
      return [];
    }

    // No raktarkeszlet
    else if (!raktarkeszlet) {
      return cikkek;
    }

    // Filter
    return cikkek.map((c: Cikktorzs) => {
      const raktarban = findByProp(raktarkeszlet, 'cikk_id', c.id);
      const foglalas = findByProp(foglalasok, 'cikk_id', c.id);

      return raktarban
        ? {
            ...c,
            aktualis_mennyiseg: raktarban.aktualis_mennyiseg,
            foglalas: foglalas ? foglalas.foglalasok : 0,
          }
        : c;
    });
  }

  private updateCikkekAktualisMennyiseg(
    cikkek: Cikktorzs[],
    raktarkeszlet: RaktarkeszletByRaktar[],
    foglalasok: any[]
  ): Cikktorzs[] {
    // No cikk
    if (!cikkek) {
      return [];
    }

    // No raktarkeszlet
    else if (!raktarkeszlet) {
      return cikkek;
    }

    // Filter
    return cikkek.map((c: Cikktorzs) => {
      const raktarban = findByProp(raktarkeszlet, 'cikk_id', c.id);
      const foglalas = findByProp(foglalasok, 'cikk_id', c.id);

      return raktarban
        ? {
            ...c,
            aktualis_mennyiseg: raktarban.aktualis_mennyiseg,
            foglalas: foglalas ? foglalas.foglalasok : 0,
          }
        : c;
    }).filter(cikk => cikk.cikkszam != 'vev001');
  }

  private getCikkekWithMennyiseg(cikkek: Cikktorzs[], torzs?: string): Cikktorzs[] {
    if(torzs == 'kimeno-szamla' || torzs == 'nyugta' || torzs == 'ajanlat' || torzs == 'dijbekero' || torzs == 'foglalas' || torzs == 'elolegszamla') {
      return cikkek.filter((c: Cikktorzs) => c.aktualis_mennyiseg > 0 || c.cikkcsoport_id == 57 || c.id == 4825);
    } else {
      return cikkek.filter((c: Cikktorzs) => c.aktualis_mennyiseg > 0 || c.id == 4825);
    }
  }

  private getCikkekWithoutSzolgaltatas(cikkek: Cikktorzs[]) {
    return cikkek.filter((c: Cikktorzs) => c.cikkcsoport_id != 57);
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        map((data: any[]) => this.fromJSON(data)),
        tap((data: any[]) => this._data.next(data.sort((a: any, b: any) => {
          if(a.cikknev < b.cikknev) { return -1; }
          if(a.cikknev > b.cikknev) { return 1; }
          return 0;
        }))),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.afakulcsokService.initData$,
      this.arkategoriakService.initData$,
      this.cikkcsoportokService.initData$,
    ]).pipe(
      map(([cikkek, afakulcsok, arkategoriak, cikkcsoportok]) => {
        this.dbService.clear('cikkek').subscribe();
            this.dbService
              .bulkAdd('cikkek', this.mapCikkek(cikkek, afakulcsok, arkategoriak, cikkcsoportok))
              .subscribe((result) => {
                localStorage.setItem('cikkek', JSON.stringify({db: result.length, lastFetch: this.lastFetch}));
              }, error => console.log(error));
        return this.mapCikkek(cikkek, afakulcsok, arkategoriak, cikkcsoportok)
      }
      )
    );
  }

  // private fetchData(): Observable<any[]> {
  //   this.lastFetch = new Date();
  //   return forkJoin([
  //     this.api.get(this.apiEndpoint).pipe(
  //       map((data: any[]) => this.fromJSON(data)),
  //       tap((data: any[]) => this._data.next(data)),
  //       catchError((err: any) => {
  //         console.log(err);
  //         return of([]);
  //       })
  //     ),
  //     this.afakulcsokService.initData$,
  //     this.arkategoriakService.initData$,
  //     this.cikkcsoportokService.initData$,
  //   ]).pipe(
  //     map(([cikkek, afakulcsok, arkategoriak, cikkcsoportok]) => {
  //       // this.dbService.count('cikkek').subscribe(count => {
  //       //   if(count == 0) {
  //       //     this.dbService
  //       //       .bulkAdd('cikkek', this.mapCikkek(cikkek, afakulcsok, arkategoriak, cikkcsoportok))
  //       //       .subscribe((result) => {
  //       //       }, error => console.log(error));
  //       //   }
  //       //   localStorage.setItem('cikkek', JSON.stringify({db: cikkek.length, lastFetch: this.lastFetch}));
  //       //   //this.cikkek = count;
  //       // })
  //       localStorage.setItem('cikkek', JSON.stringify({db: cikkek.length, lastFetch: this.lastFetch}));

  //       return this.mapCikkek(cikkek, afakulcsok, arkategoriak, cikkcsoportok)
  //     }
  //     )
  //   );
  // }

  getDBData(): Observable<any> {
    console.log('get cikkek from indexedDB')
    // return this.dbService.getAll('cikkek').pipe(
    //   map((data: any[]) => this.fromJSON(data)),
    //   tap((data: any[]) => this._data.next(data)),
    // );
    return forkJoin([
      this.dbService.getAll('cikkek').pipe(
        map((data: any[]) => this.fromJSON(data)),
        tap((data: any[]) => this._data.next(data.sort((a: any, b: any) => {
          if(a.cikknev < b.cikknev) { return -1; }
          if(a.cikknev > b.cikknev) { return 1; }
          return 0;
        }))),
      ),
      this.afakulcsokService.initData$,
      this.arkategoriakService.initData$,
      this.cikkcsoportokService.initData$,
    ]).pipe(
      map(([cikkek, afakulcsok, arkategoriak, cikkcsoportok]) => {
        return this.mapCikkek(cikkek, afakulcsok, arkategoriak, cikkcsoportok)
      }
      )
    );
  }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      map((columns: Column[]) => {
        columns.push({ name: 'cikkcsoport', nullable: true });

        const displayedColumns: string[] = [
          // 'id',
          'cikkszam',
          'cikknev',
          'cikkcsoport',
          'beszerzesi_ar',
          'fix_ar'
        ];

        const map = columns.map((c: Column) => {
          // Custom
          if (c.name === 'beszerzesi_ar') {
            return {
              ...c,
              suffix: 'Ft',
              type: 'currency',
            };
          }

          else if (c.name === 'cikkszam') {
            return {
              ...c,
              width: '160px'
            };
          }

          else if (c.name === 'fix_ar') {
            return {
              ...c,
              title: 'Bruttó fix',
              suffix: 'Ft',
              type: 'currency',
              width: '180px'
            }
          }

          // Shown
          else if (displayedColumns.includes(c.name)) {
            return c;
          }

          // Hidden
          else {
            return {
              ...c,
              hidden: true,
            };
          }
        });

        const index = map.findIndex((c: Column) => c.name === 'me_id');

        map.splice(index, 0, { name: 'me_text', nullable: true, width: '180px'});

        return map;
      }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private fromJSON(cikk: Cikktorzs | Cikktorzs[]): Cikktorzs | Cikktorzs[] {
    if (Array.isArray(cikk)) {
      return cikk.map((c: Cikktorzs) => ({
        ...c,
        elfekvo_figyeles: c.elfekvo_figyeles ? true : false,
      }));
    }

    return {
      ...cikk,
      elfekvo_figyeles: cikk.elfekvo_figyeles ? true : false,
    };
  }

  private mapCikkek(
    data: Cikktorzs[],
    afakulcsok: Afakulcs[],
    arkategoriak: Arkategoria[],
    cikkcsoportok: Cikkcsoport[]
  ): Cikktorzs[] {
    const kiskerSzazalek: number =
      1 + (findByProp(arkategoriak, 'id', 3)?.ertek / 100 || 0);
    const kivitelezoiSzazalek: number =
      1 + (findByProp(arkategoriak, 'id', 4)?.ertek / 100 || 0);
    const viszonteladoiSzazalek: number =
      1 + (findByProp(arkategoriak, 'id', 5)?.ertek / 100 || 0);

    return data.map((e: Cikktorzs) =>
      this.mapCikk(
        e,
        afakulcsok,
        cikkcsoportok,
        kiskerSzazalek,
        kivitelezoiSzazalek,
        viszonteladoiSzazalek
      )
    );
  }

  private mapCikk(
    data: Cikktorzs,
    afakulcsok: Afakulcs[],
    cikkcsoportok: Cikkcsoport[],
    kiskerSzazalek: number,
    kivitelezoiSzazalek: number,
    viszonteladoiSzazalek: number
  ): Cikktorzs {
    const afakulcs: number = findByProp(
      afakulcsok,
      'id',
      data.afakulcs_id
    )?.afakulcs;

    return {
      ...data,
      elfekvo_figyeles: !data.elfekvo_figyeles ? false : true,
      kiskerAr: calcBrutto(data.beszerzesi_ar * kiskerSzazalek, afakulcs),
      kivitelezoiAr: calcBrutto(
        data.beszerzesi_ar * kivitelezoiSzazalek,
        afakulcs
      ),
      viszonteladoiAr: calcBrutto(
        data.beszerzesi_ar * viszonteladoiSzazalek,
        afakulcs
      ),
      cikkcsoport: data?.cikkcsoport_id
        ? findByProp(cikkcsoportok, 'id', data.cikkcsoport_id)?.nev
        : null,
    };
  }

  public setCikkcsoport(payload: any): Observable<Cikktorzs[]> {
    return this.api.post(this.cikkCsoportEndpoint, payload).pipe(
      tap((added: Cikktorzs[]) => {
        added.forEach(item => {
          this.stateService.setStateOnUpdate(this.fromJSON(item), this._data)
        })
    }),
      catchError((err: any) => throwError(err))
    );
  }

  getMennyiseg(): any {
    return this.api.postBizonylat(`service/helplib/ossz_bizonylat/ci/0`, "");
  }

  clearData(): any {
    //this.data = [];
  } 

  getCikktorzsChange(raktarId: number): any {
    this.lastFetch = new Date();
    const last = JSON.parse(localStorage.getItem('cikkek'))?.lastFetch;
    this.api.get(`${this.cikkUpdateEndpoint}/${last}/${raktarId}`).pipe(
        map((data: any[]) => this.fromJSON(data)),
        //tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
    )
    .subscribe((resp: any) => {
      if(resp) {
        this.dbService.getAll('cikkek').subscribe((cikkek: Cikktorzs[]) => {
          //this._data.next(cikkek.sort((a: any,b: any) => a.cikknev - b.cikknev));
          this._data.next(cikkek.sort((a: any, b: any) => {
            if(a.cikknev < b.cikknev) { return -1; }
            if(a.cikknev > b.cikknev) { return 1; }
            return 0;
          }));
          if(resp.valtozas.length > 0) {
            resp.valtozas.forEach(cikk => {
              const cikkIndex = this._data.value?.findIndex(item => item.id == cikk.id);

              if(cikkIndex < 0) {
                this._data.value.push(cikk);
                this.dbService.add('cikkek', this.fromJSON(cikk)).subscribe();
              } else {
                this._data.value[cikkIndex] = {
                  ...cikk
                  // ...cikkItem,
                  // beszerzesi_ar: cikk?.beszerzesi,
                  // aktualis_mennyiseg: cikk?.aktualis_mennyiseg,
                  // foglalas: cikkItem?.foglalas + cikk?.foglalas,
                }
                this.dbService.update('cikkek', this.fromJSON(cikk)).subscribe();
              }
            });
          }
          if(resp.torolt_lista.length > 0) {
            resp.torolt_lista.forEach(cikk => {
              const biz = findByProp(this._data.value, 'id', cikk.modul_id);
              if(biz) {
                this.stateService.setStateOnDelete(biz ,this._data);
                this.dbService.deleteByKey('cikkek', cikk.modul_id).subscribe();
              }
            })
          }

          this.dbService.count('cikkek').subscribe(count => {
            localStorage.setItem('cikkek', JSON.stringify({db: count, lastFetch: this.lastFetch}))
          })  
        })      
      }
    }
    );
  }
}
