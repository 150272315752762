import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Subject, forkJoin, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';
import { CikktorzsService } from '../../torzsek/services/cikktorzs.service';
import { DolgozokService } from '../../torzsek/services/dolgozok.service';
import { PartnerekService } from '../../torzsek/services/partnerek.service';
import { KimenoSzamlaService } from '../../torzsek/services/kimeno-szamla.service';
import { AjanlatService } from '../../torzsek/services/ajanlat.service';
import { BevetelezesService } from '../../torzsek/services/bevetelezes.service';
import { BejovoSzamlaService } from '../../torzsek/services/bejovo-szamla.service';
import { BejovoKoltsegService } from '../../torzsek/services/bejovo-koltseg.service';
import { DijbekeroService } from '../../torzsek/services/dijbekero.service';
import { ElolegszamlaService } from '../../torzsek/services/elolegszamla.service';
import { FoglalasService } from '../../torzsek/services/foglalas.service';
import { KulsoRaktariEladasService } from '../../torzsek/services/kulso-raktari-eladas.service';
import { NyugtaService } from '../../torzsek/services/nyugta.service';
import { SzallitolevelService } from '../../torzsek/services/szallitolevel.service';
import { OrszagokService } from '../../torzsek/services/orszagok.service';
import { PenzintezetekService } from '../../torzsek/services/penzintezetek.service';
import { MegyekService } from '../../torzsek/services/megyek.service';

@Component({
  selector: 'app-auth-login-v2',
  templateUrl: './auth-login-v2.component.html',
  styleUrls: ['./auth-login-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthLoginV2Component implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private authService: AuthenticationService,
    private dolgozokService: DolgozokService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private cikktorzsService: CikktorzsService,
    private partnerekService: PartnerekService,
    private kimenoService: KimenoSzamlaService,
    private ajanlatService: AjanlatService,
    private bevetelezesService: BevetelezesService,
    private bejovoSzamlaService: BejovoSzamlaService,
    private bejovoKoltsegService: BejovoKoltsegService,
    private dijbekeroService: DijbekeroService,
    private elolegszamlaService: ElolegszamlaService,
    private foglalasService: FoglalasService,
    private kulsoraktariService: KulsoRaktariEladasService,
    private nyugtaService: NyugtaService,
    private szallitoService: SzallitolevelService,
    private orszagokService: OrszagokService,
    private penzintezetekService: PenzintezetekService,
    private megyekService: MegyekService
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get formValue(): { felhasznalonev: string; jelszo: string } {
    return this.loginForm.getRawValue();
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.loading = true;

    this.authService
      .login(this.formValue.felhasznalonev, this.formValue.jelszo)
      .pipe(
        take(1),
        catchError((err) => {
          this.loading = false;
          console.log(err);

          return throwError(err);
        })
      )
      .subscribe((resp) => {
        // this.returnUrl
        //   ? this._router.navigateByUrl(this.returnUrl)
        //   : this._router.navigate(['/']);
      if(resp) {
          const allApiCalls = [
            this.partnerekService.initData$.pipe(take(1)),
            this.cikktorzsService.initData$.pipe(take(1)),
            // this.kimenoService.initData$.pipe(take(1)),
            // this.ajanlatService.initData$.pipe(take(1)),
            // this.bejovoKoltsegService.initData$.pipe(take(1)),
            // this.bejovoSzamlaService.initData$.pipe(take(1)),
            // this.bevetelezesService.initData$.pipe(take(1)),
            // this.dijbekeroService.initData$.pipe(take(1)),
            // this.elolegszamlaService.initData$.pipe(take(1)),
            // this.foglalasService.initData$.pipe(take(1)),
            // this.kulsoraktariService.initData$.pipe(take(1)),
            // this.nyugtaService.initData$.pipe(take(1)),
            // this.szallitoService.initData$.pipe(take(1)),
            this.dolgozokService.fetchData().pipe(take(1)),
            this.orszagokService.initData$.pipe(take(1)),
            this.megyekService.initData$.pipe(take(1)),
            this.penzintezetekService.initData$.pipe(take(1))
          ];

          const numberOfSimultaneousRequests = 1;

          forkJoin(
            of(...allApiCalls)
           .pipe(
            mergeMap((apiCall) => apiCall, numberOfSimultaneousRequests),
          )
          ).subscribe(resp => {
            if(resp) {
              this._router.navigate(['/']);
              //this.loading = false;
            }
          })
        }
      }
      );
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      felhasznalonev: [null, Validators.required],
      jelszo: [null, Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
