import { Injectable } from '@angular/core';
import { findByProp } from '../utils';

interface MapData {
  dataSrc: any[];
  column: string;
  key: string;
  value: string;
  displayProp: string;
}

@Injectable({
  providedIn: 'root',
})
export class ListMapperService {
  constructor() {}

  mapData(data: any[], mapData: MapData[]): any[] {
    if (!Array.isArray(data) || !mapData?.length) {
      return data;
    }

    return data.map((e: any) => this.mapListItem(e, mapData));
  }

  private mapListItem(data: any, mapData: MapData[]): any {
    if (!data || !mapData?.length) {
      return data;
    }

    let mapped = { ...data };

    mapData.forEach(({ dataSrc, column, key, value, displayProp }) => {
      mapped = {
        ...mapped,
        [column]: findByProp(dataSrc, key, data[value])?.[displayProp] || '-',
      };
    });

    return mapped;
  }
}
