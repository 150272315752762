<div class="content-wrapper container p-0">
  <div class="content-body">
    <section class="invoice-preview-wrapper">
      <div class="row invoice-preview">
        <!-- Invoice -->
        <div class="col-12 printRef">
          <div class="card invoice-preview-card">
            <div class="card-body invoice-padding pb-0">
              <!-- Header Starts -->
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <svg
                      viewBox="0 0 139 95"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      height="24"
                    >
                      <defs>
                        <linearGradient
                          id="invoice-linearGradient-1"
                          x1="100%"
                          y1="10.5120544%"
                          x2="50%"
                          y2="89.4879456%"
                        >
                          <stop stop-color="#000000" offset="0%"></stop>
                          <stop stop-color="#FFFFFF" offset="100%"></stop>
                        </linearGradient>
                        <linearGradient
                          id="invoice-linearGradient-2"
                          x1="64.0437835%"
                          y1="46.3276743%"
                          x2="37.373316%"
                          y2="100%"
                        >
                          <stop
                            stop-color="#EEEEEE"
                            stop-opacity="0"
                            offset="0%"
                          ></stop>
                          <stop stop-color="#FFFFFF" offset="100%"></stop>
                        </linearGradient>
                      </defs>
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g transform="translate(-400.000000, -178.000000)">
                          <g transform="translate(400.000000, 178.000000)">
                            <path
                              class="text-primary"
                              d="M-5.68434189e-14,2.84217094e-14 L39.1816085,2.84217094e-14 L69.3453773,32.2519224 L101.428699,2.84217094e-14 L138.784583,2.84217094e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L6.71554594,44.4188507 C2.46876683,39.9813776 0.345377275,35.1089553 0.345377275,29.8015838 C0.345377275,24.4942122 0.230251516,14.560351 -5.68434189e-14,2.84217094e-14 Z"
                              style="fill: currentColor"
                            ></path>
                            <path
                              d="M69.3453773,32.2519224 L101.428699,1.42108547e-14 L138.784583,1.42108547e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L32.8435758,70.5039241 L69.3453773,32.2519224 Z"
                              fill="url(#invoice-linearGradient-1)"
                              opacity="0.2"
                            ></path>
                            <polygon
                              fill="#000000"
                              opacity="0.049999997"
                              points="69.3922914 32.4202615 32.8435758 70.5039241 54.0490008 16.1851325"
                            ></polygon>
                            <polygon
                              fill="#000000"
                              opacity="0.099999994"
                              points="69.3922914 32.4202615 32.8435758 70.5039241 58.3683556 20.7402338"
                            ></polygon>
                            <polygon
                              fill="url(#invoice-linearGradient-2)"
                              opacity="0.099999994"
                              points="101.428699 0 83.0667527 94.1480575 130.378721 47.0740288"
                            ></polygon>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <h3 class="text-primary invoice-logo">Vuexy</h3>
                  </div>
                  <p class="card-text mb-25">FREDERIK TECHNOLOGIES</p>
                  <p class="card-text mb-25">5600 Békéscsaba Kinizsi utca 5.</p>
                  <p class="card-text mb-0">iroda@frederik-technologies.com</p>
                </div>
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Számla
                    <span class="invoice-number">#2022/1</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Kiállítás dátuma:</p>
                    <p class="invoice-date">2022-09-01</p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Fizetési határidő:</p>
                    <p class="invoice-date">2022-09-10</p>
                  </div>
                </div>
              </div>
              <!-- Header Ends -->
            </div>

            <hr class="invoice-spacing" />

            <!-- Address And Contact Starts -->
            <div class="card-body invoice-padding pt-0">
              <div class="row invoice-spacing">
                <div class="col-xl-6 p-0">
                  <h6 class="mb-2">Vevő:</h6>
                  <h6 class="mb-25">Aranyi Kft.</h6>
                  <p class="card-text mb-25">5600 Békéscsaba Teszt utca 1.</p>
                  <p class="card-text mb-25">+36 30 746 44 41</p>
                  <p class="card-text mb-0">aranyi@aranyi.com</p>
                </div>
                <div class="col-xl-6 p-0 mt-xl-0 mt-2">
                  <h6 class="mb-2">Fizetési adatok:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Összesen:</td>
                        <td>
                          <span class="font-weight-bold">$3000</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Bank neve:</td>
                        <td>OTP Bank</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Ország:</td>
                        <td>Magyarország</td>
                      </tr>
                      <tr>
                        <td class="pr-1">IBAN:</td>
                        <td>IBAN helye</td>
                      </tr>
                      <tr>
                        <td class="pr-1">SWIFT code:</td>
                        <td>SWIFT helye</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Address And Contact Ends -->

            <!-- Invoice Description Starts -->
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="py-1">Task description</th>
                    <th class="py-1">Rate</th>
                    <th class="py-1">Hours</th>
                    <th class="py-1">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="py-1">
                      <p class="card-text font-weight-bold mb-25">
                        Native App Development
                      </p>
                      <p class="card-text text-nowrap">
                        Developed a full stack native app using React Native,
                        Bootstrap & Python
                      </p>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">$60.00</span>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">30</span>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">$1,800.00</span>
                    </td>
                  </tr>
                  <tr class="border-bottom">
                    <td class="py-1">
                      <p class="card-text font-weight-bold mb-25">
                        Ui Kit Design
                      </p>
                      <p class="card-text text-nowrap">
                        Designed a UI kit for native app using Sketch, Figma &
                        Adobe XD
                      </p>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">$60.00</span>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">20</span>
                    </td>
                    <td class="py-1">
                      <span class="font-weight-bold">$1200.00</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="card-body invoice-padding pb-0">
              <div class="row invoice-sales-total-wrapper">
                <div class="col-md-6 order-md-1 order-2 mt-md-0 mt-3">
                  <p class="card-text mb-0">
                    <span class="font-weight-bold">Salesperson:</span>
                    <span class="ml-75">Alfie Solomons</span>
                  </p>
                </div>
                <div
                  class="col-md-6 d-flex justify-content-end order-md-2 order-1"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Subtotal:</p>
                      <p class="invoice-total-amount">$3000</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Discount:</p>
                      <p class="invoice-total-amount">$0</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Tax:</p>
                      <p class="invoice-total-amount">27%</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">$3810</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Invoice Description Ends -->

            <hr class="invoice-spacing" />

            <!-- Invoice Note Starts -->
            <div class="card-body invoice-padding pt-0">
              <div class="row">
                <div class="col-12">
                  <span class="font-weight-bold">Note: </span>
                  <span
                    >It was a pleasure working with you and your team. We hope
                    you will keep us in mind for future freelance projects.
                    Thank You!</span
                  >
                </div>
              </div>
            </div>
            <!-- Invoice Note Ends -->
          </div>
        </div>
        <!--/ Invoice -->

        <!-- Invoice Actions -->
        <!-- <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
          <div class="card">
            <div class="card-body">
              <button
                class="btn btn-primary btn-block mb-75"
                data-toggle="modal"
                data-target="#send-invoice-sidebar"
                (click)="toggleSidebar('invoice-sidebar')"
                rippleEffect
              >
                <span data-feather="mail" class="cursor-pointer"></span>
                Küldés emailben
              </button>
              <button
                class="btn btn-outline-secondary btn-block btn-download-invoice mb-75"
                rippleEffect
              >
                <span data-feather="download" class="cursor-pointer"></span>
                Letöltés PDF-ben
              </button>
              <a
                class="btn btn-outline-secondary btn-block mb-75"
                href="javascript:window.print();"
                rippleEffect
              >
                <span data-feather="printer" class="cursor-pointer"></span>
                Nyomtatás
              </a>
            </div>
          </div>
        </div> -->
        <!--/ Invoice Actions -->
      </div>
    </section>
  </div>

  <!-- Send Invoice Sidebar -->
  <!-- <core-sidebar
    class="modal modal-slide-in sidebar-custom-modal fade"
    name="invoice-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-send-invoice-sidebar-preview></app-send-invoice-sidebar-preview>
  </core-sidebar> -->
  <!--/  Send Invoice Sidebar -->

  <!-- Add Payment Sidebar -->
  <!-- <core-sidebar
    class="modal modal-slide-in sidebar-custom-modal fade"
    name="add-payment-sidebar"
    overlayClass="modal-backdrop"
    hideOnEsc="true"
  >
    <app-add-payment-sidebar-preview></app-add-payment-sidebar-preview>
  </core-sidebar> -->
  <!--/  Add Payment Sidebar -->
</div>
