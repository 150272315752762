<!-- Footer -->
<p class="clearfix mb-0 d-flex">
    <span class="mx-auto d-none d-md-block">
    <img
      src="assets/images/logo/footer_logo.png"
      [ngStyle]="{ height: '35px', filter: 'invert(100%)' }"
    />
    <span id="app" class="ml-1">v{{ appVersion }}</span>
    </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>