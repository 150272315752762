import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceListService } from './invoice-list/invoice-list.service';
import { InvoicePreviewComponent } from './invoice-preview/invoice-preview.component';
import { InvoicePreviewService } from './invoice-preview/invoice-preview.service';
import { AddPaymentSidebarPreviewComponent } from './invoice-preview/sidebar/add-payment-sidebar-preview/add-payment-sidebar-preview.component';
import { SendInvoiceSidebarPreviewComponent } from './invoice-preview/sidebar/send-invoice-sidebar-preview/send-invoice-sidebar-preview.component';

@NgModule({
  declarations: [
    InvoiceListComponent,
    InvoicePreviewComponent,
    AddPaymentSidebarPreviewComponent,
    SendInvoiceSidebarPreviewComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    CoreDirectivesModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    FormsModule,
    CorePipesModule,
    NgbModule,
    NgSelectModule,
    CoreSidebarModule,
  ],
  providers: [InvoiceListService, InvoicePreviewService],
  exports: [InvoicePreviewComponent],
})
export class InvoiceModule {}
