import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
} from 'rxjs';
import {
  Cikktorzs,
} from '../models/torzsek';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  todos: any;
  //notificationNumber: number = 0;
  public notificationChange: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public notificationNumber$ = this.notificationChange.asObservable();


  constructor() {
  }

  updateNotificationNumber(item: any) {  
    const prev = this.notificationChange.getValue(); 
    this.notificationChange.next(prev + item);
}

}
