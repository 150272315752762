import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { EventApi, EventDef } from '@fullcalendar/angular';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  combineLatest,
} from 'rxjs';
import { switchMap, catchError, take, tap, map } from 'rxjs/operators';
import { NaptarKategoriakService } from '../../services/naptar-kategoriak.service';
import { EventRef, EventRefBackend } from './calendar.model';

@Injectable({
  providedIn: 'root',
})
export class CalendarReworkService {
  private readonly apiEndpoint = 'naptar';

  private readonly _data = new BehaviorSubject<EventRef[]>(null);
  private readonly _selectedData = new BehaviorSubject<EventRef | {}>({});
  private readonly _activeKategoriak = new BehaviorSubject<number[]>([
    1, 2, 3, 4, 5,
  ]);

  private state$ = this._data.pipe(
    switchMap((state: EventRef[]) => (state ? of(state) : this.fetchData()))
  );

  public data$ = combineLatest([
    this.state$,
    this.kategoriakService.data$,
  ]).pipe(
    map(([events, kategoriak]) =>
      this.mapFuvarozo(events.filter(
        (e: EventRef) =>
          kategoriak.find((k: any) => k.id === e.naptar_kategoria_id)?.checked
      ), kategoriak)
    )
  );

  constructor(
    private api: ApiService,
    private kategoriakService: NaptarKategoriakService,
    private stateService: StateService
  ) {}

  get initData$(): Observable<EventRef[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: EventRef[]) => (state ? of(state) : this.fetchData()))
    );
  }

  get selectedData$(): Observable<EventRef> {
    return this._selectedData.pipe(map((todo: EventRef) => todo || null));
  }

  add(data: EventRef): Observable<EventRef> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      map((added: EventRefBackend) => this.fromJSON(added)),
      tap((added: EventRef) => {
        this.stateService.setStateOnAdd(added, this._data);
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<EventRef> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: EventRef) => {
        this.stateService.setStateOnDelete(deleted, this._data);
        this._selectedData.next({});
      }),
      catchError((err: any) => throwError(err))
    );
  }

  update(data: EventRef): Observable<EventRef> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        map((updated: EventRefBackend) => this.fromJSON(updated)),
        tap((updated: EventRef) =>
          this.stateService.setStateOnUpdate(updated, this._data)
        ),
        catchError((err: any) => throwError(err))
      );
  }

  createNewTodo(): void {
    this._selectedData.next({});
  }

  selectKategoria(selected: number[]): void {
    this._activeKategoriak.next(selected);
  }

  selectDate(event: EventRef): void {
    this._selectedData.next(event);
  }

  selectEvent(event?: EventApi): void {
    if (!event) {
      this._selectedData.next({});
      return;
    }

    let eventRef = new EventRef();

    eventRef.id = +event.id;
    eventRef.title = event.title;
    eventRef.start = event.start;
    eventRef.end = event.end;
    eventRef.allDay = event.allDay;
    eventRef = {
      ...eventRef,
      ...event.extendedProps,
    };

    this._selectedData.next(eventRef);
  }

  fetchData(): Observable<EventRef[]> {
    return this.api.get(this.apiEndpoint).pipe(
      map((events: EventRefBackend[]) => this.fromJSON(events)),
      tap((data: EventRef[]) => this._data.next(data)),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private toJSON(data: EventRef): EventRefBackend {
    return {
      id: data.id,
      cim: data.title,
      esemeny_kezdete: data.start,
      esemeny_vege: data.end,
      egesznap: data.allDay,
      dolgozo_id: data.dolgozo_id || null,
      helyszin: data.helyszin || null,
      link: data.link || null,
      naptar_kategoria_id: data.naptar_kategoria_id || null,
      leiras: data.leiras || null,
    };
  }

  private fromJSON(
    data: EventRefBackend | EventRefBackend[]
  ): EventRef | EventRef[] {
    // Array
    if (Array.isArray(data)) {
      return data.map((e: EventRefBackend) => ({
        id: e.id,
        title: e.cim,
        start: new Date((e?.esemeny_kezdete as string).replace(' ', 'T')),
        end: new Date((e?.esemeny_vege as string).replace(' ', 'T')),
        allDay: e.egesznap === 1 || e.egesznap === true ? true : false,
        editable: true,
        naptar_kategoria_id: e.naptar_kategoria_id,
        dolgozo_id: e.dolgozo_id,
        helyszin: e.helyszin,
        leiras: e.leiras,
        link: e.link,
      }));
    }

    // Object
    else {
      return {
        id: data.id,
        title: data.cim,
        start: new Date((data?.esemeny_kezdete as string).replace(' ', 'T')),
        end: new Date((data?.esemeny_vege as string).replace(' ', 'T')),
        allDay: data.egesznap === 1 || data.egesznap === true ? true : false,
        editable: true,
        naptar_kategoria_id: data.naptar_kategoria_id,
        dolgozo_id: data.dolgozo_id,
        helyszin: data.helyszin,
        leiras: data.leiras,
        link: data.link,
      };
    }
  }

  private mapFuvarozo(data: any[], naptarKategoriak: any[]): any {
    return data.map(item => {
      return {
        ...item,
        fuvarozo: naptarKategoriak.find((k: any) => k.id === item.naptar_kategoria_id)?.fuvarozo
      }
    })
  }
}
