import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { AuthenticationService } from 'app/auth/service';
import { of, Observable, throwError, combineLatest } from 'rxjs';
import { switchMap, take, catchError, map, pluck } from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { OnelszamoloEgysegekService } from './onelszamolo-egysegek.service';

@Injectable({
  providedIn: 'root',
})
export class RaktarakService {
  private readonly raktarColumnsEndpoint = 'raktarak/columns';

  private readonly _dataByUser = combineLatest([
    this.userService.currentUser$,
    this.data$,
  ]).pipe(
    map(([user, raktarak]) =>
      raktarak.filter((r: any) => user.oe === 4 || r.oe_id === user.oe)
    )
  );

  constructor(
    private api: ApiService,
    private oeService: OnelszamoloEgysegekService,
    private userService: AuthenticationService
  ) {}

  get data$(): Observable<any[]> {
    return this.oeService.allData$.pipe(
      switchMap((data: { oek: any[]; raktarak: any[] }) =>
        data?.raktarak ? of(data) : this.oeService.initDataAll$
      ),
      pluck('raktarak')
    );
  }

  get dataByUser$(): Observable<any[]> {
    return this._dataByUser;
  }

  get initData$(): Observable<any[]> {
    return this.oeService.initDataAll$.pipe(pluck('raktarak'));
  }

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  add(data: any): Observable<any> {
    return this.oeService.addRaktar(data);
  }

  delete(id: number): Observable<any> {
    return this.oeService.deleteRaktar(id);
  }

  update(data: any): Observable<any> {
    return this.oeService.updateRaktar(data);
  }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.raktarColumnsEndpoint).pipe(
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }
}
