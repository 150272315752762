import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { Observable, forkJoin } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { Column } from '../../models/torzsek';
import { KimenoSzamlaService } from '../../services/kimeno-szamla.service';
import { addDays } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ErtekesitesDetailsResolver implements Resolve<any> {
  constructor(
    private userService: AuthenticationService,
    private kimenoService: KimenoSzamlaService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return forkJoin([
      this.kimenoService.columns$,
      this.userService.currentUser$.pipe(take(1)),
    ]).pipe(
      map(([columns, user]) => ({
        data: {
          oe_id: user.oe,
          bizonylat_datum: new Date(),
          bizonylat_datuma: new Date(),
          szamla_kelte: new Date(),
          teljesites_datum: new Date(),
          fizetesi_hatarido: new Date(),
          atado_oe_id: user.oe,
          atado_raktar_id: user.raktar,
          penznem_id: 1,
          fizetesi_mod_id: 8,
          arkategoria_id: 3,
          ervenyessegi_datum: addDays(new Date(), 30),
          statusz: 0,
        },
        columns: columns.map((c: Column) =>
          c.name === 'partner_id' || c.name === 'cim'
            ? { ...c, nullable: true }
            : c
        ),
        user,
        torzs: 'kimeno-szamla',
        bizonylattipusok: [],
      }))
    );
  }
}
