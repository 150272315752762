import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cimTransform',
})
export class CimTransformPipe implements PipeTransform {
  transform(cim: any): string {
    console.log(cim);
    if (!cim) {
      return '-';
    } else {
      return `${cim?.iranyitoszam || ''} ${cim?.telepules || ''} ${
        cim?.kozterulet_neve || ''
      } ${cim?.kozterulet_tipusa || ''} ${cim?.hazszam || ''}. ${
        cim?.epulet || ''
      } ${cim?.emelet ? `${cim?.emelet}/` : ''} ${
        cim?.ajto ? `${cim?.ajto}.` : ''
      }`;
    }
  }
}
