import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getBgColor',
})
export class GetBgColorPipe implements PipeTransform {
  transform(color: string): string {
    const split = color.split(',');

    return `${split[0]},${split[1]},${split[2]},${0.1})`.replace(/\s/g, '');
  }
}
