import { Injectable } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventModalComponent } from './calendar-event-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CalendarEventModalService {
  modalRef: NgbActiveModal;

  constructor(private modalService: NgbModal) {}

  openEventModal(): void {
    if (!this.modalRef) {
      this.modalRef = this.modalService.open(CalendarEventModalComponent);
    }
  }

  closeEventModal(): void {
    this.modalRef.close();
    this.modalRef = undefined;
  }
}
