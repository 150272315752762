import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TetelekToolbarComponent } from './tetelek-toolbar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { SharedModule } from 'app/main/shared/shared.module';
import { FilterMeByCikkPipe } from 'app/main/pages/torzsek/pipes/filter-me-by-cikk.pipe';
import { FindByKeyPipeModule } from 'app/main/shared/pipes/find-by-key-pipe/find-by-key.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [TetelekToolbarComponent, FilterMeByCikkPipe],
  imports: [
    CommonModule,
    CoreDirectivesModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbTooltipModule,
    CoreTouchspinModule,
    FindByKeyPipeModule,
    SharedModule,
  ],
  exports: [TetelekToolbarComponent],
})
export class TetelekToolbarModule {}
