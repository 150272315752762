import { Injectable } from '@angular/core';
import { ActiveToast, GlobalConfig, ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';
import { ToastrComponent } from '../components/toastr/toastr.component';

@Injectable({
  providedIn: 'root',
})
export class CustomToastService {
  private toastRef: ActiveToast<any>;
  private options: GlobalConfig;

  constructor(private toastr: ToastrService) {
    this.options = { ...this.toastr.toastrConfig, timeOut: 2000 };
  }

  // Custom Success
  toastrCustomSuccess(title: string, msg: string = '') {
    const customToastrRef = cloneDeep(this.options);
    customToastrRef.toastComponent = ToastrComponent;
    customToastrRef.closeButton = true;
    customToastrRef.tapToDismiss = false;
    customToastrRef.progressBar = true;
    customToastrRef.toastClass = 'toast ngx-toastr';
    this.toastr.success(msg, title, customToastrRef);
  }

  // Custom Warning
  toastrCustomWarning(title: string, msg: string = '', option?: string) {
    if(option == 'szallito') {
      this.options = { ...this.toastr.toastrConfig, timeOut: 4000}
    } else {
      this.options = { ...this.toastr.toastrConfig, timeOut: 2000}
    }
    
    const customToastrRef = cloneDeep(this.options);
    customToastrRef.toastComponent = ToastrComponent;
    customToastrRef.closeButton = true;
    customToastrRef.tapToDismiss = false;
    customToastrRef.progressBar = true;
    customToastrRef.toastClass = 'toast ngx-toastr';
    this.toastr.warning(msg, title, customToastrRef);
  }

  // Custom Danger
  toastrCustomDanger(title: string, msg: string = '') {
    const customToastrRef = cloneDeep(this.options);
    customToastrRef.toastComponent = ToastrComponent;
    customToastrRef.closeButton = true;
    customToastrRef.tapToDismiss = false;
    customToastrRef.progressBar = true;
    customToastrRef.toastClass = 'toast ngx-toastr';
    this.toastr.error(msg, title, customToastrRef);
  }

  // Custom Info
  toastrCustomInfo(title: string, msg: string = '', option?: string) {
    if(option == 'szallito') {
      this.options = { ...this.toastr.toastrConfig, timeOut: 4000}
    } else {
      this.options = { ...this.toastr.toastrConfig, timeOut: 2000}
    }

    const customToastrRef = cloneDeep(this.options);
    customToastrRef.toastComponent = ToastrComponent;
    customToastrRef.closeButton = true;
    customToastrRef.tapToDismiss = false;
    customToastrRef.progressBar = true;
    customToastrRef.toastClass = 'toast ngx-toastr';
    this.toastr.info(msg, title, customToastrRef);
  }

  // Success
  toastrSuccess() {
    this.toastr.success(
      '👋 Jelly-o macaroon brownie tart ice cream croissant jelly-o apple pie.',
      'Success!',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Info
  toastrInfo() {
    this.toastr.info(
      '👋 Chupa chups biscuit brownie gummi sugar plum caramels.',
      'Info!',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Warning
  toastrWarning() {
    this.toastr.warning(
      '👋 Icing cake pudding carrot cake jujubes tiramisu chocolate cake.',
      'Warning!',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Error
  toastrError() {
    this.toastr.error(
      '👋 Jelly-o marshmallow marshmallow cotton candy dessert candy.',
      'Danger!',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Top Left
  toastrTopLeft() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Top Left!',
      {
        positionClass: 'toast-top-left',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Top Center
  toastrTopCenter() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Top Center!',
      {
        positionClass: 'toast-top-center',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Top Right
  toastrTopRight() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Top Right!',
      {
        positionClass: 'toast-top-right',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Top Fullwidth
  toastrTopFullWidth() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Top Full Width!',
      {
        positionClass: 'toast-top-full-width',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Bottom Left
  toastrBottomLeft() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Bottom Left!',
      {
        positionClass: 'toast-bottom-left',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Bottom Center
  toastrBottomCenter() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Bottom Center!',
      {
        positionClass: 'toast-bottom-center',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Bottom Right
  toastrBottomRight() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Bottom Right!',
      {
        positionClass: 'toast-bottom-right',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Bottom Fullwidth
  toastrBottomFullWidth() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Bottom Full Width!',
      {
        positionClass: 'toast-bottom-full-width',
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Progress Bar
  toastrProgressBar() {
    this.toastr.success('Have fun storming the castle!', 'Progress Bar', {
      progressBar: true,
      toastClass: 'toast ngx-toastr',
      closeButton: true,
      timeOut: 5000000,
    });
  }

  // Clear Toast Button
  toastrClearToastButton() {
    this.toastr.info(
      'Clear itself?<br><br><span class="btn btn-info clear">Yes</span>',
      'Clear Toast Button',
      {
        enableHtml: true,
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Rshow
  toastrRShow() {
    this.toastRef = this.toastr.info(
      'We do have the Kapua suite available.',
      'Turtle Bay Resort',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Remove
  toastrRemove() {
    this.toastr.remove(this.toastRef.toastId);
  }

  // Cshow
  toastrCShow() {
    this.toastr.info(
      'We do have the Kapua suite available.',
      'Turtle Bay Resort',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Clear
  toastrClear() {
    this.toastr.clear();
  }

  // Ease Time
  toastrEaseTime() {
    this.toastr.success('Have fun storming the castle!', 'ease In 1 Sec', {
      easeTime: 1000,
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });
  }

  // Timeout 3sec
  toastrTimeout3() {
    this.toastr.warning('Have fun storming the castle!', 'timeOut 3 Sec', {
      timeOut: 3000,
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });
  }

  // Timeout 5sec
  toastrTimeout5() {
    this.toastr.error(
      'I do not think that word means what you think it means.',
      'Timeout 5 Sec!',
      {
        timeOut: 5000,
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }

  // Stickey
  toastrStickey() {
    this.toastr.info(
      'I do not think that word means what you think it means.',
      'Sticky!',
      {
        timeOut: 0,
        toastClass: 'toast ngx-toastr',
        closeButton: true,
      }
    );
  }
}
