import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  combineLatest,
  forkJoin,
} from 'rxjs';
import { switchMap, take, tap, catchError, map } from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { findByProp } from '../utils';
import { NyelvekService } from './nyelvek.service';
import { PenznemekService } from './penznemek.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class OrszagokService {
  private readonly apiEndpoint = 'orszagok';
  private readonly columnsEndpoint = 'orszagok/columns';
  private readonly orszagokUpdateEndpoint = 'service/frissiteslib/orszagok_frissites'

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  private orszagok = JSON.parse(localStorage.getItem('orszagok'));
  private lastFetch: Date = new Date();

  public data$ = combineLatest([
    this._data,
    this.nyelvekService.data$,
    this.penznemekService.data$,
  ]).pipe(
    switchMap(([state, nyelvek, penznemek]) => {
      return state ? of(this.mapOrszagok(state, nyelvek, penznemek)) : this.orszagok?.db > 0 ? this.getDBData() : this.fetchData()
    })
  );

  // public data$ = combineLatest([
  //   this._data,
  //   this.nyelvekService.data$,
  //   this.penznemekService.data$,
  // ]).pipe(
  //   switchMap(([state, nyelvek, penznemek]) =>
  //     state ? of(this.mapOrszagok(state, nyelvek, penznemek)) : this.fetchData()
  //   )
  // );

  constructor(
    private nyelvekService: NyelvekService,
    private penznemekService: PenznemekService,
    private api: ApiService,
    private stateService: StateService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.nyelvekService.initData$,
              this.penznemekService.initData$,
            ]).pipe(
              map(([orszagok, nyelvek, penznemek]) =>
                this.mapOrszagok(orszagok, nyelvek, penznemek)
              )
            )
          : JSON.parse(localStorage.getItem('orszagok'))?.db > 0 ? this.getDBData() : this.fetchData()
      )
    );
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.nyelvekService.initData$,
  //             this.penznemekService.initData$,
  //           ]).pipe(
  //             map(([orszagok, nyelvek, penznemek]) =>
  //               this.mapOrszagok(orszagok, nyelvek, penznemek)
  //             )
  //           )
  //         : this.fetchData()
  //     )
  //   );
  // }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, data).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('orszagok', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: any) => {
        this.stateService.setStateOnDelete(deleted, this._data);
        //this.dbService.deleteByKey('orszagok', deleted.id).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  update(data: any): Observable<any> {
    return this.api.put(`${this.apiEndpoint}/${data.id}`, data).pipe(
      tap((updated: any) => {
        this.stateService.setStateOnUpdate(updated, this._data);
        //this.dbService.update('orszagok', updated).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.nyelvekService.initData$,
      this.penznemekService.initData$,
    ]).pipe(
      map(([orszagok, nyelvek, penznemek]) => {
        this.dbService.clear('orszagok').subscribe();
            this.dbService
              .bulkAdd('orszagok', this.mapOrszagok(orszagok, nyelvek, penznemek))
              .subscribe((result) => {
                localStorage.setItem('orszagok', JSON.stringify({db: result.length, lastFetch: this.lastFetch}));
              }, error => console.log(error));
        return this.mapOrszagok(orszagok, nyelvek, penznemek)
      })
    );
  }

  // private fetchData(): Observable<any[]> {
  //   this.lastFetch = new Date();
  //   return forkJoin([
  //     this.api.get(this.apiEndpoint).pipe(
  //       tap((data: any[]) => this._data.next(data)),
  //       catchError((err: any) => {
  //         console.log(err);
  //         return of([]);
  //       })
  //     ),
  //     this.nyelvekService.initData$,
  //     this.penznemekService.initData$,
  //   ]).pipe(
  //     map(([orszagok, nyelvek, penznemek]) => {
  //       // this.dbService.count('orszagok').subscribe(count => {
  //       //   if(count == 0) {
  //       //     this.dbService
  //       //       .bulkAdd('orszagok', this.mapOrszagok(orszagok, nyelvek, penznemek))
  //       //       .subscribe((result) => {
              
  //       //       }, error => console.log(error));
  //       //   }
  //       //   localStorage.setItem('orszagok', JSON.stringify({db: orszagok.length, lastFetch: this.lastFetch}));
  //       //   //this.cikkek = count;
  //       // })
  //       localStorage.setItem('orszagok', JSON.stringify({db: orszagok.length, lastFetch: this.lastFetch}));

  //       return this.mapOrszagok(orszagok, nyelvek, penznemek)
  //     })
  //   );
  // }

  getDBData(): Observable<any> {
    console.log('get orszagok from indexedDB')
    return forkJoin([
      this.dbService.getAll('orszagok').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.nyelvekService.initData$,
      this.penznemekService.initData$,
    ]).pipe(
      map(([orszagok, nyelvek, penznemek]) => {
        return this.mapOrszagok(orszagok, nyelvek, penznemek)
      })
    );
  }

  getOrszagokChange(): any {
    this.lastFetch = new Date();
    const last = JSON.parse(localStorage.getItem('orszagok'))?.lastFetch;
    this.api.get(`${this.orszagokUpdateEndpoint}/${last}`).pipe(
        take(1),
        //tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
    )
    .subscribe((resp: any) => {
      if(resp) {
        if(this._data.value) {
          if(resp.valtozas.length > 0) {
            resp.valtozas.forEach(orszag => {
                    const biz = findByProp(this._data.value, 'id', orszag.id);
                    if(biz) {
                      this.stateService.setStateOnUpdate(orszag, this._data);
                      this.dbService.update('orszagok', orszag).subscribe();
                    } else {
                      this.stateService.setStateOnAdd(orszag, this._data);
                      this.dbService.add('orszagok', orszag).subscribe();
                    }
              })       
          };
          if(resp.torolt_lista.length > 0) {
            resp.torolt_lista.forEach(orszag => {
              const orsz = findByProp(this._data.value, 'id', orszag.modul_id);
                  if(orsz) {
                    this.stateService.setStateOnDelete(orsz ,this._data);
                    this.dbService.deleteByKey('orszagok', orszag.modul_id).subscribe();
                  }
            })
          }
        }
        this.dbService.count('orszagok').subscribe(count => {
          localStorage.setItem('orszagok', JSON.stringify({db: count, lastFetch: this.lastFetch}))
        });

        }
      }
    );
  }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private mapOrszagok(
    orszagok: any[],
    nyelvek: any[],
    penznemek: any[]
  ): any[] {
    return orszagok.map((e: any) => ({
      ...e,
      nyelv: findByProp(nyelvek, 'id', e.nyelv_id)?.megnevezes,
      penznem: findByProp(penznemek, 'id', e.penznem_id)?.megnevezes,
    }));
  }
}
