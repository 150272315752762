<!-- Calendar Add/Update/Delete event modal-->
<div>
  <div class="modal-content p-0">
    <div class="modal-header mb-1">
      <h5 class="modal-title">
        {{ editMode ? 'Esemény módosítása' : 'Új esemény' }}
      </h5>

      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        ×
      </button>
    </div>

    <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
      <form #eventForm="ngForm" class="event-form needs-validation">
        <div class="form-group">
          <label for="cim" class="form-label">Cím</label>
          <input
            type="text"
            class="form-control"
            id="cim"
            name="cim"
            [(ngModel)]="event.title"
            [requiredMask]="true"
            required
          />
        </div>

        <div class="form-group">
          <label for="select-label" class="form-label">Kategória</label>
          <ng-select
            [items]="kategoriak$ | async"
            name="selectlabel"
            class="w-100"
            bindLabel="megnevezes"
            bindValue="id"
            [(ngModel)]="event.naptar_kategoria_id"
            [requiredMask]="true"
            required
          >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <span
                class="bullet bullet-sm n mr-50 custom-bullet-{{ item.id }}"
              ></span>
              {{ item.megnevezes | titlecase }}
            </ng-template>
          </ng-select>
        </div>

        <div class="form-group position-relative">
          <label for="start-date" class="form-label">Esemény kezdete</label>
          <ng2-flatpickr
            #startDatePicker
            [config]="dateOptions"
            name="startDate"
            [setDate]="event.start"
            [requiredMask]="true"
            required
          ></ng2-flatpickr>
        </div>

        <div class="form-group position-relative">
          <label for="end-date" class="form-label">Esemény vége</label>
          <ng2-flatpickr
            #endDatePicker
            [config]="dateOptions"
            name="endDate"
            [setDate]="event.end"
          ></ng2-flatpickr>
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input allDay-switch"
              id="customSwitch3"
              [(ngModel)]="event.allDay"
              name="allDay"
            />
            <label class="custom-control-label" for="customSwitch3"
              >Egész napos</label
            >
          </div>
        </div>

        <div class="form-group position-relative">
          <label for="task-assigned" class="form-label d-block">Dolgozó</label>
          <ng-select
            [items]="dolgozok$ | async"
            name="dolgozo"
            class="w-100"
            [clearable]="false"
            bindValue="id"
            [(ngModel)]="event.dolgozo_id"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.vezeteknev }} {{ item.keresztnev }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div class="media align-items-center">
                <div class="media-body">
                  <p class="mb-0">
                    {{ item.vezeteknev }} {{ item.keresztnev }}
                  </p>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>

        <div class="form-group">
          <label for="link" class="form-label">Link</label>
          <input
            type="text"
            class="form-control"
            id="link"
            name="link"
            [(ngModel)]="event.link"
          />
        </div>

        <div class="form-group">
          <label for="helyszin" class="form-label">Helyszín</label>
          <input
            type="text"
            class="form-control"
            id="helyszin"
            name="helyszin"
            [(ngModel)]="event.helyszin"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Leírás</label>
          <quill-editor
            name="addDescription"
            customToolbarPosition="'bottom'"
            class="border-bottom-0"
            placeholder=" "
            [(ngModel)]="event.leiras"
          >
            <div quill-editor-toolbar>
              <div class="d-flex justify-content-end desc-toolbar border-top-0">
                <span class="ql-formats mr-0">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-align"></button>
                  <button class="ql-link"></button>
                </span>
              </div>
            </div>
          </quill-editor>
        </div>

        <div class="form-group d-flex">
          <button
            *ngIf="!editMode; else addEventRef"
            type="button"
            class="btn btn-primary add-event-btn mr-1"
            (click)="addEvent()"
            rippleEffect
          >
            Mentés
          </button>

          <button
            *ngIf="!editMode; else deleteEventRef"
            rippleEffect
            type="button"
            class="btn btn-outline-secondary btn-cancel"
            data-dismiss="modal"
            (click)="toggleEventSidebar()"
          >
            Mégse
          </button>

          <ng-template #addEventRef>
            <button
              rippleEffect
              type="button"
              class="btn btn-primary update-event-btn mr-1"
              (click)="updateEvent()"
            >
              Módosítás
            </button>
          </ng-template>

          <ng-template #deleteEventRef>
            <button
              rippleEffect
              class="btn btn-outline-danger btn-delete-event"
              (click)="deleteEvent()"
            >
              Törlés
            </button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>
<!--/ Calendar Add/Update/Delete event modal-->
