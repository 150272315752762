import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ApiService } from "@core/services/api.service";
import { environment } from "environments/environment";
import moment from "moment";
import { BehaviorSubject, Observable, forkJoin, of } from "rxjs";
import {
  catchError,
  map,
  skip,
  subscribeOn,
  take,
  takeUntil,
} from "rxjs/operators";
import {
  BizonylatKereses,
  Cikktorzs,
  PenzugyiBizonylatTetel,
} from "../../models/torzsek";
import * as _ from "lodash";
import { BizonylatKeresesTipus } from "../../components/torzsek-basic-table/toolbars/bizonylatok-toolbar/bizonylat-torzsek";
import { ArkategoriakService } from "../../services/arkategoriak.service";
import { findByProp } from "../../utils";
import { AjanlatService } from "../../services/ajanlat.service";
import { SzallitolevelService } from "../../services/szallitolevel.service";
import { KulsoRaktariEladasService } from "../../services/kulso-raktari-eladas.service";
import { ElolegszamlaService } from "../../services/elolegszamla.service";
import { FoglalasService } from "../../services/foglalas.service";
import { DijbekeroService } from "../../services/dijbekero.service";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { CikktorzsService } from "../../services/cikktorzs.service";

interface SelectedBizonylat {
  readonly [bizonylatId: string]: number;
}

@Injectable()
export class DijbekeroElolegKimenoHelperService {
  public formGroup: FormGroup;
  private readonly BIZONYLAT_ID_ENDPOINT =
    "service/rendeles/bizonylatkeresoidra";

  // Kiválasztott bizonylat id és tétel mennyiség típus szerint
  selectedBizonylatTetelek: {
    a: SelectedBizonylat;
    d: SelectedBizonylat;
    e: SelectedBizonylat;
    f: SelectedBizonylat;
    sz: SelectedBizonylat;
    kr: SelectedBizonylat;
  } = { a: {}, d: {}, e: {}, f: {}, sz: {}, kr: {} };

  // Kiválasztott bizonylatok száma típus szerint
  selectedBizonylatok: {
    a: number;
    d: number;
    e: number;
    sz: number;
    kr: number;
  } = { a: 0, d: 0, e: 0, sz: 0, kr: 0 };

  private arkategoriak: any[];

  private readonly _selectedBizonylatTipus =
    new BehaviorSubject<BizonylatKeresesTipus>(null);
  private readonly _bizonylatok = new BehaviorSubject<any[]>([]);

  private readonly = new BehaviorSubject<any[]>([]);

  get bizonylatok$(): Observable<any[]> {
    return this._bizonylatok.asObservable();
  }

  set selectedBizonylatTipus(tipus: BizonylatKeresesTipus) {
    this._selectedBizonylatTipus.next(tipus);
  }

  constructor(
    private api: ApiService,
    private cikkekService: CikktorzsService,
    private arkategoriakService: ArkategoriakService
  ) {}

  fetchData(data: BizonylatKereses): void {
    this.getBizonylatok(data)
      .pipe(
        // FIXME: Backend FIX kell
        map((bizonylatok: any[]) => bizonylatok) //bizonylatok.filter((b: any) => b?.statusz))
      )
      .subscribe((bizonylatok: any[]) => {
        this._bizonylatok.next(bizonylatok);
        this._selectedBizonylatTipus.next(data.tipus);

        this.updateSelectedState();
      });
  }

  // fetchData(data: any): void {
  //   this.arkategoriakService.initData$.subscribe(resp => {
  //     this.arkategoriak = resp;
  //   });

  //   let serviceData: any;

  //   switch(data.tipus) {
  //     case 'a':
  //         this.ajanlatService.getBizonylatValtozas().subscribe();
  //         serviceData = this.ajanlatService.data$;
  //         break;
  //     case 'sz':
  //         this.szallitoService.getBizonylatValtozas().subscribe();
  //         serviceData = this.szallitoService.data$;
  //         break;
  //     case 'kr':
  //         this.kulsoraktariService.getBizonylatValtozas().subscribe();
  //         serviceData = this.kulsoraktariService.data$;
  //         break;
  //     case 'e':
  //         this.elolegszamlaService.getBizonylatValtozas().subscribe();
  //         serviceData = this.elolegszamlaService.data$;
  //         break;
  //     case 'f':
  //         this.foglalasService.getBizonylatValtozas().subscribe();
  //         serviceData = this.foglalasService.data$;
  //         break;
  //     case 'd':
  //         this.dijbekeroService.getBizonylatValtozas().subscribe();
  //         serviceData = this.dijbekeroService.data$;
  //         break;
  //   }

  //     //this.getBizonylatok(data)
  //     serviceData.pipe(
  //       // FIXME: Backend FIX kell
  //       map((bizonylatok: any[]) =>  {
  //         if(data.tipus == 'sz') {
  //           bizonylatok.forEach((bizonylat, index) => {
  //             let newTetelek = []

  //             bizonylat?.tetelek?.forEach(tetel => {
  //               const index = newTetelek.findIndex(item => item.cikk_id == tetel.cikk_id && item.netto_egysegar == tetel.netto_egysegar);
  //               const elem = newTetelek.find(item => item.cikk_id == tetel.cikk_id && item.netto_egysegar == tetel.netto_egysegar);

  //               if(index == -1) {
  //                 newTetelek.push({
  //                   ...tetel
  //                 })
  //               } else if(elem) {
  //                 newTetelek[index] = {
  //                   ...elem,
  //                   mennyiseg: elem.mennyiseg + tetel.mennyiseg,
  //                   netto_ar: elem.netto_ar + tetel.netto_ar,
  //                   afa_ar: elem.afa_ar + tetel.afa_ar,
  //                   brutto_ar: elem.brutto_ar + tetel.brutto_ar,
  //                   kiszamlazva: elem.kiszamlazva + tetel.kiszamlazva
  //                 }
  //               }
  //             });

  //             bizonylatok[index] = {
  //               ...bizonylat,
  //               tetelek: newTetelek.filter(tetel => tetel.mennyiseg > 0),
  //               kiszolgalva: newTetelek.filter(tetel => {
  //                 if(tetel.kiszamlazva > 0 && tetel.mennyiseg > tetel.kiszamlazva) {
  //                   return true;
  //                 } else {
  //                   return false;
  //                 }
  //               }).length > 0
  //             };
  //           });
  //           return bizonylatok.filter((b: any) => {
  //             return b?.tetelek.filter(tetel => {
  //               return tetel.mennyiseg.toFixed(2) != tetel.kiszamlazva.toFixed(2)
  //             }).length > 0 && b.statusz == 1 && this.betweenDates(data.kezdete, data.vege, b) && (data.partner_id ? b.partner_id == data.partner_id : true);
  //           });
  //         } else {
  //           return bizonylatok.filter((b: any) => {
  //             return b?.tetelek.filter(tetel => {
  //               return tetel.mennyiseg.toFixed(2) != tetel.kiszamlazva.toFixed(2)
  //             }).length > 0 && b.statusz == 1 && this.betweenDates(data.kezdete, data.vege, b) && (data.partner_id ? b.partner_id == data.partner_id : true)
  //           })
  //           //return bizonylatok.filter((b: any) => b?.tetelek)
  //         }
  //       })
  //     )
  //     .subscribe((bizonylatok: any[]) => {
  //       this._bizonylatok.next(
  //         bizonylatok.filter(e => e.bizonylat_szam != null && e.tetelek.filter(tetel => (tetel.mennyiseg > tetel.kiszamlazva)).length > 0).map(e => {
  //           return {
  //             ...e,
  //             nev: e.partner,
  //             tetelek: e.tetelek.map((t) => {return {...t, arkategoria: findByProp(this.arkategoriak, 'id', t.arkategoria_id)?.nev}})
  //           }
  //         })
  //       );
  //       this._selectedBizonylatTipus.next(data.tipus);

  //       this.updateSelectedState();
  //     });

  // }

  updateSelectedState() {
    const tipus = this._selectedBizonylatTipus.value;

    if (tipus) {
      this.selectedBizonylatTetelek[tipus] = this.groupTetelek(
        this.formGroup?.get("tetelek")?.value,
        tipus
      );
    }
  }

  vanKivalasztottBizonylat(): boolean {
    let sum = 0;

    for (let prop in this.selectedBizonylatok) {
      if (prop !== "null") {
        sum += this.selectedBizonylatok[prop];
      }
    }

    return sum > 0;
  }

  calcSum(
    tetelek: any[],
    penznem: any,
    fizetesi_mod: any,
    kimenoSzamla?: boolean // Kimenő számla esetén az előlegszámlás tételeket nem számoljuk bele a SUM-ba
  ): {
    netto_ar: number;
    brutto_ar: number;
    afa_ar: number;
  } {
    let netto_ar = 0;
    let brutto_ar = 0;
    let afa_ar = 0;

    if (Array.isArray(tetelek)) {
      tetelek.forEach((t: any) => {
        // Hozzáadás
        if (!kimenoSzamla || (kimenoSzamla && !t.elolegszamla_id)) {
          netto_ar += t.netto_ar;
          brutto_ar += t.brutto_ar;
          afa_ar += t.afa_ar;
        }
      });
    }

    const netto = Math.round(netto_ar * 100) / 100;
    const afa = Math.round(afa_ar * 100) / 100;
    const brutto = Math.round(brutto_ar * 100) / 100;

    if (penznem == 1 && fizetesi_mod == 8) {
      return {
        netto_ar: this.checkIfZero(netto, brutto) ? 0 : netto,
        brutto_ar: this.checkIfZero(netto, brutto)
          ? 0
          : this.roundNearest5(brutto),
        afa_ar: this.checkIfZero(netto, brutto) ? 0 : afa,
      };
    } else {
      return {
        netto_ar: this.checkIfZero(netto, brutto) ? 0 : netto,
        brutto_ar: this.checkIfZero(netto, brutto) ? 0 : brutto,
        afa_ar: this.checkIfZero(netto, brutto) ? 0 : afa,
      };
    }
  }

  calcSumHelyesbites(
    tetelek: any[],
    penznem: any,
    fizetesi_mod: any,
    kimenoSzamla?: boolean // Kimenő számla esetén az előlegszámlás tételeket nem számoljuk bele a SUM-ba
  ): {
    netto_ar_hely: number;
    brutto_ar_hely: number;
    afa_ar_hely: number;
  } {
    let netto_ar = 0;
    let brutto_ar = 0;
    let afa_ar = 0;

    if (Array.isArray(tetelek)) {
      tetelek.forEach((t: any) => {
        // Hozzáadás
        if (t.cikkszam != 'vev001') {
          netto_ar += t.netto_ar;
          brutto_ar += t.brutto_ar;
          afa_ar += t.afa_ar;
        }
      });
    }

    const netto = Math.round(netto_ar * 100) / 100;
    const afa = Math.round(afa_ar * 100) / 100;
    const brutto = Math.round(brutto_ar * 100) / 100;

    if (penznem == 1 && fizetesi_mod == 8) {
      return {
        netto_ar_hely: this.checkIfZero(netto, brutto) ? 0 : netto,
        brutto_ar_hely: this.checkIfZero(netto, brutto)
          ? 0
          : this.roundNearest5(brutto),
        afa_ar_hely: this.checkIfZero(netto, brutto) ? 0 : afa,
      };
    } else {
      return {
        netto_ar_hely: this.checkIfZero(netto, brutto) ? 0 : netto,
        brutto_ar_hely: this.checkIfZero(netto, brutto) ? 0 : brutto,
        afa_ar_hely: this.checkIfZero(netto, brutto) ? 0 : afa,
      };
    }
  }

  checkIfZero(netto: number, brutto: number): boolean {
    return netto == 0 || brutto == 0;
  }

  roundNearest5(num) {
    return Math.round(num / 5) * 5;
  }

  mapTetelekWithReferenceId(
    tetelek: PenzugyiBizonylatTetel[],
    id: number,
    propName: BizonylatKeresesTipus,
    penznem_id?: any,
    bizonylat_szam?: any
  ): PenzugyiBizonylatTetel[] {
    // Return list compatible tételek
    return tetelek.map((t: any) => {
      const maradek = t?.mennyiseg - (t?.kiszamlazva || 0);
      const kedvezmeny = t.kedvezmeny ? 1 - t.kedvezmeny / 100 : 1;

      const netto =
        penznem_id == 1
          ? Math.round(t.netto_egysegar * maradek * kedvezmeny)
          : t.netto_egysegar * maradek * kedvezmeny;

      const afa =
        penznem_id == 1
          ? Math.round(netto * (t.afakulcs / 100))
          : netto * (t.afakulcs / 100);

      //Ha a tétel a Vevőtől kapott előleg akkor más a visszatérés
      return t.cikk_id == 1 ? 
      {
        ...t,
        cikknev: t.cikknev + ' ' + bizonylat_szam,
        mennyiseg: 1,
        netto_egysegar: -netto,
        brutto_egysegar: -(netto + afa),
        netto_ar: -netto,
        afa_ar: -afa,
        brutto_ar: -(netto + afa),
        [this.getPropNameByTipus(propName)]: id,
      } : 
      {
        ...t,
        mennyiseg: t?.mennyiseg - (t?.kiszamlazva || 0),
        netto_ar: netto,
        afa_ar: afa,
        brutto_ar: netto + afa,
        [this.getPropNameByTipus(propName)]: id,
      };
    });
  }

  getPropNameByTipus(
    tipus: BizonylatKeresesTipus
  ):
    | "ajanlat_id"
    | "dijbekero_id"
    | "elolegszamla_id"
    | "foglalas_id"
    | "szallitolevel_id"
    | "kulsoraktarieladas_id" {
    switch (tipus) {
      case "a": {
        return "ajanlat_id";
      }

      case "d": {
        return "dijbekero_id";
      }

      case "e": {
        return "elolegszamla_id";
      }

      case "f": {
        return "foglalas_id";
      }

      case "sz": {
        return "szallitolevel_id";
      }

      case "kr": {
        return "kulsoraktarieladas_id";
      }

      default: {
        return null;
      }
    }
  }

  groupTetelek(
    tetelek: PenzugyiBizonylatTetel[],
    tipus: BizonylatKeresesTipus
  ): any {
    const propName = this.getPropNameByTipus(tipus);

    let grouped = _.groupBy(tetelek, propName);
    let result: any = {};

    // Map object's array value to array.length value
    Object.keys(grouped)
      .filter((key: string) => key !== "undefined" && key !== "null")
      .forEach((key: string) => {
        result[key] = (grouped[key] as PenzugyiBizonylatTetel[]).reduce(
          (sum, t) => sum + t.mennyiseg,
          0
        );
      });

    // Update Bizonylat selection
    this.selectedBizonylatok[tipus] = Object.keys(result)?.length;

    // console.log('Grouped', result);

    return result;
  }

  filterCikkekByMennyiseg(cikkek: Cikktorzs[]): Cikktorzs[] {
    return cikkek.filter((c: Cikktorzs) => c.aktualis_mennyiseg > 0);
  }

  private getBizonylatok(data: any): Observable<any[]> {
    return this.api
      .get(
        `${environment.bizonylatkereso}/${data.tipus}/${
          data.partner_id
        }/${moment(data.kezdete).format("YYYY-MM-DD")}/${moment(
          data.vege
        ).format("YYYY-MM-DD")}`
      )
      .pipe(
        catchError((err) => {
          console.log("Bizonylatkereső error", err);

          return of([]);
        })
      );
  }

  calcMennyisegByCikk(tetelek: any[]): any {
    let maradek = [];

    tetelek.forEach((tetel) => {
      const osszesen = tetelek
        .filter(
          (item) =>
            item.cikk_id === tetel.cikk_id &&
            !item.szallitolevel_id &&
            !item.foglalas_id
        )
        .reduce((sum, current) => sum + current.mennyiseg, 0);
      const eredetiOsszesen = tetelek
        .filter(
          (item) => item.cikk_id === tetel.cikk_id && item.szallitolevel_id
        )
        .reduce((sum, current) => sum + current.mennyiseg, 0);

      if (maradek.findIndex((item) => item.cikk_id == tetel.cikk_id) == -1) {
        maradek.push({
          cikk_id: tetel.cikk_id,
          osszesen: osszesen,
          eredetiOsszesen: eredetiOsszesen,
        });
      }
    });

    return maradek;
  }

  betweenDates(startDate: Date, endDate: Date, bizonylat: any) {
    const bizDate = new Date(bizonylat.letrehozva);
    const startDateConst = new Date(startDate);
    const endDateConst = new Date(endDate);

    if (bizDate && startDateConst && endDateConst) {
      if (bizDate >= startDateConst && bizDate <= endDateConst) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  fetchBizonylatById(tipus: string, id: number): Observable<any> {
    return forkJoin([
      this.api.get(`${this.BIZONYLAT_ID_ENDPOINT}/${tipus}/${id}`).pipe(
        take(1),
        catchError((err) => {
          console.log("Bizonylatkereső error", err);

          return of([]);
        })
      ),
      this.cikkekService.initData$,
      this.arkategoriakService.initData$,
    ]).pipe(
      map(([bizonylat, cikkek, arkategoriak]) => {
        return {
          ...bizonylat,
          tetelek: bizonylat.tetelek.map((tetel) => {
            return {
              ...tetel,
              cikknev: findByProp(cikkek, "id", tetel.cikk_id)?.cikknev,
              arkategoria: findByProp(arkategoriak, "id", tetel.arkategoria_id)
                ?.nev,
            };
          }),
        };
      })
    );
  }

  createElolegSzamlaSor(row: any, netto_ar: number, afa_ar: number, brutto_ar: number): any {
    return {
      ...row,
      id: null,
      //elolegszamla_id: null,
      cikk_id: 1,
      cikkszam: "vev001",
      cikknev: "Vevőtől kapott előleg ",
      mennyiseg: 1,
      mennyisegi_egyseg: 2,
      mennyisegi_egyseg_text: "db",
      me_text: "db / dob / db",
      me_id: 2,
      me_id_2: 36,
      me_id_3: 2,
      brutto_egysegar: brutto_ar * (-1),
      netto_egysegar: netto_ar * (-1),
      netto_ar: netto_ar * (-1),
      afa_ar: afa_ar * (-1),
      afa_egysegar: afa_ar * (-1),
      brutto_ar: brutto_ar * (-1),
    };
  }
}
