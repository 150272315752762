import { Directive, ElementRef, Host, HostListener } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[overflowTooltip]',
})
export class OverflowTooltipDirective {
  constructor(@Host() private tooltip: NgbTooltip, private elRef: ElementRef) {}

  @HostListener('mouseenter') mouseEnter() {
    this.tooltip.disableTooltip = !this.isOverflows;
  }

  private get isOverflows(): boolean {
    const element = this.elRef.nativeElement;

    return element.offsetWidth < element.scrollWidth;
  }
}
