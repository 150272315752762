import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { BehaviorSubject, of, Observable, throwError, forkJoin } from 'rxjs';
import { switchMap, take, tap, catchError, map, pluck } from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { findByProp, isJSONString, parseCim } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class OnelszamoloEgysegekService {
  private readonly oeApiEndpoint = 'onelszamoloegysegek';
  private readonly oeColumnsEndpoint = 'onelszamoloegysegek/columns';
  private readonly raktarApiEndpoint = 'raktarak';

  private readonly _data = new BehaviorSubject<{ oek: any[]; raktarak: any[] }>(
    null
  );
  private readonly _selectedData = new BehaviorSubject<any>(null);

  public allData$ = this._data.asObservable();

  constructor(private api: ApiService) {}

  get data$(): Observable<any[]> {
    return this.allData$.pipe(pluck('oek'));
  }

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initDataAll$(): Observable<{ oek: any[]; raktarak: any[] }> {
    return this._data.pipe(
      take(1),
      switchMap((state: { oek: any[]; raktarak: any[] }) =>
        state
          ? of(state)
          : forkJoin([this.fetchData(), this.fetchRaktarak()]).pipe(
              map(([oek, raktarak]) => this.mapOekRaktarak(oek, raktarak)),
              tap((data: { oek: any[]; raktarak: any[] }) =>
                this._data.next(data)
              )
            )
      )
    );
  }

  get initData$(): Observable<any[]> {
    return this.initDataAll$.pipe(pluck('oek'));
  }

  add(data: any): Observable<any> {
    return this.api.post(this.oeApiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => this.setStateOnAdd(added, this._data, 'oek')),
      catchError((err: any) => throwError(err))
    );
  }

  addRaktar(data: any): Observable<any[]> {
    return this.api.post(this.raktarApiEndpoint, data).pipe(
      tap((added: any) => this.setStateOnAdd(added, this._data, 'raktarak')),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.oeApiEndpoint, id).pipe(
      tap((deleted: any) => this.setStateOnDelete(deleted, this._data, 'oek')),
      catchError((err: any) => throwError(err))
    );
  }

  deleteRaktar(id: number): Observable<any> {
    return this.api.delete(this.raktarApiEndpoint, id).pipe(
      tap((deleted: any) =>
        this.setStateOnDelete(deleted, this._data, 'raktarak')
      ),
      catchError((err: any) => throwError(err))
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.oeApiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) =>
          this.setStateOnUpdate(updated, this._data, 'oek')
        ),
        catchError((err: any) => throwError(err))
      );
  }

  updateRaktar(data: any): Observable<any> {
    return this.api.put(`${this.raktarApiEndpoint}/${data.id}`, data).pipe(
      tap((updated: any) =>
        this.setStateOnUpdate(updated, this._data, 'raktarak')
      ),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  private fetchData(): Observable<any[]> {
    return this.api.get(this.oeApiEndpoint).pipe(
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private fetchRaktarak(): Observable<any[]> {
    return this.api.get(this.raktarApiEndpoint).pipe(
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.oeColumnsEndpoint).pipe(
      map((c: Column[]) => {
        const columns = [...c, { name: 'szamlazasiCim', nullable: true }];

        return columns.map((c: Column) =>
          c.name === 'cim' ? { ...c, hidden: true } : c
        );
      }),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private mapOekRaktarak(
    oek: any[],
    raktarak: any[]
  ): { oek: any[]; raktarak: any[] } {
    const oekMap: any[] = oek.map((oe: any) => {
      let cim = oe.cim;

      if (typeof oe.cim !== 'object') {
        cim = JSON.parse(oe.cim);
      }

      const szamlazasiCim = parseCim(cim);

      return {
        ...oe,
        nyitvatartas: isJSONString(oe.nyitvatartas)
          ? JSON.parse(oe.nyitvatartas)
          : oe.nyitvatartas,
        cim,
        szamlazasiCim,
        alapertelmezett_raktar: findByProp(
          raktarak,
          'id',
          oe.alapertelmezett_raktar_id
        )?.megnevezes,
        raktarak: raktarak.filter((r: any) => oe.id === 4 || r.oe_id === oe.id),
      };
    });

    const raktarakMap: any[] = raktarak.map((r: any) => ({
      ...r,
      oe: findByProp(oekMap, 'id', r.oe_id)?.megnevezes,
    }));

    return {
      oek: oekMap,
      raktarak: raktarakMap,
    };
  }

  private setStateOnAdd(
    data: any,
    container: BehaviorSubject<{ oek: any[]; raktarak: any[] }>,
    prop: 'oek' | 'raktarak'
  ): void {
    if (data) {
      // Jelenlegi tömb
      const { oek, raktarak } = container.value;

      let uOek = oek.slice();
      let uRaktarak = raktarak.slice();

      // Öe
      if (prop === 'oek') {
        uOek = this.mapOekRaktarak([data, ...oek], raktarak).oek;
      }
      // Raktár
      else {
        uRaktarak = this.mapOekRaktarak(oek, [data, ...raktarak]).raktarak;
      }

      // Update BehaviorSubject
      container.next({ oek: uOek, raktarak: uRaktarak });

      // Log
      // console.log('Add in state: ', { oek: uOek, raktarak: uRaktarak });
    }
    // Error
    else {
      console.log('Nincs adat in: "SetStateOnAdd()"');
    }
  }

  private setStateOnUpdate(
    data: any,
    container: BehaviorSubject<{ oek: any[]; raktarak: any[] }>,
    prop: 'oek' | 'raktarak'
  ): void {
    if (data) {
      //   Jelenlegi tömb
      const { oek, raktarak } = container.value;

      let uOek = [];
      let uRaktarak = [];

      // Öe
      if (prop === 'oek') {
        const index = oek.findIndex((element: any) => element.id === data.id);
        const newData = oek.slice();
        newData[index] = data;

        uOek = this.mapOekRaktarak(newData, raktarak).oek;
        uRaktarak = this.mapOekRaktarak(uOek, raktarak).raktarak;
      }
      // Raktár
      else {
        const index = raktarak.findIndex(
          (element: any) => element.id === data.id
        );
        const newData = raktarak.slice();
        newData[index] = data;

        uRaktarak = this.mapOekRaktarak(oek, newData).raktarak;
        uOek = this.mapOekRaktarak(oek, uRaktarak).oek;
      }

      //   const newState = { oek: uOek, raktarak: uRaktarak };

      // Update BehaviorSubject
      container.next({ oek: uOek, raktarak: uRaktarak });

      // Log
      //   console.log('Update in state: ', { oek: uOek, raktarak: uRaktarak });
    }
    // Error
    else {
      console.log('Nincs adat in: "SetStateOnUpdate()"');
    }
  }

  private setStateOnDelete(
    data: any,
    container: BehaviorSubject<{ oek: any[]; raktarak: any[] }>,
    prop: 'oek' | 'raktarak'
  ): void {
    if (data) {
      // Jelenlegi tömb
      const { oek, raktarak } = container.value;

      let uOek = oek.slice();
      let uRaktarak = raktarak.slice();

      // Öe
      if (prop === 'oek') {
        uOek = oek.filter((element: any) => element.id !== data.id);
      }
      // Raktár
      else {
        uRaktarak = raktarak.filter((element: any) => element.id !== data.id);
      }

      // Update BehaviorSubject
      container.next({ oek: uOek, raktarak: uRaktarak });
    }
    // Error
    else {
      console.log('Nincs adat in: "SetStateOnDelete()"');
    }
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: JSON.stringify(data.cim),
    };
  }
}
