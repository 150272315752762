import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArrayBySearch',
})
export class FilterArrayBySearchPipe implements PipeTransform {
  transform(array: any[], filter?: string): any[] {
    if (!array || !filter) {
      return array;
    }

    return array.filter((o) =>
      Object.keys(o).some((k) => {
        if (o[k]) {
          return o[k]
            .toString()
            .toLowerCase()
            .includes(filter ? filter.toLowerCase() : '');
        } else {
          return false;
        }
      })
    );
  }
}
