import { Pipe, PipeTransform } from '@angular/core';
import { Column } from 'app/main/pages/torzsek/models/torzsek';

@Pipe({
  name: 'filterColumns',
})
export class FilterColumnsPipe implements PipeTransform {
  transform(columns: Column[]): Column[] {
    // Invalid conditions
    if (!columns) {
      return [];
    }

    // Filter
    return columns.filter((c: Column) => !c?.hidden);
  }
}
