import { NgModule } from '@angular/core';
import { TorzsekDetailsComponent } from './torzsek-details.component';
import { CoreCommonModule } from '@core/common.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TorzsekDetailsComponent],
  imports: [CoreCommonModule, RouterModule, TranslateModule],
  exports: [TorzsekDetailsComponent],
})
export class TorzsekDetailsModule {}
