import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partnerekErtekesitesTransform',
})
export class PartnerekErtekesitesTransformPipe implements PipeTransform {
  transform(cim: any): string {
    if (!cim) {
      return '-';
    } else {
      return `${cim[0]?.iranyitoszam || ''} ${cim[0]?.telepules || ''} ${
        cim[0]?.kozterulet_neve || ''
      } ${cim[0]?.kozterulet_tipusa || ''} ${cim[0]?.hazszam || ''}. ${
        cim[0]?.epulet || ''
      } ${cim[0]?.emelet ? `${cim[0]?.emelet}/` : ''} ${
        cim[0]?.ajto ? `${cim[0]?.ajto}.` : ''
      }`;
    }
  }
}
