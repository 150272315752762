import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { AuthenticationService } from 'app/auth/service';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { switchMap, take, tap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { Column, Penzintezet, Penznem } from '../models/torzsek';
import { PenzintezetekService } from './penzintezetek.service';
import { PenznemekService } from './penznemek.service';
import { ListMapperService } from './_list-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class BankszamlaszamokService {
  private readonly apiEndpoint = 'bankszamlaszamok';
  private readonly columnsEndpoint = 'bankszamlaszamok/columns';

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  public data$ = combineLatest([
    this._data,
    this.penzintezetekService.data$,
    this.penznemekService.data$,
  ]).pipe(
    switchMap(([state, penzintezetek, penznemek]) =>
      state
        ? of(this.mapBankszamlaszamok(state, penzintezetek, penznemek))
        : this.fetchData()
    )
  );

  constructor(
    private _listMapper: ListMapperService,
    private penzintezetekService: PenzintezetekService,
    private penznemekService: PenznemekService,
    private api: ApiService,
    private stateService: StateService,
    private authService: AuthenticationService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.penzintezetekService.initData$,
              this.penznemekService.initData$,
            ]).pipe(
              map(([kimenoSzamlak, penzintezetek, penznemek]) =>
                this.mapBankszamlaszamok(
                  kimenoSzamlak,
                  penzintezetek,
                  penznemek
                )
              )
            )
          : this.fetchData()
      )
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => this.stateService.setStateOnAdd(added, this._data)),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((storno: any) =>
        this.stateService.setStateOnDelete(storno, this._data)
      ),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of({
          ...data,
          kulfoldi_szla:
            data.kulfoldi_szla === 0 || data.kulfoldi_szla === false
              ? false
              : true,
        });
      })
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) =>
          this.stateService.setStateOnUpdate(updated, this._data)
        ),
        catchError((err: any) => throwError(err))
      );
  }

  private fetchData(): Observable<any[]> {
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.penzintezetekService.initData$,
      this.penznemekService.initData$,
    ]).pipe(
      map(([kimenoSzamlak, penzintezetek, penznemek]) =>
        this.mapBankszamlaszamok(kimenoSzamlak, penzintezetek, penznemek)
      )
    );
  }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      catchError((err: any) => {
        console.log(err);

        return of([]);
      })
    );
  }

  private mapBankszamlaszamok(
    bankszamlak: any[],
    penzintezetek: Penzintezet[],
    penznemek: Penznem[]
  ): any[] {
    return this._listMapper.mapData(bankszamlak, [
      {
        dataSrc: penznemek,
        column: 'penznem',
        key: 'id',
        value: 'penznem_id',
        displayProp: 'megnevezes',
      },
      {
        dataSrc: penzintezetek,
        column: 'penzintezet',
        key: 'id',
        value: 'penzintezet_id',
        displayProp: 'nev',
      },
    ]);
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: JSON.stringify(data.cim),
    };
  }
}
