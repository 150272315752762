import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { switchMap, take, tap, catchError, map, withLatestFrom, bufferCount, concatMap, delay } from 'rxjs/operators';
import { Column, Partner, Penznem } from '../models/torzsek';
import { findByProp } from '../utils';
import { PartnerekService } from './partnerek.service';
import { PenznemekService } from './penznemek.service';
import { ArkategoriakService } from './arkategoriak.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { FizetesiModokService } from './fizetesi-modok.service';
import { AfakulcsokService } from './afakulcsok.service';

@Injectable({
  providedIn: 'root',
})
export class DijbekeroService {
  private readonly apiEndpoint = 'dijbekero';
  private readonly columnsEndpoint = 'dijbekero/columns';
  private readonly updateEndpoint = 'service/helplib/bizonylat_frissites/d';
  private readonly nyomtatasBeallitasEndpoint = 'service/helplib/nyomtatas_kapcsolo/d';

  private lastFetch: Date = new Date();
  private dijbekero = JSON.parse(localStorage.getItem('dijbekero'));

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  // public data$ = combineLatest([
  //   this._data,
  //   this.partnerekService.data$,
  //   this.penznemekService.data$,
  //   this.arkategoriakService.data$
  // ]).pipe(
  //   switchMap(([state, partnerek, penznemek, arkategoriak]) => {
  //     return state
  //       ? of(this.mapDijbekerok(state, partnerek, penznemek, arkategoriak))
  //       : this.dijbekero?.db > 0 ? this.getDBData() : this.fetchData()
  //   }),
  //   withLatestFrom(this.authService.currentUser$),
  //   map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  // );

  public data$ = combineLatest([
    this._data,
    this.partnerekService.data$,
    this.penznemekService.data$,
    this.arkategoriakService.data$,
    this.fizetesiModokService.data$
  ]).pipe(
    switchMap(([state, partnerek, penznemek, arkategoriak, fizetesimodok]) =>
      state
        ? of(this.mapDijbekerok(state, partnerek, penznemek, arkategoriak, fizetesimodok))
        : this.fetchData()
    )
  );

  constructor(
    private partnerekService: PartnerekService,
    private penznemekService: PenznemekService,
    private api: ApiService,
    private stateService: StateService,
    private authService: AuthenticationService,
    private arkategoriakService: ArkategoriakService,
    private fizetesiModokService: FizetesiModokService,
    private afakulcsokService: AfakulcsokService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.partnerekService.initData$,
  //             this.penznemekService.initData$,
  //             this.arkategoriakService.initData$
  //           ]).pipe(
  //             map(([dijbekerok, partnerek, penznemek, arkategoriak]) =>
  //               this.mapDijbekerok(dijbekerok, partnerek, penznemek, arkategoriak)
  //             )
  //           )
  //         : JSON.parse(localStorage.getItem('dijbekero'))?.db > 0 ? this.getDBData() : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  //   );
  // }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.partnerekService.initData$,
              this.penznemekService.initData$,
              this.arkategoriakService.initData$,
              this.fizetesiModokService.initData$
            ]).pipe(
              map(([dijbekerok, partnerek, penznemek, arkategoriak, fizetesimodok]) =>
                this.mapDijbekerok(dijbekerok, partnerek, penznemek, arkategoriak, fizetesimodok)
              )
            )
          : this.fetchData()
      ),
      withLatestFrom(this.authService.currentUser$),
      map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('dijbekero', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((storno: any) => {
        this.stateService.setStateOnDelete(storno, this._data);
        //this.dbService.deleteByKey('dijbekero', storno.id).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('dijbekero', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem('currentUser')).oe;
    const last = JSON.parse(localStorage.getItem('dijbekero'))?.lastFetch;
    return this.api.postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
           .pipe(
            take(1),
            tap(valtozas => {
              this.dbService.getAll('dijbekero').subscribe(bizonylatok => {
                this._data.next(bizonylatok.sort((a: any,b: any) => b.id - a.id));
                if(bizonylatok.length == 0) {
                  this.fetchData().subscribe();
                } else {
                  if(valtozas.valtozasok.length > 0) {
                    valtozas.valtozasok.forEach(bizonylat => {
                      const biz = findByProp(this._data.value, 'id', bizonylat.id);
                      if(biz) {
                        this.stateService.setStateOnUpdate(bizonylat, this._data);
                        //this.dbService.update('dijbekero', bizonylat).subscribe();
                      } else {
                        this.stateService.setStateOnAdd(bizonylat, this._data);
                        //this.dbService.add('dijbekero', bizonylat).subscribe();
                      }
                    })
                  }
                  if(valtozas.torolt_lista.length > 0) {
                    valtozas.torolt_lista.forEach(bizonylat => {
                      const biz = findByProp(this._data.value, 'id', bizonylat.modul_id);
                      if(biz) {
                        this.stateService.setStateOnDelete(biz ,this._data);
                        //this.dbService.deleteByKey('dijbekero', bizonylat.modul_id).subscribe();
                      }
                    })
                  }
                }
                this.dbService.clear('dijbekero').subscribe(success => {
                  if(success) {
                    this.dbService.bulkAdd('dijbekero', this._data.value).subscribe(count => {
                      localStorage.setItem('dijbekero', JSON.stringify({db: count.length, lastFetch: this.lastFetch}))
                    });
                  }
                });
              });
            }))
  }


  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
      this.afakulcsokService.initData$
    ]).pipe(
      map(([dijbekero, partnerek, penznemek, arkategoriak, afakulcsok]) => {
        const retVal = this.mapDijbekero(dijbekero, partnerek, penznemek, arkategoriak, afakulcsok)
        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) => {
          this.stateService.setStateOnUpdate(updated, this._data);
          //this.dbService.update('dijbekero', updated).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .postBizonylat(`${environment.pdfGeneralas}/${this.apiEndpoint}`, payload)
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  nyomtatasBeallitas(row: any, megjegyzes_nyomtat: number, hivatkozas_nyomtat: number = 0): Observable<any> {
    return this.api
    .post(`${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`, '')
    .pipe(
      tap((updated: any) => {
        const updateRow = {
          ...row,
          megjegyzes_nyomtat: megjegyzes_nyomtat,
        }
        this.stateService.setStateOnUpdate(updateRow, this._data);
      }),
      catchError(() => of(null))
    );
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
        this.fizetesiModokService.initData$
    ]).pipe(
      map(([dijbekerok, partnerek, penznemek, arkategoriak, fizetesimodok]) => {
        localStorage.setItem('dijbekero', JSON.stringify({db: dijbekerok.length, lastFetch: this.lastFetch}));
        return this.mapDijbekerok(dijbekerok, partnerek, penznemek, arkategoriak, fizetesimodok)
      })
    );
  }


  // private fetchData(): Observable<any[]> {
  //   this.lastFetch = new Date();
  //   return forkJoin([
  //     this.api.get(this.apiEndpoint).pipe(
  //       tap((data: any[]) => this._data.next(data)),
  //       catchError((err: any) => {
  //         console.log(err);
  //         return of([]);
  //       })
  //     ),
  //     this.partnerekService.initData$,
  //     this.penznemekService.initData$,
  //     this.arkategoriakService.initData$
  //   ]).pipe(
  //     map(([dijbekerok, partnerek, penznemek, arkategoriak]) => {
  //       // this.dbService.count('dijbekero').subscribe(count => {
  //       //   if(count == 0) {
  //       //     this.dbService
  //       //       .bulkAdd('dijbekero', this.mapDijbekerok(dijbekerok, partnerek, penznemek, arkategoriak))
  //       //       .subscribe((result) => {
  //       //       }, error => console.log(error));
  //       //   }
  //       //   localStorage.setItem('dijbekero', JSON.stringify({db: dijbekerok.length, lastFetch: this.lastFetch}));
  //       //   //this.cikkek = count;
  //       // })
  //       localStorage.setItem('dijbekero', JSON.stringify({db: dijbekerok.length, lastFetch: this.lastFetch}));

  //       return this.mapDijbekerok(dijbekerok, partnerek, penznemek, arkategoriak)
  //     })
  //   );
  // }


  private getColumns(): Observable<Column[]> {
    const columns = JSON.parse(localStorage.getItem('dijbekero_columns'));

    if(columns) {
      return of(columns);
    } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((data: Column[]) => {
          const columns = [
            ...data,
            { name: 'arkategoria_id', nullable: false },
            { name: 'tetelek', nullable: true },
            { name: 'megjegyzes_nyomtat', nullable: false, hidden: true, default: 1 }
          ];
  
          const mappedColumns = columns.map((c: Column) =>
            c.name === 'szamla_kelte'
              ? { ...c, disabled: true }
              : c.name === 'bizonylat_szam'
              ? { ...c, nullable: true }
              : c
          );
  
          localStorage.setItem('dijbekero_columns', JSON.stringify(mappedColumns));
          return mappedColumns.filter(col => col.name != 'afakulcs');
        }),
        catchError((err: any) => {
          console.log(err);
  
          return of([]);
        })
      );
    }
  }

  private mapDijbekerok(
    dijbekerok: any[],
    partnerek: Partner[],
    penznemek: Penznem[],
    arkategoriak: any[],
    fizetesiModok: any[]
  ): any[] {
    return dijbekerok.map((e: any) => {
      const penznemObj: Penznem = findByProp(penznemek, 'id', e.penznem_id);
      const penznem = penznemObj?.outfix || penznemObj?.prefix;

      return {
        ...e,
        partner: findByProp(partnerek, 'id', e.partner_id)?.nev,
        szamla_kelte: new Date(e.szamla_kelte),
        teljesites_datum: new Date(e.teljesites_datum),
        fizetesi_hatarido: new Date(e.fizetesi_hatarido),
        fizetesiMod: findByProp(fizetesiModok, 'id', e.fizetesi_mod_id)?.nev,
        //afakulcs: e.tetelek[0]?.afakulcs,
        penznem,
        //tetelek: e.tetelek.map((t: any) => ({ ...t, penznem, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev })),
      };
    });
  }

  private mapDijbekero(
    dijbekero: any,
    partnerek: Partner[],
    penznemek: Penznem[],
    arkategoriak: any[],
    afakulcsok: any[]
  ): any[] {
      const penznemObj: Penznem = findByProp(penznemek, 'id', dijbekero.penznem_id);
      const penznem = penznemObj?.outfix || penznemObj?.prefix;

      return {
        ...dijbekero,
        partner: findByProp(partnerek, 'id', dijbekero.partner_id)?.nev,
        szamla_kelte: new Date(dijbekero.szamla_kelte),
        teljesites_datum: new Date(dijbekero.teljesites_datum),
        fizetesi_hatarido: new Date(dijbekero.fizetesi_hatarido),
        fizetesiMod: dijbekero.fizetesi_mod,
        afakulcs: dijbekero.tetelek[0]?.afakulcs,
        penznem,
        tetelek: dijbekero.tetelek.map((t: any) => ({ ...t, penznem, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, afakulcs_nev: findByProp(afakulcsok, 'id', t.afakulcs_id)?.nev  })),
      };
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: JSON.stringify(data.cim),
      teljesites_datum: new Date(
        new Date(data.teljesites_datum).setHours(12, 0)
      ),
      szamla_kelte: new Date(new Date(data.szamla_kelte).setHours(12, 0)),
      fizetesi_hatarido: new Date(
        new Date(data.fizetesi_hatarido).setHours(12, 0)
      ),
    };
  }
}
