import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from 'app/auth/models';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  Column,
  Arkategoria,
  FizetesiMod,
  Partner,
  Penznem,
} from '../../models/torzsek';
import { KimenoSzamlaService } from '../../services/kimeno-szamla.service';
import { NyugtaService } from '../../services/nyugta.service';
import { SzallitolevelService } from '../../services/szallitolevel.service';
import { FormService } from '../../services/_form.service';
import { DijbekeroElolegKimenoHelperService } from '../dijbekero-eloleg-kimeno/dijbekero-eloleg-kimeno-helper.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { PartnerekService } from '../../services/partnerek.service';
import { CikktorzsService } from '../../services/cikktorzs.service';
import { ArfolyamokService } from '../../services/arfolyamok.service';

@Component({
  selector: 'app-ertekesites',
  templateUrl: './ertekesites.component.html',
  styleUrls: ['./ertekesites.component.scss'],
  providers: [DijbekeroElolegKimenoHelperService],
})
export class ErtekesitesComponent implements OnInit, OnDestroy {
  @ViewChild('detailsWrapperTemplate') template: TemplateRef<any>;
  editData: any;
  columns: Column[];
  formGroup: FormGroup;
  torzs = 'kimeno-szamla';
  bizonylattipusok = [];
  arkategoriak: Arkategoria[];
  fizetesiModok: FizetesiMod[];
  partnerek: Partner[];
  penznemek: Penznem[];
  user: User;
  arfolyamok: any[];
  kedvezmeny: number = 0;

  // Side forms
  modalTitle: 'Partner adatok' | 'Számla adatok';
  sideForm: 'partner' | 'szamla';

  get formValue(): any {
    return this.formGroup.getRawValue();
  }

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _coreSidebarService: CoreSidebarService,
    private formService: FormService,
    private helperService: DijbekeroElolegKimenoHelperService,
    private kimenoService: KimenoSzamlaService,
    private szallitoService: SzallitolevelService,
    private nyugtaService: NyugtaService,
    private partnerekService: PartnerekService,
    private location: Location,
    private cikktorzsService: CikktorzsService,
    private arfolyamokService: ArfolyamokService,
  ) {}

  ngOnInit(): void {
    const raktarID = JSON.parse(localStorage.getItem('currentUser')).raktar;

    this.cikktorzsService.getCikktorzsChange(raktarID);
    
    this._activatedRoute.data
      .pipe(
        map(
          (resolved: {
            data: {
              data: any;
              user: User;
              columns: Column[];
              torzs:
                | 'dijbekero'
                | 'elolegszamla'
                | 'kimeno-szamla'
                | 'nyugta'
                | 'szallitolevel';
              bizonylattipusok: { title: string; value: string }[];
              service: any;
            };
            torzsek: {
              arkategoriak: Arkategoria[];
              fizetesiModok: FizetesiMod[];
              partnerek: Partner[];
              penznemek: Penznem[];
            };
          }) => ({ ...resolved.data, ...resolved.torzsek })
        )
      )
      .subscribe(
        ({
          data,
          columns,
          user,
          torzs,
          bizonylattipusok,
          arkategoriak,
          fizetesiModok,
          partnerek,
          penznemek,
        }) => {
          this.formGroup = this.formService.createFormGroup(
            columns,
            data,
            'optional-validation'
          );
          this.formGroup.patchValue({
            skonto: 0,
            skonto_nap: 0
          })

          this.editData = data;
          this.columns = columns;
          this.torzs = torzs;
          this.bizonylattipusok = bizonylattipusok;

          this.arkategoriak = arkategoriak;
          this.fizetesiModok = fizetesiModok;
          this.partnerek = partnerek;
          this.penznemek = penznemek;
          this.user = user;

          this.helperService.formGroup = this.formGroup;

          if (torzs === 'nyugta') {
            this.formGroup.get('partner_id').disable();
            this.formGroup.get('cim').disable();
          }
        }
      );

    this.partnerekService.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerek: Partner[]) => {
        this.partnerek = partnerek;
      });

    this.calcSumOnTetelekChange();
    this.updateOnFizetesiModChange();

    this.arfolyamokService.initData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.arfolyamok = data;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToList(): void {
    this.location.back();
  }

  onSubmit(tipus: 'szallitolevel' | 'nyugta' | 'kimeno-szamla'): void {
    switch (tipus) {
      case 'szallitolevel': {
        this.szallitoService
        .add({ 
          ...this.formValue, 
          statusz: 1,
         })
        .pipe(
          switchMap((res: any) =>
            this.szallitoService.generatePdf({ id: res.id }).pipe(
              tap((url: string) => {
                if (url) {
                  this.openPreview(url);
                }
              })
            )
          )).subscribe(res => {
            //this.formGroup.reset(this.editData);
            this.resetForm();
          })
        
        break;
      }

      case 'nyugta': {
        this.nyugtaService.add({...this.formValue, statusz: 1}).pipe(
          switchMap((res: any) =>
            this.nyugtaService.generatePdf({ id: res.id }).pipe(
              tap((url: string) => {
                if (url) {
                  this.openPreview(url);
                }
              })
            )
          ))
        .subscribe(() => {
          //this.formGroup.reset(this.editData);
          this.resetForm();
        });

        break;
      }

      case 'kimeno-szamla': {
        this.kimenoService
        .add({ 
          ...this.formValue, 
          statusz: 1,
          fizetett: (this.formValue.fizetesi_mod_id == 6 || this.formValue.fizetesi_mod_id == 8) ?
              this.formValue.brutto_ar : 0
         })
        .pipe(
          switchMap((res: any) =>
            this.kimenoService.generatePdf({ id: res.id }).pipe(
              tap((url: string) => {
                if (url) {
                  this.openPreview(url);
                }
              })
            )
          )).subscribe(res => {
            //this.formGroup.reset(this.editData);
            this.resetForm();
          })

        break;
      }

      default: {
        break;
      }
    }
  }

  openPartnerAdatai(): void {
    this.modalTitle = 'Partner adatok';
    this.sideForm = 'partner';
    this._coreSidebarService.getSidebarRegistry('ertekesites-sidebar').open();
  }

  openSzamlaAdatai(): void {
    this.modalTitle = 'Számla adatok';
    this.sideForm = 'szamla';
    this._coreSidebarService.getSidebarRegistry('ertekesites-sidebar').open();
  }

  closeSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('ertekesites-sidebar').close();
    this.modalTitle = undefined;
    this.sideForm = undefined;
  }

  updateForm(): void {}

  private calcSumOnTetelekChange() {
    this.formGroup
      .get('tetelek')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((tetelek: any[]) => {
        const penznem = this.formGroup.get('penznem_id')?.value;
        const fizetesi_mod_id = this.formGroup.get('fizetesi_mod_id')?.value
        const { netto_ar, afa_ar, brutto_ar } =
          this.helperService.calcSum(tetelek, penznem, fizetesi_mod_id);

        this.formGroup.patchValue({
          netto_ar,
          brutto_ar,
          afa_ar,
        });

        this.helperService.updateSelectedState();
      });
  }

  private updateOnFizetesiModChange() {
    this.formGroup.get('fizetesi_mod_id').valueChanges.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(fizetesiMod => {
        const penznemId = this.formGroup.get('penznem_id')?.value;
        //const fizetesi_mod_id = this.fizetesiModControl.value;

        const { netto_ar, afa_ar, brutto_ar } =
          this.helperService.calcSum(this.formValue.tetelek, penznemId, fizetesiMod);

        this.formGroup.patchValue({
          netto_ar,
          brutto_ar,
          afa_ar,
        });
    })
  }

  private openPreview(url: string): void {
    // Open preview
    window.open(url, '_blank');
  }

  onPartner(partner: any) {
    this.kedvezmeny = partner.kedvezmeny;
  }

  resetForm(): void {
    this.formGroup.patchValue({
      partner_id: null,
      //cim: null,
      fizetesi_mod_id: 6,
      tetelek: []
    });
    this.formGroup.get('cim').reset()
  }
}
