import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  combineLatest,
  forkJoin,
} from 'rxjs';
import { switchMap, take, tap, catchError, map } from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { findByProp } from '../utils';
import { MegyekService } from './megyek.service';

@Injectable({
  providedIn: 'root',
})
export class TelepulesekService {
  private readonly apiEndpoint = 'telepulesek';
  private readonly columnsEndpoint = 'telepulesek/columns';

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  public data$ = combineLatest([this._data, this.megyekService.data$]).pipe(
    switchMap(([state, megyek]) =>
      state ? of(this.mapTelepulesek(state, megyek)) : this.fetchData()
    )
  );

  constructor(
    private api: ApiService,
    private megyekService: MegyekService,
    private stateService: StateService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([of(state), this.megyekService.initData$]).pipe(
              map(([telepulesek, megyek]) =>
                this.mapTelepulesek(telepulesek, megyek)
              )
            )
          : this.fetchData()
      )
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, data).pipe(
      tap((added: any) => this.stateService.setStateOnAdd(added, this._data)),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: any) =>
        this.stateService.setStateOnDelete(deleted, this._data)
      ),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  update(data: any): Observable<any> {
    return this.api.put(`${this.apiEndpoint}/${data.id}`, data).pipe(
      tap((updated: any) =>
        this.stateService.setStateOnUpdate(updated, this._data)
      ),
      catchError((err: any) => throwError(err))
    );
  }

  private fetchData(): Observable<any[]> {
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.megyekService.initData$,
    ]).pipe(
      map(([telepulesek, megyek]) => this.mapTelepulesek(telepulesek, megyek))
    );
  }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private mapTelepulesek(telepulesek: any[], megyek: any[]): any[] {
    return telepulesek.map((m: any) => ({
      ...m,
      megye: findByProp(megyek, 'id', m.megye_id)?.nev,
    }));
  }
}
