import { NgModule } from '@angular/core';
import { DateMaskDirective } from './directives/date-input-mask.directive';
import { MarkAsteriskDirective } from './directives/mask-asterix.directive';
import { OverflowTooltipDirective } from './directives/overflow-tooltip.directive';

@NgModule({
  declarations: [
    DateMaskDirective,
    MarkAsteriskDirective,
    OverflowTooltipDirective,
  ],
  imports: [],
  exports: [DateMaskDirective, MarkAsteriskDirective, OverflowTooltipDirective],
})
export class SharedDirectivesModule {}
