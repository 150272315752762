<div class="d-flex flex-column table-container" [ngStyle]="{ height: height }">
    <table class="table table-hover table-bordered" [ngClass]="tableClass" [ngStyle]="tableStyle">
        <thead>
            <tr class="columns">
                <th scope="col" [ngStyle]="{ width: '47px', padding: '0.72rem 17px' }">
                    #
                </th>
                <th *ngFor="let column of filteredColumns" [ngStyle]="{ width: column?.width || '' }" scope="col">
                    {{ column?.title || (column.name | translate) }}
                </th>
                <th [ngStyle]="{ width: '75px' }" *ngIf="!disableActions">Műveletek</th>
            </tr>
        </thead>
        <tbody [style.maxHeight]="bodyMaxHeight">
            <tr *ngIf="!_data?.length">
                <td [attr.colspan]="filteredColumns?.length + 2" class="text-center">
                    Nincs megjelenítendő adat.
                </td>
            </tr>

            <tr *ngFor="let dataItem of _data; index as i" [class.selected]="i === selectedItem?.rowIndex" (click)="onRowSelect(dataItem, i)" [ngbTooltip]="!checkRaktaron(dataItem) ? 'Hiány: ' + hiany : dataItem.hiany ? 'Hiány: ' + dataItem.hiany : ''">
                <td [ngStyle]="{ width: '47px', padding: '0.72rem 17px' }">
                    {{ i + 1 }}
                </td>
                <td *ngFor="let column of filteredColumns" [ngStyle]="!checkRaktaron(dataItem) && { width: column?.width || '' } && { 'color': 'red' } || (dataItem.hiany || dataItem.hibas) ? {'color' : 'red'} : '' ">
                    <ng-container [ngTemplateOutlet]="
              column.name === 'cim'
                ? cimTemplate
                : column.name === 'szamlaszam'
                ? szamlaszamTemplate
                : column?.type === 'currency'
                ? currencyTemplate
                : column?.suffix
                ? suffixTemplate
                : column?.suffixProp
                ? suffixPropTemplate
                : defaultTemplate
            " [ngTemplateOutletContext]="{ column: column, dataItem: dataItem }">
                    </ng-container>
                </td>
                <td *ngIf="!disableActions" [ngStyle]="{ width: '75px' }">
                    <button *ngIf="route == 'bevetelezes'" class="btn btn-icon float-right" (mouseenter)="preventSelection = true" (mouseleave)="preventSelection = false" [ngClass]="dataItem.beszerar_frissit ? 'btn-success' : 'btn-outline-secondary'" [ngStyle]="{ 'margin-right': '3px' }"
                        type="button" [disabled]="formGroup?.invalid || formGroup?.pristine" (click)="changeBeszerarFrissit(dataItem)" ngbTooltip="Beszerzési ár frissítése" placement="top" container="body">
          <span [data-feather]="'dollar-sign'"></span>
        </button>
                    <button *ngIf="route == 'bejovo-szamla'" class="btn btn-icon float-left" (mouseenter)="preventSelection = true" (mouseleave)="preventSelection = false" [ngClass]="dataItem.beszerar_frissit ? 'btn-success' : 'btn-outline-secondary'" [ngStyle]="{ 'margin-right': '3px' }"
                        type="button" [disabled]="formGroup?.invalid || formGroup?.pristine" (click)="changeBeszerarFrissit(dataItem)" [ngbTooltip]="beszerar" placement="top" container="body">
          <span [data-feather]="'dollar-sign'"></span>
        </button>
                    <ng-template #beszerar>
                        <div class="d-flex flex-column">
                            <span>Legutolsó beszer.ár:</span>
                            <span>{{ dataItem.beszerzesi_ar}} Ft</span>
                        </div>
                    </ng-template>
                    <button *ngIf="torzs != 'szallitolevel'" type="button" class="btn btn-icon btn-primary waves-effect" (mouseenter)="preventSelection = true" (mouseleave)="preventSelection = false" (click)="onRemove(i)">
            <span class="ficon feather icon-trash-2"></span>
          </button>
                    <button *ngIf="torzs == 'szallitolevel' && (!dataItem.szallitolevel_id || editMode)" type="button" class="btn btn-icon btn-primary waves-effect" (mouseenter)="preventSelection = true" (mouseleave)="preventSelection = false" (click)="onRemove(i)">
                    <span class="ficon feather icon-trash-2"></span>
                </button>
                    <button *ngIf="route == 'kimeno-szamla' || route == 'elolegszamla' || route == 'dijbekero' || route == 'ajanlat' || route == 'foglalas' || route == 'kulso-raktari-eladas' || route == 'nyugta' || (route == 'szallitolevel' && (!dataItem.szallitolevel_id || editMode))"
                        type="button" class="btn btn-icon btn-secondary waves-effect ml-1" (mouseenter)="preventSelection = true" (mouseleave)="preventSelection = false" (click)="openModal(megjegyzes, dataItem, i)">
            <span [data-feather]="'message-square'"></span>
          </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #defaultTemplate let-dataItem="dataItem" let-column="column">
    {{ dataItem[column.name] }}
</ng-template>

<ng-template #mennyisegTemplate let-dataItem="dataItem" let-column="column">
    {{ dataItem[column.name] | number : '1.0' }} {{ dataItem?.mennyisegi_egyseg_text || 'nem található' }}
</ng-template>

<ng-template #currencyTemplate let-dataItem="dataItem" let-column="column">
    <span>{{ dataItem[column.name] | number : '1.0' }}</span>

    <span *ngIf="dataItem.suffixProp">
        {{ dataItem.suffixProp }}
      </span>

    <span *ngIf="column?.suffixProp && !dataItem.suffixProp">
    {{ dataItem?.penznem || dataItem[column.suffixProp] }}
  </span>

    <span *ngIf="!column?.suffixProp && column?.suffix && !dataItem.suffixProp">
    {{ dataItem?.penznem || column.suffix }}</span
  >
</ng-template>

<ng-template #suffixTemplate let-dataItem="dataItem" let-column="column">
    <span>{{ dataItem[column.name] }}</span>

    <span> {{ column.suffix }}</span>
</ng-template>

<ng-template #suffixPropTemplate let-dataItem="dataItem" let-column="column">
    <span *ngIf="column?.type !== 'number'">{{ dataItem[column.name] }}</span>
    <span *ngIf="column?.type === 'number'">{{
    dataItem[column.name] | number : '1.0'
  }}</span>

    <span> {{ dataItem[column.suffixProp] }}</span>
</ng-template>

<ng-template #cimTemplate let-dataItem="dataItem" let-column="column">
    {{ dataItem | cimTransform }}
</ng-template>

<ng-template #szamlaszamTemplate let-dataItem="dataItem" let-column="column">
    {{ dataItem[column.name] | mask : '00000000-00000000-00000000||00000000-00000000' }}
</ng-template>

<ng-template #defaultToolbar>
    <input id="search-input" type="text" class="form-control" placeholder="Keresés..." (keyup)="filterUpdate($event)" />
</ng-template>

<!-- Megjegyzes modal -->
<ng-template #megjegyzes let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Megjegyzés hozzáadása</h4>
        <button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">x</button>
    </div>
    <div class="row">
        <div class="col-12 pl-4 pr-4">
            <div class="form-group">
                <label for="megjegyzes">Megjegyzés:</label>
                <div class="input-group input-group-merge">
                    <textarea id="megjegyzes" class="form-control" [(ngModel)]="cikkMegjegyzes"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="setMegjegyzes(tetel)">Hozzáadás</button>
    </div>
</ng-template>