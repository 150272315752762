import { Pipe, PipeTransform } from '@angular/core';
import { Arkategoria, Partner } from '../../../pages/torzsek/models/torzsek';
import { findByProp } from '../../../pages/torzsek/utils';

@Pipe({
  name: 'filterArkategoriakByPartner',
})
export class FilterArkategoriakByPartnerPipe implements PipeTransform {
  transform(
    arkategoriak: Arkategoria[],
    partnerek: Partner[],
    pId: number
  ): Arkategoria[] {
    if (!arkategoriak || !partnerek || !pId) {
      return arkategoriak;
    }

    const partner: Partner = findByProp(partnerek, 'id', pId);

    if(partner) {
      return arkategoriak.filter(
        (a: Arkategoria) =>
          (partner.arkategoria_id as number[]).indexOf(a.id) !== -1
      );
    } else {
      return arkategoriak;
    }
  }
}
