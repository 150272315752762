import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsAllSelectedPipe } from './is-all-selected.pipe';
import { IsHeaderIndeterminatePipe } from './is-header-indeterminate.pipe';
import { IsKiszolgaltPipe } from './is-kiszolgalt.pipe';
import { IsReszbenKiszolgaltPipe } from './is-reszben-kiszolgalt.pipe';
import { FilterArkategoriakByPartnerPipe } from './filter-arkategoriak-by-partner.pipe';

const pipes = [
  IsAllSelectedPipe,
  IsHeaderIndeterminatePipe,
  IsKiszolgaltPipe,
  IsReszbenKiszolgaltPipe,
  FilterArkategoriakByPartnerPipe,
];

@NgModule({
  declarations: [pipes],
  imports: [CommonModule],
  exports: [pipes],
})
export class PenzugyiBizonylatokPipesModule {}
