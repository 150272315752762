import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

const modules = [
  CommonModule,
  CoreDirectivesModule,
  CorePipesModule,
  FlexLayoutModule,
  FormsModule,
  NgbModule,
  NgSelectModule,
  ReactiveFormsModule,
];

@NgModule({
  imports: [modules],
  exports: [modules],
})
export class CoreCommonModule {}
