import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthUtils } from './auth.utils';
import { AuthenticationService } from '../service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _authService: AuthenticationService
  ) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const KIRRequest: boolean = req.url.includes(
      'kir.frederik-technologies.com'
    );
    // Clone the request object
    let newReq = req.clone();

    // KIR Auth
    if (KIRRequest) {
      newReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + localStorage.getItem('kirToken')
        ),
      });
    }

    // Request
    // API Auth
    else if (
      this._authService.accessToken &&
      !AuthUtils.isTokenExpired(this._authService.accessToken)
    ) {
      newReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this._authService.accessToken
        ),
      });
    }
    // Invalid req
    else {
      console.log('Session ended. Navigating to login page...');
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !KIRRequest
        ) {
          // Sign out
          this._authService.logout();

          this._router.navigate(['bejelentkezes'], {
            queryParams: { returnUrl: this._router.url },
          });
        }

        return throwError(error);
      })
    );
  }
}
