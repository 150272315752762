import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { FilterArrayBySearchPipe } from './pipes/filter-array-by-search.pipe';
import { CalcActionsColWidthPipe } from './pipes/calc-actions-col-width.pipe';
import { RequiredControlPipeModule } from './pipes/required-control-pipe/required-control.-pipe.module';
import { SharedDirectivesModule } from './shared-directives.module';
import { GetBgColorPipeModule } from './pipes/get-bg-color-pipe/get-bg-color-pipe.module';

const maskConfigFunction: () => Partial<IConfig> = () => ({
  validation: true,
});

@NgModule({
  declarations: [FilterArrayBySearchPipe, CalcActionsColWidthPipe],
  imports: [
    RequiredControlPipeModule,
    GetBgColorPipeModule,
    SharedDirectivesModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxMaskModule.forRoot(maskConfigFunction),
  ],
  exports: [
    TranslateModule,
    SharedDirectivesModule,
    FilterArrayBySearchPipe,
    CalcActionsColWidthPipe,
    GetBgColorPipeModule,
    NgxMaskModule,
    RequiredControlPipeModule,
  ],
})
export class SharedModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
