import { NgModule } from '@angular/core';
import { TorzsekBasicTableComponent } from './torzsek-basic-table.component';
import { SharedModule } from 'app/main/shared/shared.module';
import { CimTransformPipe } from './pipes/cim-transform.pipe';
import { CoreCommonModule } from '@core/common.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [TorzsekBasicTableComponent, CimTransformPipe],
  imports: [CoreCommonModule, SharedModule],
  exports: [TorzsekBasicTableComponent],
})
export class TorzsekBasicTableModule {}
