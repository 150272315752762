import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  combineLatest,
  forkJoin,
} from 'rxjs';
import { switchMap, take, tap, catchError, map } from 'rxjs/operators';
import { Column } from '../models/torzsek';
import { findByProp } from '../utils';
import { OrszagokService } from './orszagok.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class PenzintezetekService {
  private readonly apiEndpoint = 'penzintezetek';
  private readonly columnsEndpoint = 'penzintezetek/columns';
  private readonly penzintezetekUpdateEndpoint = 'service/frissiteslib/penzintezetek_frissites';

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);

  private penzintezetek = JSON.parse(localStorage.getItem('penzintezetek'));
  private lastFetch: Date = new Date();

  public data$ = combineLatest([this._data, this.orszagokService.data$]).pipe(
    switchMap(([state, orszagok]) => {
      return state ? of(this.mapPenzintezetek(state, orszagok)) : this.penzintezetek?.db > 0 ? this.getDBData() : this.fetchData()
    })
  );

  // public data$ = combineLatest([this._data, this.orszagokService.data$]).pipe(
  //   switchMap(([state, orszagok]) =>
  //     state ? of(this.mapPenzintezetek(state, orszagok)) : this.fetchData()
  //   )
  // );

  constructor(
    private orszagokService: OrszagokService,
    private api: ApiService,
    private stateService: StateService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([of(state), this.orszagokService.initData$]).pipe(
              map(([penzintezetek, orszagok]) =>
                this.mapPenzintezetek(penzintezetek, orszagok)
              )
            )
          : JSON.parse(localStorage.getItem('penzintezetek'))?.db > 0 ? this.getDBData() : this.fetchData()
      )
    );
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([of(state), this.orszagokService.initData$]).pipe(
  //             map(([penzintezetek, orszagok]) =>
  //               this.mapPenzintezetek(penzintezetek, orszagok)
  //             )
  //           )
  //         : this.fetchData()
  //     )
  //   );
  // }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, data).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('penzintezetek', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((deleted: any) => {
        this.stateService.setStateOnDelete(deleted, this._data);
        //this.dbService.deleteByKey('penzintezetek', deleted.id).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  update(data: any): Observable<any> {
    return this.api.put(`${this.apiEndpoint}/${data.id}`, data).pipe(
      tap((updated: any) => {
        this.stateService.setStateOnUpdate(updated, this._data);
        //this.dbService.update('penzintezetek', updated).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(this.apiEndpoint).pipe(
        tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.orszagokService.initData$,
    ]).pipe(
      map(([megyek, orszagok]) => {
        this.dbService.clear('penzintezetek').subscribe();
            this.dbService
              .bulkAdd('penzintezetek', this.mapPenzintezetek(megyek, orszagok))
              .subscribe((result) => {
                localStorage.setItem('penzintezetek', JSON.stringify({db: result.length, lastFetch: this.lastFetch}));
              }, error => console.log(error));
        return this.mapPenzintezetek(megyek, orszagok)
      })
    );
  }

  // private fetchData(): Observable<any[]> {
  //   this.lastFetch = new Date();
  //   return forkJoin([
  //     this.api.get(this.apiEndpoint).pipe(
  //       tap((data: any[]) => this._data.next(data)),
  //       catchError((err: any) => {
  //         console.log(err);
  //         return of([]);
  //       })
  //     ),
  //     this.orszagokService.initData$,
  //   ]).pipe(
  //     map(([megyek, orszagok]) => {
  //       // this.dbService.count('penzintezetek').subscribe(count => {
  //       //   if(count == 0) {
  //       //     this.dbService
  //       //       .bulkAdd('penzintezetek', this.mapPenzintezetek(megyek, orszagok))
  //       //       .subscribe((result) => {
  //       //       }, error => console.log(error));
  //       //   }
  //       //   localStorage.setItem('penzintezetek', JSON.stringify({db: this.mapPenzintezetek(megyek, orszagok).length, lastFetch: this.lastFetch}));
  //       //   //this.cikkek = count;
  //       // })
  //       localStorage.setItem('penzintezetek', JSON.stringify({db: this.mapPenzintezetek(megyek, orszagok).length, lastFetch: this.lastFetch}));

  //       return this.mapPenzintezetek(megyek, orszagok)
  //     })
  //   );
  // }

  getDBData(): Observable<any> {
    console.log('get penzintezetek from indexedDB')
    return forkJoin([
      this.dbService.getAll('penzintezetek').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.orszagokService.initData$,
    ]).pipe(
      map(([megyek, orszagok]) => {
        return this.mapPenzintezetek(megyek, orszagok)
      })
    );
  }

  getPenzintezetekChange(): any {
    this.lastFetch = new Date();
    const last = JSON.parse(localStorage.getItem('penzintezetek')).lastFetch;
    this.api.get(`${this.penzintezetekUpdateEndpoint}/${last}`).pipe(
        //take(1),
        //tap((data: any[]) => this._data.next(data)),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
    )
    .subscribe((resp: any) => {
      if(resp) {
        if(this._data.value) {
          if(resp.valtozas.length > 0) {
            resp.valtozas.forEach(valtozas => {
                  const biz = findByProp(this._data.value, 'id', valtozas.id);
                  if(biz) {
                    this.stateService.setStateOnUpdate(valtozas, this._data);
                    this.dbService.update('penzintezetek', valtozas).subscribe();
                  } else {
                    this.stateService.setStateOnAdd(valtozas, this._data);
                    this.dbService.add('penzintezetek', valtozas).subscribe();
                  }
                })
              }            
          }
          if(resp.torolt_lista.length > 0) {
            resp.torolt_lista.forEach(penzintezet => {
              const part = findByProp(this._data.value, 'id', penzintezet.modul_id);
                  if(part) {
                    this.stateService.setStateOnDelete(part ,this._data);
                    this.dbService.deleteByKey('penzintezetek', penzintezet.modul_id).subscribe();
                  }
            })
          }
          this.dbService.count('penzintezetek').subscribe(count => {
            localStorage.setItem('penzintezetek', JSON.stringify({db: count, lastFetch: this.lastFetch}))
          });
        }
      }
    );
  }

  private getColumns(): Observable<Column[]> {
    return this.api.get(this.columnsEndpoint).pipe(
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  private mapPenzintezetek(penzintezetek: any[], orszagok: any[]): any[] {
    return penzintezetek.map((p: any) => ({
      ...p,
      orszag: findByProp(orszagok, 'id', p.orszag_id)?.megnevezes,
    }));
  }
}
