import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TorzsekCimFormComponent } from './torzsek-cim-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'app/main/shared/shared.module';

@NgModule({
  declarations: [TorzsekCimFormComponent],
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, SharedModule],
  exports: [TorzsekCimFormComponent],
})
export class TorzsekCimFormModule {}
