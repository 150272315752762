import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerekModalComponent } from 'app/main/shared/modals/partnerek-modal/partnerek-modal.component';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Partner } from '../../../models/torzsek';
import { findByProp } from '../../../utils';

@Component({
  selector: 'app-ertekesites-partner-adatai',
  templateUrl: './ertekesites-partner-adatai.component.html',
  styleUrls: ['./ertekesites-partner-adatai.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ErtekesitesPartnerAdataiComponent implements OnInit, OnDestroy {
  @Input() partnerek: Partner[];
  @Output() close = new EventEmitter<void>();
  @Output() kedvezmenyPartner = new EventEmitter<Partner>();

  // Ref from container
  formGroup: FormGroup;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private parent: FormGroupDirective,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.formGroup = this.parent.form;
    this.fillFormOnPartnerChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openNewPartnerModal(): void {
    const modal = this.modalService.open(PartnerekModalComponent, {
      centered: true,
      size: 'sm',
    });

    modal.closed
      .pipe(filter((partner: any) => partner?.id))
      .subscribe((partner: any) => {
        this.formGroup.patchValue(
          { partner_id: partner.id },
          { emitEvent: false }
        );
        this.fillPartnerCimOnPartnerSelect(partner);
        this.fillSzamlaAdatokOnPartnerSelect(partner);
      });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter((t) => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach((arr_term) => {
      let nev = item['nev']?.toLowerCase();
      let adoszam = item['adoszam']?.toLowerCase();
      isWordThere.push(
        nev.indexOf(arr_term?.toLowerCase()) != -1 ||
          (adoszam && adoszam?.indexOf(arr_term?.toLowerCase()) != -1)
      );
    });

    const allWords = (thisWord: string) => thisWord;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(allWords);
  }

  closeSidebar(): void {
    this.close.emit();
  }

  private fillPartnerCimOnPartnerSelect(partner: Partner): void {
    // Magánszemély
    if (partner?.ugyfel_tipus === 'MS') {
      this.fillMaganszemelyCim(partner);
    }

    // Jogi személy
    else {
      this.fillJogiSzemelyCim(partner);
    }
  }

  private fillSzamlaAdatokOnPartnerSelect(partner: Partner): void {
    this.formGroup.patchValue({
      arkategoria_id: partner?.arkategoria_id[0],
      fizetesi_mod_id: partner?.fizetesimod_id || 8,
      penznem_id: partner?.penznem_id || 1,
    });
  }

  private fillFormOnPartnerChange() {
    this.formGroup
      .get('partner_id')
      .valueChanges.pipe(
        map((pId: number) => findByProp(this.partnerek, 'id', pId)),
        takeUntil(this.destroy$)
      )
      .subscribe((partner: Partner) => {
        // Fill partner adatok
        this.fillPartnerCimOnPartnerSelect(partner);

        // Fill számla adatok
        this.fillSzamlaAdatokOnPartnerSelect(partner);

        this.kedvezmenyPartner.emit(partner);

        // Set Validation
        if (partner) {
          this.updateCimValidators(
            this.formGroup.get('cim') as FormGroup,
            true
          );
        }
        // Clear validation and Reset
        else {
          this.updateCimValidators(this.formGroup.get('cim') as FormGroup);
        }
      });
  }

  private updateCimValidators(formGroup: FormGroup, required?: boolean): void {
    const requiredControls = [
      'iranyitoszam',
      'telepules',
      'kozterulet_neve',
      'kozterulet_tipusa',
      'hazszam',
    ];

    requiredControls.forEach((controlName: string) => {
      const control = formGroup.get(controlName);

      // Required because Partner filled
      if (required) {
        control.setValidators(Validators.required);
        control.updateValueAndValidity();
      }

      // Not required | Partner cleared | Clear input
      else {
        control.clearValidators();
        control.setValue(null);
        control.updateValueAndValidity();
      }
    });
  }

  private fillMaganszemelyCim(partner: Partner): void {
    const cimControl: AbstractControl = this.formGroup.get('cim');

    const szamlazasiCim = findByProp(
      partner.cim || [],
      'cimtipus',
      'Számlázási cím'
    );
    const allandoLakcim = findByProp(
      partner.cim || [],
      'cimtipus',
      'Állandó lakcím'
    );

    const szekhely = findByProp(partner.cim || [], 'cimtipus', 'Székhely');

    // Fill
    if (szamlazasiCim || allandoLakcim || szekhely) {
      cimControl.patchValue(szamlazasiCim || allandoLakcim || szekhely, { emitEvent: false });
    }

    // Reset
    else {
      cimControl.reset();
    }
  }

  private fillJogiSzemelyCim(partner: Partner): void {
    const cimControl: AbstractControl = this.formGroup.get('cim');
    const szekhely = findByProp(partner.cim || [], 'cimtipus', 'Székhely');

    // Fill
    if (szekhely) {
      cimControl.patchValue(szekhely, { emitEvent: false });
    }

    // Reset
    else {
      cimControl.reset();
    }
  }
}
