<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="szamlaKelte">Számla kelte</label>
            <div class="input-group">
                <p-calendar #szamlaKelte dateMask [minDate]="minFizetesiHatarido" inputStyleClass="form-control text-color-input" formControlName="szamla_kelte" [required]="'szamla_kelte' | requiredControl: formGroup"></p-calendar>
            </div>
        </div>
    </div>

    <div class="col">
        <div class="form-group">
            <label for="fizetesimod">Fizetési mód</label>
            <div class="input-group">
                <ng-select id="fizetesimod" class="w-100" [items]="fizetesiModok" [clearable]="false" bindLabel="nev" bindValue="id" formControlName="fizetesi_mod_id" [required]="'fizetesi_mod_id' | requiredControl: formGroup">
                </ng-select>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="teljesitesDatum">Teljesítés dátuma</label>
            <div class="input-group">
                <p-calendar #teljesitesDatum dateMask [minDate]="minTeljesitesDatuma" inputStyleClass="form-control text-color-input" formControlName="teljesites_datum" [required]="'teljesites_datum' | requiredControl: formGroup"></p-calendar>
            </div>
        </div>
    </div>

    <div class="col">
        <div class="form-group">
            <label for="fizetesiHatarido">Fizetési határidő</label>
            <div class="input-group">
                <p-calendar #fizetesiHatarido dateMask [minDate]="minFizetesiHatarido" inputStyleClass="form-control text-color-input" formControlName="fizetesi_hatarido" [required]="'fizetesi_hatarido' | requiredControl: formGroup"></p-calendar>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="form-group">
            <label for="arkategoria">Árkategória</label>
            <div class="input-group">
                <ng-select id="arkategoria" class="w-100" [items]="arkategoriak" [clearable]="false" bindLabel="nev" bindValue="id" formControlName="arkategoria_id" [required]="'arkategoria_id' | requiredControl: formGroup">
                </ng-select>
            </div>
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <label for="penznem">Pénznem</label>
            <div class="input-group">
                <ng-select id="penznem" class="w-100" [items]="_penznemek" [clearable]="false" bindLabel="megnevezes" bindValue="id" formControlName="penznem_id" [required]="'penznem_id' | requiredControl: formGroup">
                </ng-select>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="custom-control custom-switch custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="megjegyzes_nyomtat" />
            <label class="custom-control-label" for="customSwitch1">Megjegyzés</label>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="form-group">
            <label for="megjegyzes" class="form-label">Megjegyzés</label>
            <textarea class="form-control" id="megjegyzes" aria-describedby="megjegyzes" rows="4" formControlName="megjegyzes" [required]="'megjegyzes' | requiredControl: formGroup"></textarea>
        </div>
    </div>
</div>

<div class="row mb-1" *ngIf="formGroup.get('fizetesi_mod_id')?.value != 8 && formGroup.get('fizetesi_mod_id')?.value != 6">
    <div class="col-3">
        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck1" [checked]="skontoShow" (change)="onCheckBoxChange()" />
            <label class="custom-control-label" for="customCheck1" ngbTooltip="(csak számla)" container="body">Skontó</label>
        </div>
    </div>



    <div class="col-4" *ngIf="skontoShow">
        <div class="form-group">
            <label for="basic-range">Skontó napok</label> <br>
            <ngb-rating [(rate)]="skonto_nap" [min]="0" [max]="6" formControlName="skonto_nap">
                <ng-template let-fill="fill" let-index="index">
                    <span class="fa fa-circle-o" [class.fa-circle]="fill===100" [class.filled]="fill===100"></span>
                    <!-- <span class="label">{{index + 1}}</span> -->
                </ng-template>
            </ngb-rating>
        </div>
        <!-- <div class="form-group">
          <label for="basic-range">Skontó napok</label>
          <ngb-pagination [collectionSize]="60" size="sm" [(page)]="skonto_nap" [directionLinks]="false" aria-label="Default pagination"></ngb-pagination>
      </div> -->
    </div>
    <div class="col-5" *ngIf="skontoShow">
        <div class="form-group">
            <label for="basic-range">Skontó százalék</label> <br>
            <ngb-rating [(rate)]="skonto" [min]="0" [max]="3" formControlName="skonto">
                <ng-template let-fill="fill" let-index="index">
                    <span class="fa fa-circle-o" [class.fa-circle]="fill===100" [class.filled]="fill===100"></span>
                </ng-template>
            </ngb-rating>
        </div>
    </div>
</div>