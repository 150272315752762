import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Arkategoria,
  FizetesiMod,
  Partner,
  Penzintezet,
  Penznem,
} from '../../models/torzsek';
import { ArkategoriakService } from '../../services/arkategoriak.service';
import { FizetesiModokService } from '../../services/fizetesi-modok.service';
import { PartnerekService } from '../../services/partnerek.service';
import { PenzintezetekService } from '../../services/penzintezetek.service';
import { PenznemekService } from '../../services/penznemek.service';

@Injectable({
  providedIn: 'root',
})
export class ErtekesitesTorzsekResolver
  implements
    Resolve<{
      arkategoriak: Arkategoria[];
      fizetesiModok: FizetesiMod[];
      partnerek: Partner[];
      penznemek: Penznem[];
      penzintezetek: Penzintezet[];
    }>
{
  constructor(
    private arkategoriakService: ArkategoriakService,
    private fizetesiModokService: FizetesiModokService,
    private partnerekService: PartnerekService,
    private penzintezetekService: PenzintezetekService,
    private penznemekService: PenznemekService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<{
    arkategoriak: Arkategoria[];
    fizetesiModok: FizetesiMod[];
    partnerek: Partner[];
    penznemek: Penznem[];
    penzintezetek: Penzintezet[];
  }> {
    return forkJoin([
      this.arkategoriakService.initData$,
      this.fizetesiModokService.initData$,
      this.partnerekService.initData$.pipe(
        map((partnerek: Partner[]) => partnerek.filter((p: any) => p.vevo && p.statusz != -1))
      ),
      this.penzintezetekService.initData$,
      this.penznemekService.initData$,
    ]).pipe(
      map(
        ([
          arkategoriak,
          fizetesiModok,
          partnerek,
          penzintezetek,
          penznemek,
        ]) => ({
          arkategoriak,
          fizetesiModok,
          partnerek,
          penzintezetek,
          penznemek,
        })
      )
    );
  }
}
