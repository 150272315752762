import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CustomToastService } from './custom-toast.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly baseUrl: string = environment.apiUrl;

  constructor(
    private toastr: CustomToastService,
    private httpClient: HttpClient
  ) {}

  get(endpoint: string): Observable<any> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/${endpoint}`).pipe(
      catchError((err: any) => {
        return of([]);
      })
    );
  }

  getById(endpoint: string, id: number | string): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/${endpoint}/${id}`);
  }

  post(
    endpoint: string,
    data: any,
    msg: string = 'Sikeres mentés'
  ): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/${endpoint}`, data).pipe(
      tap(() => {
        if (endpoint !== 'auth') {
          this.toastr.toastrCustomSuccess(msg);
        }
      }),
      catchError((err) =>
        this.errorHandler(endpoint === 'auth' ? 'login' : 'add', err)
      )
    );
  }

  postBizonylat(
    endpoint: string,
    data: any,
  ): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/${endpoint}`, data).pipe(
       catchError((err) => 
        this.errorHandler(endpoint === 'auth' ? 'login' : 'add', err)
      )
    );
  }

  put(
    endpoint: string,
    data: any,
    msg: string = 'Sikeres módosítás'
  ): Observable<any> {
    return this.httpClient.put<any>(`${this.baseUrl}/${endpoint}`, data).pipe(
      tap(() => {
        if(msg == 'disable') {
          
        } else {
          this.toastr.toastrCustomSuccess(msg);
        }
      }),
      catchError((err) => this.errorHandler('update', err))
    );
  }

  delete(
    endpoint: string,
    id: number,
    msg: string = 'törlés'
  ): Observable<any> {
    return this.httpClient
      .delete<any>(`${this.baseUrl}/${endpoint}/${id}`)
      .pipe(
        tap(() => {
          this.toastr.toastrCustomSuccess(`Sikeres ${msg}`);
        }),
        catchError((err) => this.errorHandler('delete', err))
      );
  }

  deleteNoMsg(
    endpoint: string,
    id: number
  ): Observable<any> {
    return this.httpClient
      .delete<any>(`${this.baseUrl}/${endpoint}/${id}`)
      .pipe(
        tap(() => {
        }),
        catchError((err) => this.errorHandler('delete', err))
      );
  }

  private errorHandler(
    service: 'get' | 'add' | 'update' | 'delete' | 'login',
    error: any
  ): Observable<any> {
    const title = this.generateTitle(service);

    // Backend Error
    if (error?.error?.message) {
      this.toastr.toastrCustomDanger(title, error?.error?.message);
    }

    // Database error
    else if (error?.error?.messages) {
      let resultStr = '';

      Object.keys(error.error.messages).forEach((key: string) => {
        resultStr += error.error.messages[key] + '\n';
      });

      this.toastr.toastrCustomDanger(title, resultStr);
    }

    // Undefined
    else {
      this.toastr.toastrCustomDanger(title, 'Váratlan hiba történt');
    }

    return throwError(error);
  }

  private generateTitle(
    service: 'get' | 'add' | 'update' | 'delete' | 'login'
  ): string {
    switch (service) {
      case 'get': {
        return 'Hiba a lekérdezés közben';
      }

      case 'add': {
        return 'Hiba a mentés közben';
      }

      case 'update': {
        return 'Hiba a módosítás közben';
      }

      case 'delete': {
        return 'Hiba törlés közben';
      }

      case 'login': {
        return 'Sikertelen bejelentkezés';
      }

      default: {
        ('-');
      }
    }
  }
}
