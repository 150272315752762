<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo-->
        <a class="brand-logo" href="javascript:void(0);">
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="60" />
            <!-- <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" /> -->
            <!-- <h2 class="brand-text text-primary ml-1">Aranyi ERP</h2> -->
        </a>
        <!-- /Brand logo-->
        <!-- Left Text-->
        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img class="img-fluid" [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/login-v2-dark.svg'
              : 'assets/images/pages/login-v2.svg'
          " alt="Login V2" />
            </div>
        </div>
        <!-- /Left Text-->
        <!-- Login-->
        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title font-weight-bold mb-1">
                    Üdvözöllek az Aranyi ERP-ben! 👋
                </h2>
                <p class="card-text mb-2">Kérlek jelentkezz be a folytatáshoz</p>

                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                    <div class="alert-body">
                        <p>{{ error }}</p>
                    </div>
                </ngb-alert>

                <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="form-label" for="login-felhasznalonev">Felhasználónév</label
            >
            <input
              type="text"
              formControlName="felhasznalonev"
              class="form-control"
              [ngClass]="{
                'ng-invalid.ng-touched': submitted && f.felhasznalonev.errors
              }"
              placeholder="Felhasználónév"
              aria-describedby="login-felhasznalonev"
              autofocus=""
              tabindex="1"
            />
            <div
              *ngIf="submitted && f.felhasznalonev.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.felhasznalonev.errors.required">
                Felhasználónév kötelező
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Jelszó</label>
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                        <input [type]="passwordTextType ? 'text' : 'password'" formControlName="jelszo" class="form-control form-control-merge" [ngClass]="{
                  'ng-invalid.ng-touched error': submitted && f.jelszo.errors
                }" placeholder="············" aria-describedby="login-jelszo" tabindex="2" />
                        <div class="input-group-append">
                            <span class="input-group-text cursor-pointer"><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.jelszo.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.jelszo.errors }">
                        <div *ngIf="f.jelszo.errors.required">Jelszó kötelező</div>
                    </div>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3" checked="true" />
                    <label class="custom-control-label" for="remember-me">
                Emlékezz rám</label
              >
            </div>
          </div>
          <button
            [disabled]="loading"
            class="btn btn-primary btn-block"
            tabindex="4"
            rippleEffect
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >Bejelentkezés
          </button>
        </form>
      </div>
    </div>
    <!-- /Login-->
  </div>
</div>