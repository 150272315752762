<div class="d-flex flex-column" style="height: 100% !important; overflow-y: auto !important;">
    <div class="modal-header details-header">
        <div class="d-inline-flex flex-grow-1">
            <div class="d-flex flex-column gap-2 align-items-center flex-grow-1">
                <h5 class="modal-title details-title flex-grow-1 text-primary">
                    Analitika
                </h5>
                <span class="text-hint" [ngStyle]="{ fontSize: '12px' }">
          Analitika
        </span>
            </div>

            <button (click)="closeSidebar()" type="button" class="font-large-1 font-weight-normal py-0 ml-auto btn btn-icon" data-dismiss="modal" aria-label="Close" [ngStyle]="{ position: 'absolute', right: '10px', top: '7px' }">
        ×
      </button>
        </div>
    </div>

    <div class="sidebar col-12 overflow-auto" style="overflow-y: auto !important; height: 100% !important;">
        <div class="sidebar-content email-app-sidebar">
            <div class="email-app-menu d-flex flex-column">
                <div class="sidebar-menu-list" style="height: 100% !important;">
                    <div class="list-group list-group-messages mt-2">
                        <ngb-accordion #acc="ngbAccordion" style="overflow-y: auto !important;">
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Készlet analitika</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex justify-content-center">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onKeszletkarton()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Foglalás lista</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex justify-content-center">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onFoglalasLista()">
                                                XLS generálás
                                            </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Vevő analitika </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Vevő analitika</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex justify-content-center">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onVevoAnalitika()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Szállító analitika </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Szállító analitika</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="transaction-item">
                                        Időszak:
                                    </div>
                                    <div class="transaction-item">
                                        <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="dateRange" style="width: 100% !important"></ng2-flatpickr>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2" [ngClass]="{'disabled': dateRange.length != 2}">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onSzallitoAnalitika()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Minimális készlet analitika </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Minimális készlet analitika</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex justify-content-center">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onMinKeszlet()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Bizonylat analitika </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Bizonylat analitika</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Év:
                                        </div>
                                        <div class="col-6">
                                            <p-calendar baseZIndex="9999" appendTo="body" [(ngModel)]="bizonylatYear" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onBizonylatAnalitika()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Napi pénztár zárás </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Napi pénztár zárás</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Dátum:
                                        </div>
                                        <div class="col-6">
                                            <p-calendar baseZIndex="9999" appendTo="body" class="datum-analitika" dateMask [maxDate]="now" inputStyleClass="form-control text-color-input" [ngStyle]="{ width: '105px' }" [(ngModel)]="penztarNap"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Önelszámoló egység:
                                        </div>
                                        <div class="col-6">
                                            <ng-select class="oe-select" id="oe" [items]="oek$ | async" bindLabel="megnevezes" bindValue="id" [clearable]="false" [ngModel]="oe" [ngModelOptions]="{ standalone: true }">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onPenztarZaras()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Bizonylat teljesítettség </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Bizonylat teljesítettség</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Év:
                                        </div>
                                        <div class="col-6">
                                            <p-calendar baseZIndex="9999" appendTo="body" [(ngModel)]="teljesitettsegYear" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onBizonylatTeljesitett()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Költségnemenkénti analitika </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Költségnemenkénti analitika</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Év:
                                        </div>
                                        <div class="col-6">
                                            <p-calendar baseZIndex="9999" appendTo="body" [(ngModel)]="koltsegnemYear" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onKoltsegnemAnalitika()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Forgalom </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Forgalom</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="transaction-item">
                                        Időszak:
                                    </div>
                                    <div class="transaction-item">
                                        <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="dateRange" style="width: 100% !important"></ng2-flatpickr>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2" [ngClass]="{'disabled': dateRange.length != 2}">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onForgalomAnalitika()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Forgalom </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Kiegyenlített számlák</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Dátum:
                                        </div>
                                        <div class="col-6">
                                            <p-calendar baseZIndex="9999" appendTo="body" class="datum-analitika" view="month" dateFormat="yy-mm" [maxDate]="now" inputStyleClass="form-control text-color-input" [ngStyle]="{ width: '105px' }" [(ngModel)]="kiegyenlitettDate"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onKiegyenlitettSzamlak()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Jutalék </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Jutalék</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Év:
                                        </div>
                                        <div class="col-6">
                                            <p-calendar baseZIndex="9999" appendTo="body" [(ngModel)]="jutalekYear" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onJutalek()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Áfa analitika </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Áfa analitika</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <!-- <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Dátum:
                                        </div>
                                        <div class="col-6">
                                            <p-calendar baseZIndex="9999" appendTo="body" [(ngModel)]="afaDate" view="month" dateFormat="yy-mm" [readonlyInput]="true"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onAfaAnalitika()">
                                            XLS generálás
                                        </button>
                                    </div> -->
                                    <div class="transaction-item">
                                        Időszak:
                                    </div>
                                    <div class="transaction-item">
                                        <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" [(ngModel)]="dateRange" style="width: 100% !important"></ng2-flatpickr>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2" [ngClass]="{'disabled': dateRange.length != 2}">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onAfaAnalitika()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- <span class="lead collapse-title"> Árajánlat analitika </span> -->
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i [data-feather]="'file-text'" [size]="18" class="ml-0"></i>
                                        </div>
                                        <span class="ml-1 lead collapse-title">Árajánlat analitika</span>
                                        <div class="ml-auto">
                                            <i [data-feather]="'chevron-down'" [size]="18" class="mr-0"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-6">
                                            Év:
                                        </div>
                                        <div class="col-6">
                                            <p-calendar [(ngModel)]="arajanlatYear" view="year" dateFormat="yy" inputId="yearpicker"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-2">
                                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" (click)="onArajanlatAnalitika()">
                                            XLS generálás
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                        <div class="divider mt-3">
                            <div class="divider-text">Egyéb</div>
                        </div>
                        <div class="d-flex align-items-center mx-2 mt-1" style="cursor: pointer;" (click)="onKintlevosegekOpen()">
                            <div>
                                <i [data-feather]="'codesandbox'" [size]="18" class="ml-0"></i>
                            </div>
                            <span class="ml-1 lead collapse-title">Kintlévőségek</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>