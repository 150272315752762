export const kozteruletTipusok: string[] = [
  'utca',
  'út',
  'tér',
  'körút',
  'köz',
  'sétány',
  'sor',
  'sikátor',
  'part',
  'ajándékbolt',
  'akna',
  'akna-alsó',
  'akna-felső',
  'aknaüzem',
  'alagsor',
  'alagút',
  'alkotóház',
  'alsórakpart',
  'aluljáró',
  'anyagbánya',
  'arborétum',
  'autóparkoló',
  'autópálya',
  'autóút',
  'állat és növ.kert',
  'állomás',
  'árnyék',
  'árok',
  'ártér',
  'árucsarnok',
  'árusítóhely',
  'átjáró',
  'átkelő',
  'barakképület',
  'barlang',
  'bánya',
  'bányatelep',
  'bányaüzem',
  'bástya',
  'bástyája',
  'bejáró',
  'bekötőút',
  'belterület',
  'bemutatókert',
  'bemutatóterem',
  'benzinkút',
  'betonüzem',
  'bevásárlóközpont',
  'bevásárlóudvar',
  'birtok',
  'bokor',
  'bolt',
  'borházsor',
  'borozó',
  'csapás',
  'csarnok',
  'csarnokrész',
  'csatorna',
  'csárda',
  'csomópont',
  'csónakház',
  'csónakházak',
  'csónakkikötő',
  'domb',
  'dülő',
  'dűlő',
  'dűlők',
  'dűlősor',
  'dűlőterület',
  'dülőterület',
  'dűlőút',
  'dülőút',
  'egyetem',
  'egyetemváros',
  'egyéb',
  'elágazás',
  'emlékpark',
  'emlékút',
  'erdészet',
  'erdészház',
  'erdészlak',
  'erdő',
  'erdőalja',
  'erdősor',
  'épület',
  'farm',
  'farmertanyák',
  'fasor',
  'fasora',
  'felső',
  'felüljáró',
  'fennsík',
  'félsziget',
  'forduló',
  'főcsatorna',
  'föld',
  'főmérnökség',
  'főtér',
  'főutca',
  'főút',
  'főútvonal',
  'fürdő',
  'garázs',
  'garázssor',
  'gát',
  'gátfeljáró',
  'gátőrház',
  'gátsor',
  'gépállomás',
  'gépjavítóüzem',
  'géppark',
  'géptelep',
  'gödör',
  'gyár',
  'gyártelep',
  'gyárváros',
  'gyümölcsös',
  'hajóállomás',
  'hajókikötő',
  'halastavak',
  'halastó',
  'határ',
  'határátkelőhely',
  'határkirendeltség',
  'határsor',
  'határszél',
  'határút',
  'hágó',
  'ház',
  'házrész',
  'hegy',
  'hegyalja',
  'hegyhát',
  'hegyhát dűlő',
  'hegyhát köz',
  'hév',
  'hévállomás',
  'híd',
  'hídfő',
  'homokhát',
  'horgásztó',
  'hrsz',
  'hrsz.',
  'iparipark',
  'ipartelep',
  'iparterület',
  'iskola',
  'játszótér',
  'juhászat',
  'juhtelep',
  'kapu',
  'kastély',
  'kastélyszálló',
  'kert',
  'kertalja',
  'kertsor',
  'kertváros',
  'kerület',
  'kikötő',
  'kikötősor',
  'kilátó',
  'kioszk',
  'kiskörút',
  'kocsiszín',
  'kolónia',
  'korzó',
  'kör',
  'körforgalom',
  'körgyűrű',
  'körlet',
  'körönd',
  'körtér',
  'körvasútsor',
  'körzet',
  'közdülő',
  'köztemető',
  'közterület',
  'köztér',
  'közút',
  'kultúrpark',
  'kunyhó',
  'kút',
  'kútsor',
  'külkerület',
  'kültelek',
  'külterület',
  'kültér',
  'lakóház',
  'lakónegyed',
  'lakópark',
  'lakótelep',
  'laktanya',
  'leágazás',
  'lejáró',
  'lejtő',
  'lépcső',
  'liget',
  'major',
  'malom',
  'menedékház',
  'mélyút',
  'mező',
  'munkásszálló',
  'műút',
  'nagykörút',
  'népliget',
  'oldal',
  'orom',
  'országút',
  'öreghegy',
  'őrház',
  'őrházak',
  'őrházlak',
  'ösvény',
  'övezet',
  'pagony',
  'park',
  'parkerdő',
  'parkja',
  'parkoló',
  'parkolótér',
  'parkutca',
  'pavilon',
  'pálya',
  'pályaudvar',
  'piac',
  'piactér',
  'piaccsarnok',
  'pihenő',
  'pince',
  'pincehelyiség',
  'pincesor',
  'postafiók',
  'puszta',
  'rakpart',
  'repülőtér',
  'rész',
  'rét',
  'sarok',
  'sánc',
  'sávház',
  'sétaút',
  'sípálya',
  'sora',
  'sóstó',
  'sportcentrum',
  'sportcsarnok',
  'sportpálya',
  'sporttelep',
  'sportuszoda',
  'stadion',
  'strand',
  'strandfürdő',
  'sugárút',
  'szabadidőközpont',
  'szabadidőpark',
  'szállás',
  'szállások',
  'szer',
  'szél',
  'szérű',
  'szérűskert',
  'sziget',
  'szivattyútelep',
  'szőlő',
  'szőlőhegy',
  'szőlők',
  'szőlőkalja',
  'szőlőskert',
  'szőlősor',
  'szőlővölgy',
  'tag',
  'tanya',
  'tanyaközpont',
  'tanyák',
  'telek',
  'telep',
  'telephely',
  'temető',
  'terasz',
  'tere',
  'terminál',
  'terület',
  'tető',
  'tetőszint',
  'tetőtér',
  'téli kikötő',
  'tó',
  'tópark',
  'tópart',
  'tósétány',
  'töltés',
  'tömb',
  'turistaház',
  'udvar',
  'ugar',
  'uradalom',
  'utak',
  'utcafront',
  'utcasor',
  'utcatér',
  'utcája',
  'újtelep',
  'útfél',
  'útgyűrű',
  'útja',
  'útőrház',
  'útsor',
  'üdülő',
  'üdülő-part',
  'üdülő-sor',
  'üdülő-telep',
  'üdülőközpont',
  'üdülőpart',
  'üdülőtelep',
  'üdülőterület',
  'üzletház',
  'üzletközpont',
  'üzletsor',
  'vadaskert',
  'vadászház',
  'vasút',
  'vasútállomás',
  'vasúti megálló',
  'vasúti őrház',
  'vasútsor',
  'vágóhíd',
  'vár',
  'várkerület',
  'várköz',
  'vármegy',
  'város',
  'városrész',
  'vásárcsarnok',
  'vásárpiac',
  'vásártelep',
  'vásártér',
  'védgát',
  'vezetőút',
  'végállomás',
  'villasor',
  'vízitelep',
  'vízmű',
  'víztározó',
  'völgy',
  'völgysziget',
  'zárkert',
  'zsilip',
  'zug',
];
